import React from 'react';
import connect from 'react-redux/es/connect/connect';
import ChoiceListFilteredTableNoDashboardData from '../../common/FilteredTable/ChoiceListFilteredTableNoDashboardData';
import { TableDefinition } from '../../common/FilteredTable/tableAndFilterHelpers';
import { mapToRows } from '../../common/Table/addColumnsToRow';
import addressDef from '../addressDef';

class AddressTableCard extends React.Component {
    static defaultProps = {
        breakpoint: 500,
        itemsPerPage: 5,
        noItemsText: 'There are no addresses',
        columns: [
            addressDef.address1,
            addressDef.address2,
            addressDef.address3,
            addressDef.address4,
            addressDef.city,
            addressDef.postCode
        ]
    };

    render() {
        const { rows, resources, columns, isLoading, noItemsText, breakpoint, itemsPerPage } = this.props;

        const tableDefinition = new TableDefinition(columns);

        return (
            <ChoiceListFilteredTableNoDashboardData
                id="Demos"
                title="Demos"
                noItemsText={noItemsText}
                tableIsNotInCard={true}
                showFilter={true}
                itemsPerPage={itemsPerPage}
                breakpoint={breakpoint}
                cardButtons={[]}
                isLoading={isLoading}
                tableDefinition={tableDefinition}
                rows={mapToRows(rows, addressDef.id, resources, tableDefinition.columns, undefined, undefined)}
            />
        );
    }
}

const mapStateToProps = state => ({
    resources: state.resources
});

export default connect(mapStateToProps)(AddressTableCard);
