import { push } from 'connected-react-router';
import { actions } from 'react-redux-form';
import moment from 'moment';
export const SET_MODAL_OPEN = 'SET_MODAL_OPEN';

export const openModal = (name, data) => dispatch => setModalState(dispatch, name, data, true);

export const openInModalOrPage = (formPath, itemId, formData, altId, altId2, altId3) => async (dispatch, getState) => {
    if (formPath.formName) {
        dispatch(actions.change(formPath.formName, formData));
    }
    if (getState().settings.openInModals) {
        dispatch(openModal(formPath.modalName, formPath.modalData(itemId, altId, altId2, altId3)));
    } else {
        dispatch(push(formPath.makePath(itemId, altId, altId2, altId3)));
    }
};

export const FORM_PATHS = {
    ExpiredTokenModal: {
        modalName: 'ExpiredTokenModal',
        modalData: () => null
    },
    EditVisitActionModal: {
        modalName: 'EditVisitActionModal',
        modalData: (visitActionId, companyId, parentCommunicationId) => ({
            visitActionId,
            companyId,
            parentCommunicationId
        }),
        makePath: (visitActionId, companyId, parentCommunicationId) =>
            `/task/edit/${visitActionId}/${parentCommunicationId}/${companyId}`
    },
    EditEventModal: {
        modalName: 'EditRegistrationModal',
        modalData: (eventId, startDate, eventType) => ({
            eventId,
            startDate,
            eventType
        }),
        makePath: (communicationId, startDate, eventType) =>
            `/day/edit/${eventType}/${communicationId}/${moment(startDate).format()}`
    },
    EditCommunicationModal: {
        modalName: 'EditCommunicationModal',
        modalData: (communicationId, companyId) => ({
            communicationId,
            companyId
        }),
        makePath: (communicationId, companyId) => `/communication/edit/${companyId}/${communicationId}`
    },
    AgreementOpportunityModal: {
        modalName: 'AgreementOpportunityModal',
        modalData: (agreementOpportunityId, companyId) => ({
            agreementOpportunityId,
            companyId
        }),
        makePath: (communicationId, companyId) => `/agreementOpportunity/edit/${companyId}/${communicationId}`
    },
    EditMeetingModal: {
        modalName: 'EditMeetingModal',
        modalData: (meetingId, companyId) => ({
            meetingId,
            companyId
        }),
        makePath: (meetingId, companyId) => `/meeting/edit/${companyId}/${meetingId}`
    },
    CancelCommunicationModal: {
        modalName: 'CancelCommunicationModal',
        modalData: (communicationId, companyId) => ({
            communicationId,
            companyId
        }),
        makePath: (communicationId, companyId) => `/communication/cancel/${companyId}/${communicationId}`
    },
    CompleteMeetingModal: {
        modalName: 'CompleteMeetingModal',
        modalData: (meetingId, companyId) => ({
            meetingId,
            companyId
        }),
        makePath: (communicationId, companyId) => `/meeting/complete/${companyId}/${communicationId}`
    },
    EditAgreementTerm: {
        modalName: 'EditAgreementTermModal',
        modalData: id => ({ id }),
        formName: 'agreementTermForm',
        makePath: termId => `/agreement/create/term/edit/${termId}`
    },
    EditOpportunity: {
        modalName: 'EditOpportunityModal',
        modalData: (opportunityId, companyId) => ({ opportunityId, companyId }),
        formName: 'opportunityForm',
        makePath: (opportunityId, companyId) => `/opportunity/edit/${companyId}/${opportunityId}`
    },
    EditPerson: {
        modalName: 'PersonEditModal',
        modalData: (personId, companyId) => ({ personId, companyId }),
        makePath: (personId, companyId) => `/people/edit/${companyId}/${personId}`
    },
    CreateLead: {
        modalName: 'CreateLeadModal',
        modalData: leadId => ({ leadId }),
        makePath: leadId => `/lead/edit/${leadId}`
    },
    EditAddress: {
        modalName: 'EditAddressModal',
        modalData: (addressId, companyId) => ({ addressId, companyId }),
        makePath: (addressId, companyId) => `/address/edit/${companyId}/${addressId}`
    },
    RequestSupport: {
        modalName: 'RequestSupportModal',
        modalData: () => ({}),
        makePath: () => `/support/request`
    }
};

export const closeModal = formPath => dispatch => setModalState(dispatch, formPath, {}, false);

const setModalState = (dispatch, name, data, isOpen) => dispatch({ type: SET_MODAL_OPEN, name, isOpen, data });
