import React from 'react';
import { connect } from 'react-redux';
import Sidebar from '../Sidebar';
import { Nav, Navbar, NavbarBrand } from 'reactstrap';
import NavRouterLink from '../NavRouterLink/.';
import NavigationItems from '../NavigationItems';
import { AppLogo } from '../AppLogo';
import SidebarUserMenu from '../Sidebar/SidebarUserMenu';
import { getNavigationItems } from '../../../config/navigation';
import DealerLogo from '../../app/DealerLogo';
import useToggleOpen from '../../common/Hooks/useToggleOpen';

const PageWithNavigation = ({ children, user }) => {
    const { toggle, isOpen } = useToggleOpen();
    const { toggle: toggleUserMenu, isOpen: showUserMenu } = useToggleOpen();

    return (
        <>
            <div className="wrapper" key="wrapper">
                <nav id="sidebar" className={`${isOpen ? 'active' : ''} sidebar-background shadow`}>
                    <div className="navbar-img sidebar-header-image">
                        <AppLogo className="mt-4" />
                        <div className="p-2 d-grid grid-cols-2 hover-pointer" onClick={toggleUserMenu}>
                            <span>{user.email}</span>
                            <span
                                className={`grid-js-end fa d-inline ${
                                    showUserMenu ? 'fa-chevron-down' : 'fa-chevron-right'
                                }`}
                            />
                        </div>
                    </div>
                    {showUserMenu ? <SidebarUserMenu onLinkOpen={toggle} /> : <Sidebar onLinkOpen={toggle} />}
                </nav>

                <button
                    type="button"
                    onClick={() => toggle()}
                    id="sidebarCollapse"
                    className={`navbar-btn position-fixed zindex-501 ${isOpen ? '' : 'active'}`}
                >
                    <span />
                    <span />
                    <span />
                </button>
                <NavbarBrand href="/dashboard/home" className="navbar-brand position-fixed zindex-501">
                    <AppLogo />
                    <DealerLogo />
                </NavbarBrand>
                <div className={`overlay ${isOpen ? 'active' : ''}`} onClick={() => toggle()} />

                <Navbar
                    className={`px-md-5 px-sm-4 px-2 navbar-img ${
                        isOpen ? 'navbar-open' : ''
                    } position-fixed zindex-500 w-100  shadow`}
                    dark
                    color="primary"
                    expand="md"
                >
                    <Nav className="ml-auto" navbar>
                        <NavigationItems navigation={getNavigationItems(user)} />
                        <NavRouterLink isVisible icon="sign-out" name="Sign Out" link="/" />
                    </Nav>
                </Navbar>
                <div id="content" key="MainContent" className="navbar-offset">
                    {children}
                </div>
            </div>
        </>
    );
};

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps)(PageWithNavigation);
