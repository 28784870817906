import React from 'react';
import Link from 'react-router-dom/es/Link';

export const NavRouterLink = ({ onLinkOpen, className, isVisible, name, link, icon }) =>
    isVisible ? (
        <li className={`nav-item ${className}`}>
            <Link className="nav-link nav-bar-nav-item m-0 p-0" to={link}>
                <div className="m-1 p-1" onClick={() => onLinkOpen && onLinkOpen()}>
                    {icon ? <i className={`fa d-inline mr-2 ml-3" fa-${icon}`} /> : null}
                    {name}
                </div>
            </Link>
        </li>
    ) : null;

export default NavRouterLink;
