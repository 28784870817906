import { ENDPOINTS, IAction } from '../../../actions/Api/constants';
import { IMetaData } from '../Meeting';
import { RESET_REDUCER } from '../../../actions/resetReducers';
import _ from 'lodash';
import communication from "../../../containers/Communication/communication";
import { getFieldsNotUpdated, setPropertiesSyncing } from "../helper";
import { ICommunicationFilter } from "../../../actions/Api/Communication/apiCommunicationFilter";
import moment from "moment";

enum EventAction {
	followUpCall = 'BSDRBSMCompanyFollowUpCall',
}

export interface ICommunication {
	communicationId: number;
	activityRegarding: string;
	action: EventAction;
	startDate: Date;
	endDate: Date;
	subject: string;
	status: string;
	details: string;
	createdBy: number;
	createdDate: Date;
	updatedBy: number;
	updatedDate: number;
	assignee?: IMetaData;
	company?: IMetaData;
	person?: IMetaData;
}

const propertiesToIgnoreForErrors = [
	'person',
	'company',
	'communicationId',
	'descriptor',
	'assignee',
	'updatedDate',
	'start',
	'end',
	'assigneeName',
	'calendarOption'
];

export const getCommunicationUpdateErrors = (local:any, remote:any) => {
	return getFieldsNotUpdated(local, remote, propertiesToIgnoreForErrors);
};

const idField = communication.id.field;

function updateStateBeforeSendingToServer(state: ICommunication[], {data}: IAction){
	const original = state.find((x:any) => data[idField] === x[idField]);
	if(original)
	{
		setPropertiesSyncing(original, data);
	}
	data.syncing = true;
	return [
		data,
		...state.filter((x:any) => x[idField] !== data[idField])
	];
}

function postCommit(state:ICommunication[], action:IAction){
	const {
		meta: { modified },
		payload: { data: remote }
	} = action;
	remote.assigneeId = _.get(remote, 'assignee.id');
	return [
		remote,
		...state.filter((x:any) => modified[idField] !== x[idField] && x[idField] !== remote[idField])
	];
}

export default (state: ICommunication[] = [], action: IAction) => {
	switch (action.type) {
		case RESET_REDUCER:
			return [];
		case ENDPOINTS.API.COMMUNICATION_POST:
			return  updateStateBeforeSendingToServer(state, action);
		case ENDPOINTS.API.COMMUNICATION_POST_COMMIT:
			return postCommit(state, action);
		case ENDPOINTS.API.COMMUNICATIONS_FILTER:
			return updateState(state, action.data, action.filter);
		default:
			return state;
	}
};

const updateState = (state: ICommunication[], update: ICommunication[], filter:ICommunicationFilter): ICommunication[] => [
	...update.map(communication => ({...communication, assigneeId: _.get(communication, 'assignee.id')})),
	//...state.filter(x => !update.some(y => x.communicationId === y.communicationId))
	...filterState(state, filter, update)
];

export function filterState(state: ICommunication[], filter:ICommunicationFilter, newData:ICommunication[]): ICommunication[] {
	const matchesState = filterToMatchFunction(filter);

	const filteredState = state.filter(
		x => !newData.some(newComm => x.communicationId === newComm.communicationId) && !matchesState(x)
	);
	return filteredState;
};

function filterToMatchFunction({ companyId, status, assigneeId, startDate, endDate }: ICommunicationFilter):Function {
	const start = startDate === undefined ? undefined : moment(startDate);
	const end = endDate === undefined ? undefined : moment(endDate);

	return (communication:ICommunication)=> {
		return (
			(!companyId || _.get(communication, 'company.id','') === companyId) &&
			(!assigneeId || _.get(communication, 'assignee.id','') === assigneeId) &&
			(!status || status.indexOf(communication.status) > -1) &&
			((!start && !end ) || ((!start || start.isBefore(moment(communication.startDate))) && (!end || end.isAfter(moment(communication.startDate)))))
		);
	};
}