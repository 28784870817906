import React, { useContext } from 'react';
import PageSectionContentTable from '../../common/PageSection/PageSectionContentTable';
import { CompanyContext } from '../Provider/CompanyProvider';
import companyDef from '../companyDef';

const CompanyPageTitleContent = () => {
    const {
        company
    } = useContext(CompanyContext);
    return (
        <PageSectionContentTable
           values={company}
           columnDefs={[
            companyDef.ucn
           ]}
       />
    );
};

export default CompanyPageTitleContent;
