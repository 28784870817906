import { ENDPOINTS } from '../../../actions/Api/constants';
import { RESET_REDUCER } from '../../../actions/resetReducers';
import moment from 'moment';
import _ from 'lodash';

const updateStateForAffinityMetrics = (state, action) => {
    //console.log(state);
    //console.log(action);
    const newState = { ...state };

    const report = _.get(state, action.type, {});
    action.data.reports[0].data.forEach(data => (report[data.label] = { data, updated: moment().format() }));
    newState[action.type] = report;

    return newState;
};

export function reports(state = {}, action) {
    switch (action.type) {
        case ENDPOINTS.API.AFFINITY_METRICS_PAGE_VIEWS_TODAY:
        case ENDPOINTS.API.AFFINITY_METRICS_PAGE_VIEWS_WEEK:
        case ENDPOINTS.API.AFFINITY_METRICS_EMPLOYER_VOUCHERS_CREATED:
        case ENDPOINTS.API.AFFINITY_METRICS_EMPLOYER_VOUCHERS_USED:
        case ENDPOINTS.API.AFFINITY_METRICS_VEHICLE_VOUCHERS_CREATED:
        case ENDPOINTS.API.AFFINITY_METRICS_VEHICLE_VOUCHERS_USED:
        case ENDPOINTS.API.AFFINITY_METRICS_OFFER_VOUCHERS_CREATED:
        case ENDPOINTS.API.AFFINITY_METRICS_OFFER_VOUCHERS_USED:
        case ENDPOINTS.API.AFFINITY_METRICS_OFFER_DETAIL_VOUCHERS_CREATED:
        case ENDPOINTS.API.AFFINITY_METRICS_OFFER_DETAIL_VOUCHERS_USED:
        case ENDPOINTS.API.AFFINITY_METRICS_RETAILER_VOUCHERS_USED:
        case ENDPOINTS.API.AFFINITY_METRICS_RETAILER_VOUCHERS_SEARCHED:
        case ENDPOINTS.API.AFFINITY_METRICS_USER_VOUCHERS_SEARCHED:
        case ENDPOINTS.API.AFFINITY_METRICS_BROWSER_VERSIONS:
        case ENDPOINTS.API.AFFINITY_METRICS_RETAILER_LOGIN:
        case ENDPOINTS.API.AFFINITY_METRICS_EMPLOYER_LOGIN:
            return updateStateForAffinityMetrics(state, action);
        case ENDPOINTS.API.REPORTS:
            const { reportName, data, options } = action;
            return {
                ...state,
                [reportName]: {
                    data,
                    options
                }
            };

        case RESET_REDUCER:
            return {};

        default:
            return state;
    }
}
