import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router';
import { getLoadingState } from '../../../reducers/Rest/loadingStates';
import ChoiceListFilteredTableNoDashboardData from '../../common/FilteredTable/ChoiceListFilteredTableNoDashboardData';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { TableDefinition } from '../../common/FilteredTable/tableAndFilterHelpers';
import { mapToRows } from '../../common/Table/addColumnsToRow';
import termsDef from '../termsDefs';

class TermsTableCard extends React.Component {
    static defaultProps = {
        breakpoint: 500,
        itemsPerPage: 5,
        noItemsText: 'There are no terms',
        columns: [
            termsDef.typeOfTerms,
            termsDef.actualStartDate,
            termsDef.actualEndDate,
            termsDef.requestedByName,
            termsDef.contactName,
            termsDef.status
        ]
    };

    render() {
        const {
            loadingStates,
            rows,
            resources,
            columns,
            title,
            breakpoint,
            itemsPerPage,
            noItemsText,
            ...rest
        } = this.props;
        const isLoading = getLoadingState(loadingStates, ENDPOINTS.API.VISIT_ACTIONS_FILTER_COMMIT).isLoading;
        const tableDefinition = new TableDefinition(columns);

        return (
            <ChoiceListFilteredTableNoDashboardData
                id={title}
                tableDefinition={tableDefinition}
                breakpoint={breakpoint}
                noItemsText={noItemsText}
                itemsPerPage={itemsPerPage}
                rows={mapToRows(rows, termsDef.id, resources, tableDefinition.columns, undefined, undefined)}
                isLoading={isLoading}
                tableIsNotInCard={true}
                cardButtons={[]}
                title={title}
                {...rest}
            />
        );
    }
}

const mapStateToProps = state => ({
    loadingStates: state.loadingStates,
    resources: state.resources,
    people: state.people
});

export default connect(mapStateToProps)(withRouter(TermsTableCard));
