import _ from 'lodash';

export const getChoiceListState = (state, name) => _.get(state, `resources.choiceList.${name}`, []);

export const getAgreementOpportunityPeriodsChoiceList = state =>
    getChoiceListState(state, 'agreementOpportunityPeriods');
export const getDealerStatusChoiceList = state => getChoiceListState(state, 'dealerStatus');

export const getAgreementOpportunityStageChoiceList = state => getChoiceListState(state, 'agreementOpportunityStage');
export const getOpportunityStageChoiceList = state => getChoiceListState(state, 'opportunityStage');
export const getOpportunityStatusChoiceList = state => getChoiceListState(state, 'opportunityStatus');

export const getAgreementOpportunityBrandsChoiceList = state => getChoiceListState(state, 'agreementOpportunityBrands');

export const getAgreementOpportunityBrandsPeriodsList = state =>
    getChoiceListState(state, 'agreementOpportunityPeriods');

export const getResources = state => state.resources;

export const getChoiceListKeyFromValue = (choiceList, value) =>
    _.get(choiceList.find(period => period.value === value), `key`, undefined);
