import React, { useContext } from 'react';
import IncludeMeetingNotesCheckbox from './IncludeMeetingNotesCheckbox';
import SimpleCard from '../../common/Card/SimpleCard';
import MeetingNotesForm from '../Forms/MeetingNotesForm';
import { MeetingContext } from '../../Meeting/Provider/MeetingProvider';

function MeetingNotes({ formikProps, isDealer }) {
    const { meeting } = useContext(MeetingContext);

    return (
        <SimpleCard title="Meeting Notes">
            <MeetingNotesForm meeting={meeting} />
            {!isDealer && <IncludeMeetingNotesCheckbox meeting={meeting} />}
        </SimpleCard>
    );
}

export default MeetingNotes;
