import React from 'react';
import { Link } from 'react-router-dom';
import { FieldLabel } from './FieldLabel';
import { FieldValue } from './FieldValue';

export const DisplayField = props => {
    const { title, value, link, href, className, primary, fontawesome, inline, onClick, indicateIsEmpty } = props;

    let renderedValue = href ? <a href={href}>{value}</a> : link ? <Link to={link}>{value}</Link> : value;

    return (
        <div className={className} onClick={onClick}>
            <FieldLabel className={inline ? 'd-inline-block' : ''}>{title}</FieldLabel>
            <FieldValue className={inline ? 'd-inline-block ml-2' : ''} primary={primary}>
                {fontawesome && <i className={`gradient fa d-inline ${fontawesome} mr-2`} />}
                {renderedValue}
                {indicateIsEmpty && !renderedValue && '---'}
            </FieldValue>
        </div>
    );
};
