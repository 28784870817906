import * as yup from 'yup';

const validation = yup.object().shape({
    eventType: yup.string(),
    startDate: yup.date().required('Please enter a start date'),
    endDate: yup.date().required('Please select an end date'),
    body: yup.string()
});

export default validation;
