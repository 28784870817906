import React, { useContext } from 'react';
import PageSectionContentTable from '../../common/PageSection/PageSectionContentTable';
import { MeetingContext } from '../../Meeting/Provider/MeetingProvider';
import meetingDef from '../../Meeting/meeting';

export default () => {
    const { meeting } = useContext(MeetingContext);
    return (
        <PageSectionContentTable
            values={meeting}
            columnDefs={[meetingDef.status, meetingDef.meetingMode, meetingDef.startDate, meetingDef.location]}
        />
    );
};
