import React from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ENDPOINTS } from '../../../actions/Api/constants';
import CompanyCommunicationsProvider from './CompanyCommunicationsProvider';
import CompanyTermsProvider from './CompanyTermsProvider';
import CompanyDemosProvider from './CompanyDemosProvider';
import CompanyRegistrationsProvider from './CompanyRegistrationsProvider';
import CompanyPeopleProvider from './CompanyPeopleProvider';
import CompanyVisitActionsProvider from './CompanyVisitActionsProvider';
import CompanyOpportunitiesProvider from './CompanyOpportunitiesProvider';
import CompanyMeetingsProvider from './CompanyMeetingsProvider';

export const CompanyContext = React.createContext({
    company: { descriptor: '', profile: {}, addresses: [] },
    resources: {},
    isLoading: false
});

export const CompanyConsumer = CompanyContext.Consumer;

const CompanyProvider = ({ loadingStates, companyId, profile, resources, company, isLoading, children }) => (
    <CompanyCommunicationsProvider companyId={companyId}>
        <CompanyMeetingsProvider companyId={companyId}>
            <CompanyTermsProvider companyId={companyId}>
                <CompanyDemosProvider companyId={companyId}>
                    <CompanyRegistrationsProvider companyId={companyId}>
                        <CompanyVisitActionsProvider companyId={companyId}>
                            <CompanyPeopleProvider companyId={companyId}>
                                <CompanyOpportunitiesProvider companyId={companyId}>
                                    <CompanyContext.Provider
                                        value={{
                                            loadingStates,
                                            companyId,
                                            profile,
                                            resources,
                                            company,
                                            isLoading
                                        }}
                                    >
                                        {children}
                                    </CompanyContext.Provider>
                                </CompanyOpportunitiesProvider>
                            </CompanyPeopleProvider>
                        </CompanyVisitActionsProvider>
                    </CompanyRegistrationsProvider>
                </CompanyDemosProvider>
            </CompanyTermsProvider>
        </CompanyMeetingsProvider>
    </CompanyCommunicationsProvider>
);

const makeMapStateToProps = () => {
    const getState = createSelector(
        [
            state => state.loadingStates,
            (state, { companyId }) => state.fleetProfiles.find(x => x.companyId === companyId),
            state => state.resources,
            (state, { companyId }) =>
                state.companies.find(company => company.companyId === companyId) || { addresses: [] }
        ],
        (loadingStates, profile, resources, company) => ({
            loadingStates,
            isLoading: _.get(loadingStates, `${ENDPOINTS.API.COMPANY}.isLoading`, false),
            resources,
            profile,
            company
        })
    );
    return (state, props) => getState(state, props);
};

export default connect(makeMapStateToProps)(CompanyProvider);
