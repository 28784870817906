import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import FormWithSchema from '../../common/FormkControls/FormWithSchema';
import { generateInitialValues } from '../../common/FormkControls/formSchemaGenerator';
import { FORM_NAMES } from '../../../actions/formNames';
import { cookieGetExpires, cookieGetUserId } from '../../../reducers/cookies';
import { apiSupportRequest } from '../../../actions/Api/Support/apiSupportRequest';

const FORM_NAME = FORM_NAMES.SUPPORT;
const formDef = {
    sections: [
        {
            title: 'Contact Details',
            order: 0,
            fields: [
                {
                    fieldName: 'userId',
                    defaultValue: -1
                },
                {
                    fieldName: 'tokenExpires',
                    defaultValue: -1
                },
                {
                    fieldName: 'roles',
                    defaultValue: []
                },
                {
                    fieldName: 'errors',
                    defaultValue: []
                },
                {
                    fieldName: 'system',
                    defaultValue: []
                },
                {
                    order: 1,
                    fieldName: 'email',
                    title: 'Email',
                    schema: {
                        type: 'email',
                        required: 'Email is required',
                        invalidEmail: 'Invalid email address'
                    }
                },
                {
                    order: 2,
                    fieldName: 'phoneNumber',
                    title: 'Phone'
                }
            ]
        },
        {
            title: 'Describe the problem',
            order: 1,
            fields: [
                {
                    order: 1,
                    fieldName: 'issue',
                    title: 'issue',
                    schema: {
                        type: 'string',
                        required: 'Please select a value'
                    }
                },
                {
                    order: 1,
                    fieldName: 'notes',
                    title: 'Notes',
                    type: 'textarea',
                    schema: {
                        type: 'string',
                        required: 'Please provide as many details as possible'
                    }
                },
                {
                    order: 1,
                    fieldName: 'contactTime',
                    title: 'Best time to contact',
                    type: 'textarea',
                    schema: {
                        type: 'string'
                    }
                }
            ]
        }
    ]
};

class SupportForm extends React.Component {
    handleSubmit = values => this.props.save(values, this.props.cancel);

    render() {
        const { user, cancel, resources } = this.props;
        const formDefinition = _.cloneDeep(formDef);

        let initialValues = {
            ...generateInitialValues(formDefinition),
            userId: cookieGetUserId(),
            tokenExpires: cookieGetExpires(),
            email: user.email,
            roles: user.roles,
            phoneNumber: user.mobile,
            errors: user.errors, //TODO: convert errors to an array of strings
            system: process.env.REACT_APP_DASHBOARD
        };

        return (
            <FormWithSchema
                formDefinition={formDefinition}
                initialValues={initialValues}
                resources={resources}
                cancel={cancel}
                formName={FORM_NAME}
                debugSections={[]}
                onSubmit={this.handleSubmit}
            />
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    errors: state.errors,
    resources: state.resources
});

const mapDispatchToProps = dispatch => ({
    save: (form, cancel) => {
        dispatch(apiSupportRequest(form));
        cancel();
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SupportForm);
