import { ENDPOINTS } from '../../../actions/Api/constants';
import _ from 'lodash';

const initialState = [];

function terms(state = initialState, { type, data, companyId }) {
    switch (type) {
        case ENDPOINTS.API.COMPANY_TERMS_GET:
            return [...state.filter(x => _.get(x, 'companyId') !== companyId), ...data];
        default:
            return state;
    }
}

export { terms };
