import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router';
import { getLoadingState } from '../../../reducers/Rest/loadingStates';
import ChoiceListFilteredTableNoDashboardData from '../../common/FilteredTable/ChoiceListFilteredTableNoDashboardData';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { TableDefinition } from '../../common/FilteredTable/tableAndFilterHelpers';
import { mapToRows } from '../../common/Table/addColumnsToRow';
import regDef from '../regDefs';
import EditRegistrationModal from '../Modals/EditRegistrationModal';

class RegistrationsTableCard extends React.Component {
    static defaultProps = {
        breakpoint: 500,
        itemsPerPage: 5,
        noItemsText: 'There are no registrations',
        columns: [
            regDef.dealerName,
            regDef.modelDescription,
            regDef.vehicleDescription,
            regDef.registrationNumber,
            regDef.registrationDate
        ]
    };

    render() {
        const { loadingStates, rows, resources, columns, title, ...rest } = this.props;
        const isLoading = getLoadingState(loadingStates, ENDPOINTS.API.VISIT_ACTIONS_FILTER_COMMIT).isLoading;
        const tableDefinition = new TableDefinition(columns);

        return (
            <EditRegistrationModal>
                {({ viewRegistration }) => (
                    <ChoiceListFilteredTableNoDashboardData
                        id={title}
                        tableDefinition={tableDefinition}
                        rows={mapToRows(
                            rows,
                            regDef.id,
                            resources,
                            tableDefinition.columns,
                            values => viewRegistration(values),
                            undefined
                        )}
                        isLoading={isLoading}
                        tableIsNotInCard={true}
                        cardButtons={[]}
                        title={title}
                        editRow={viewRegistration}
                        {...rest}
                    />
                )}
            </EditRegistrationModal>
        );
    }
}
const mapStateToProps = state => ({
    loadingStates: state.loadingStates,
    resources: state.resources
});

export default connect(
    mapStateToProps,
    null
)(withRouter(RegistrationsTableCard));
