import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import FormikText from '../FormkControls/FormikText';
import FormikDate from '../FormkControls/FormikDate';
import FormikSelectChoicelist from '../FormkControls/FormikSelectChoicelist';
import { SaveCancelButton } from '../SaveCancelButton';
import FormikErrors from '../FormkControls/FormikErrors';
import AuditSummary from '../CommonForms/AuditSummary';
import { DebugSection } from '../DebugSection';
import { DisplayField } from '../DisplayField';
import {
    mapColumnDefToCheckbox,
    mapColumnDefToChoiceList,
    mapColumnDefToDate,
    mapColumnDefToText
} from '../FilteredTable/tableAndFilterHelpers';
import FormikCheckbox from '../FormkControls/FormikCheckbox';
import ButtonGroup from '../ButtonGroup';
import _ from 'lodash';
import ReactiveFormDropzone from './ReactiveFormDropzone';
import FormikHtmlEditor from '../FormkControls/FormikHtmlEditor';
import { Button } from 'reactstrap';

//TODO: Do we want a test to make sure things have formik props passed to them
// https://github.com/kentcdodds/advanced-react-patterns-v2/blob/master/src/exercises-final/02.extra.js

const MapToButtonGroup = ({
    resources,
    columnDef,
    options,
    className,
    formikProps,
    canChange,
    submits = true,
    customOnClick
}) => (
    <ButtonGroup
        choiceList={options || _.get(resources, `choiceList.${columnDef.choiceList}`, [])}
        selectedKey={formikProps.values[columnDef.field]}
        className={className}
        canChange={canChange}
        onButtonClick={x => {
            formikProps.setFieldValue(columnDef.field, x, true);
            submits && formikProps.handleSubmit();
            if (customOnClick) {
                customOnClick();
            }
        }}
    />
);

const ReactiveForm = props => {
    const {
        initialValues,
        validationSchema,
        resources,
        handleSubmit,
        hideCancelButton,
        hideSaveButton,
        cancel,
        saveButtonContent,
        children,
        hideMetaData,
        isInitialValid = false,
        formResetButton,
        onChange,
        ...rest
    } = props;
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            cancel={cancel}
            onSubmit={handleSubmit}
            onChange={onChange}
            isInitialValid={isInitialValid}
            render={formikProps => (
                <form onSubmit={formikProps.handleSubmit}>
                    <div className="row">
                        {React.Children.map(children, (child, index) => {
                            return React.cloneElement(child, { formikProps, index, resources, ...rest });
                        })}
                    </div>
                    <div className="grid-cs-2-form">
                        <FormikErrors {...formikProps} />
                    </div>
                    <div className="grid-cs-1">
                        <DebugSection sections={[{ name: 'Form Values', object: formikProps.values }]} />
                        {formikProps.values.createdDate && !hideMetaData && (
                            <AuditSummary
                                createdDate={formikProps.values.createdDate}
                                updatedDate={formikProps.values.updatedDate}
                                createdBy={formikProps.values.createdBy}
                                updatedBy={formikProps.values.updatedBy}
                                choiceList={resources.choiceList}
                            />
                        )}
                    </div>
                    {hideSaveButton ? null : (
                        <SaveCancelButton
                            pullRight
                            saveButtonContent={saveButtonContent}
                            hideCancelButton={hideCancelButton}
                            onCancel={cancel}
                        />
                    )}
                    {/*{formResetButton ? (*/}
                    {/*<Button*/}
                    {/*type="button"*/}
                    {/*className="mr-2"*/}
                    {/*color="warning"*/}
                    {/*onClick={() => {*/}
                    {/*formikProps.resetForm(initialValues);*/}
                    {/*}}*/}
                    {/*>*/}
                    {/*Reset*/}
                    {/*</Button>*/}
                    {/*) : null}*/}
                </form>
            )}
        />
    );
};

// index: index of the component, 1 is added to form the section number displayed on the screen
// title: heading of the section
// formikProps: a prop from the Formik form library, do not override from your component
// children: react children
// grow: change to false if the form body should always be underneath the section title
// size: boostrap column size, the default 12 is fullscreen
ReactiveForm.Section = ({ show = true, index, title, formikProps, children, grow = true, size = 12, ...rest }) => {
    const marginTop = 'mt-3';

    // By default, the title is next to the form body on large screens, and underneath each other
    // on smaller screens. We can override this to make them be always underneath each other
    // no matter the size of the screen. It's useful for columns that are next to each other
    const classNameHeading = grow ? `col-12 col-md-4 ${marginTop}` : `col-12 ${marginTop}`;
    const classNameBody = grow && title ? `col-12 col-md-8 ${marginTop}` : `col-12 ${marginTop}`;

    return (
        ((_.isFunction(show) && show(formikProps)) || show === true) && (
            <div className={`col-12 col-md-${size}`}>
                <div className="row">
                    {title && (
                        <div className={classNameHeading}>
                            <span>
                                <span className="mr-2 logo-font form-subsection-number">{index + 1}</span>
                                <h4 className={`d-inline logo-font form-subsection-heading`}>{title}</h4>
                            </span>
                        </div>
                    )}
                    <div className={classNameBody}>
                        {React.Children.map(children, child => {
                            const hide = child.props.hide && child.props.hide(formikProps);
                            return hide ? null : React.cloneElement(child, { formikProps, ...rest });
                        })}
                    </div>
                </div>
            </div>
        )
    );
};

ReactiveForm.DisplayInColumns = ({
    columnClasses = ['col-12 col-sm-6 text-left', 'col-12 col-sm-6 text-right'],
    children,
    formikProps,
    ...rest
}) => (
    <div className="row">
        {React.Children.map(children, (child, index) => {
            const hide = child.props.hide && child.props.hide(formikProps);
            return hide ? null : (
                <div className={columnClasses[index % columnClasses.length]}>
                    {React.cloneElement(child, { formikProps, ...rest })}
                </div>
            );
        })}
    </div>
);

ReactiveForm.Text = ({ formikProps, columnDef, ...rest }) => (
    <FormikText {...mapColumnDefToText(columnDef)} {...rest} {...formikProps} />
);

ReactiveForm.HtmlEditor = ({ formikProps, columnDef, ...rest }) => (
    <DisplayField title={columnDef.title} value={<FormikHtmlEditor fieldName={columnDef.field} {...formikProps} />} />
);

ReactiveForm.Choicelist = ({ formikProps, columnDef, options, ...rest }) => (
    <FormikSelectChoicelist
        {...mapColumnDefToChoiceList(columnDef)}
        options={options ? (typeof options === 'function' ? options(formikProps) : options) : undefined}
        {...rest}
        {...formikProps}
    />
);

const personSelectorMapStateToProps = state => ({ people: state.people });
const PersonSelector = ({ people, formikProps, columnDef, companyId, ...rest }) => {
    const options = people
        .filter(person => (companyId ? person.companyId === companyId : true))
        .map(x => ({
            key: x.personId,
            value: x.descriptor
        }));
    return (
        <FormikSelectChoicelist {...mapColumnDefToChoiceList(columnDef)} {...rest} {...formikProps} options={options} />
    );
};
ReactiveForm.PersonSelector = connect(personSelectorMapStateToProps)(PersonSelector);

ReactiveForm.TextWithInputGroupAddons = ({
    formikProps,
    resources,
    buttonGroupColumnDef,
    canChange,
    submits,
    options,
    columnDef,
    ...rest
}) => (
    <FormikText
        inputGroupAddons={
            <MapToButtonGroup
                className="input-group-append"
                resources={resources}
                options={options}
                columnDef={buttonGroupColumnDef}
                formikProps={formikProps}
                canChange={canChange}
                submits={submits}
            />
        }
        {...mapColumnDefToText(columnDef)}
        resources
        {...rest}
        {...formikProps}
    />
);

ReactiveForm.TextWithSubmit = ({ formikProps, resources, buttonText, canChange, columnDef, ...rest }) => (
    <FormikText
        inputGroupAddons={
            <Button type="submit" className="btn btn-primary">
                {buttonText}
            </Button>
        }
        {...mapColumnDefToText(columnDef)}
        resources
        {...rest}
        {...formikProps}
    />
);

ReactiveForm.ButtonGroup = ({
    hideTitle = false,
    formikProps,
    options,
    submits,
    columnDef,
    resources,
    canChange,
    customOnClick,
    ...rest
}) => {
    return (
        <DisplayField
            title={!hideTitle && columnDef.title}
            value={
                <div className="mt-1">
                    <MapToButtonGroup
                        resources={resources}
                        canChange={!canChange || canChange(formikProps)}
                        columnDef={columnDef}
                        options={options}
                        submits={submits}
                        formikProps={formikProps}
                        customOnClick={customOnClick}
                    />
                </div>
            }
        />
    );
};

ReactiveForm.Checkbox = ({ formikProps, columnDef, ...rest }) => (
    <FormikCheckbox {...mapColumnDefToCheckbox(columnDef)} {...rest} {...formikProps} />
);

ReactiveForm.Date = ({ formikProps, columnDef, ...rest }) => (
    <FormikDate {...mapColumnDefToDate(columnDef)} {...rest} {...formikProps} />
);

ReactiveForm.ColumnValue = ({ formikProps, columnDef, options }) => {
    const value = formikProps.values[columnDef.field];
    const option = options && options.find(x => x.key === value);

    return (
        <DisplayField
            title={columnDef.title}
            value={<div className="mt-3 mb-4 ml-2">{option ? option.value : value}</div>}
        />
    );
};
ReactiveForm.Dropzone = ({ formikProps: { values, setFieldValue }, columnDef }) => (
    <DisplayField
        title={columnDef.title}
        value={<ReactiveFormDropzone values={values} setFieldValue={setFieldValue} {...columnDef} />}
    />
);

ReactiveForm.Value = ({ title, value, formikProps }) => (
    <DisplayField title={title} value={<div className="mt-3 mb-4 ml-2">{value(formikProps)}</div>} />
);

ReactiveForm.Other = ({ formikProps, children }) => children(formikProps);

export default ReactiveForm;
