import _ from 'lodash';

import {
    SET_SHOW_COMMUNICATION_TYPE_ON_CALENDAR,
    SET_SHOW_ON_AGENDA,
    SET_OPEN_IN_MODALS_OR_IN_PAGE,
    SET_CALENDAR_FILTER
} from '../../actions/settings';

const defaultState = {
    showOnCalendarCommunicationTypes: {
        holiday: true,
        adminDay: true,
        faceToFace: true,
        phoneOut: true,
        visitReportAction: true
    },
    openInModals: true,
    calendarFilters: {},
    agendaFilters: {}
};

export const settings = (state = defaultState, { type, data }) => {
    switch (type) {
        case SET_SHOW_COMMUNICATION_TYPE_ON_CALENDAR:
            return {
                ...state,
                showOnCalendarCommunicationTypes: {
                    ...state.showOnCalendarCommunicationTypes,
                    [data.type]: data.showOnCalendar
                }
            };

        case SET_SHOW_ON_AGENDA:
            return {
                ...state,
                agendaFilters: {
                    ...state.agendaFilters,
                    [`${data.filter}/${data.type}`]: data.show
                }
            };

        case SET_OPEN_IN_MODALS_OR_IN_PAGE:
            return { ...state, openInModals: data };

        case SET_CALENDAR_FILTER:
            const { filterName, optionName, show } = data;
            const updatedCalendarFilters = { ...state.calendarFilters };
            updatedCalendarFilters[filterName] = {
                ...updatedCalendarFilters[filterName],
                [optionName]: show
            };
            return {
                ...state,
                calendarFilters: updatedCalendarFilters
            };

        default:
            return state;
    }
};

export const showOnAgenda = (agendaFilters, filter, type) => {
    const res = agendaFilters[`${filter}/${type}`];
    return res === undefined || res;
};

export const filterVisitActionsForCalendar = (filterData, visitActions) => {
    return visitActions ? visitActions.filter(x => _.get(filterData, `status.${x.status}`, true)) : [];
};

export const filterForCalendar = (filterData, comms) => {
    let result = [...comms];
    Object.keys(filterData).forEach(filter => {
        const currentFilter = filterData[filter];
        const blockedValues = Object.keys(currentFilter).reduce(
            (acc, next) => (currentFilter[next] === false ? [...acc, next] : acc),
            []
        );
        result = result.filter(x => !blockedValues.includes(x[filter]));
    });
    return result;
};
