import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router';
import { getLoadingState } from '../../../reducers/Rest/loadingStates';
import ChoiceListFilteredTableNoDashboardData from '../../common/FilteredTable/ChoiceListFilteredTableNoDashboardData';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { TableDefinition } from '../../common/FilteredTable/tableAndFilterHelpers';
import meeting from '../meeting';
import { mapToRows } from '../../common/Table/addColumnsToRow';
import { CREATE_MEETING_TYPE_MEETING, createMeeting } from '../../../actions/Navigation/Communication/createMeeting';

class MeetingTableCard extends React.Component {
    static defaultProps = {
        breakpoint: 500,
        itemsPerPage: 5,
        noItemsText: 'There are no meetings to show',
        columns: [meeting.startDate, meeting.activityRegarding, meeting.assigneeId, meeting.status, meeting.body]
    };

    renderButtons = () => {
        const { buttons, cardButtons, createMeeting, companyId } = this.props;
        const items = cardButtons || [];

        if (companyId && buttons && buttons.includes('addMeeting')) {
            items.push({
                text: 'Add Meeting',
                fontAwesomeName: 'fa-plus',
                onClick: () => createMeeting(companyId)
            });
        }

        return items;
    };

    render() {
        const { loadingStates, rows, resources, openCompany, editRow, title, columns, ...rest } = this.props;
        const isLoading = getLoadingState(loadingStates, ENDPOINTS.API.MEETING_FILTER).isLoading;

        const tableDefinition = new TableDefinition(columns);

        const tableRows = mapToRows(rows, meeting.id, resources, tableDefinition.columns, values => editRow(values), {
            openCompany
        });

        return (
            <ChoiceListFilteredTableNoDashboardData
                id={title}
                tableDefinition={tableDefinition}
                rows={tableRows}
                isLoading={isLoading}
                showInCard={true}
                cardButtons={this.renderButtons()}
                title={title}
                {...rest}
            />
        );
    }
}

const mapStateToProps = state => ({
    loadingStates: state.loadingStates,
    resources: state.resources
});

const mapDispatchToProps = dispatch => ({
    createMeeting: companyId => dispatch(createMeeting(companyId, CREATE_MEETING_TYPE_MEETING))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(MeetingTableCard));
