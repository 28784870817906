import { isLoading } from '../../Loading/isLoading';
import { isError } from '../../Loading/isError';
import { toastr } from 'react-redux-toastr';
import { makeHeaders, makePath, makeUrl } from '../apiHelpers';
import { ENDPOINTS, IAction } from '../constants';
import axios, {AxiosRequestConfig} from 'axios';
import { dispatchServiceIsLoading } from '../../../reducers/Rest/loadingStates';
import { Dispatch } from 'redux';

export interface IOpportunityFilter {
	companyId?: number;
	assigneeId?: number;
	stages?: string[];
}

export const apiOpportunityFilter = (filter: IOpportunityFilter) => async (
	dispatch: Dispatch<IAction>,
	getState: () => any
) => {
	if (!getState().offline.online) {
		return false;
	}

	const type = ENDPOINTS.API.OPPORTUNITY_FILTER_COMMIT;

	try {
		dispatchServiceIsLoading(dispatch, type, true, {});

		const axiosRequest:AxiosRequestConfig = {
			method: 'POST',
			url: makeUrl(getState(), makePath(['opportunity', 'filter']), {}),
			headers: makeHeaders(),
			data: filter,
		};

		const result = await axios(axiosRequest);

		dispatch({ type, payload: { data: result.data, filter } });
		isLoading(dispatch, type, false, {});
		return true;
	} catch (error) {
		isError(dispatch, type, true, error.message, {});
		if (getState().user.userId > -1) {
			toastr.error(type, `Response ${error.message}`);
		}
		return false;
	}
};
