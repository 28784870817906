import React from 'react';
import { connect } from 'react-redux';
import eventTypes from '../eventTypes';
import _ from 'lodash';
import { FormatCompanyNameLink } from '../../common/Table/ColumnTypeFormatCompanyNameOneLine';
import CaptionBadge from '../../common/FilteredTable/CaptionBadge';

const CalendarEventContentMeeting = ({ resources, data }) => {
    return (
        <div>
            <CaptionBadge
                caption={data.status}
                resources={resources}
                choiceList="communicationStatus"
                className="mr-1"
            />
            Meeting
            <br />
            <FormatCompanyNameLink values={data} />
        </div>
    );
};
const CalendarEventContentScheduledContact = ({ resources, data }) => {
    const communicationActions = _.get(resources, `choiceList.communicationAction`, []);
    const actionChoice = communicationActions.find(x => x.key === data.action);
    return (
        <div>
            <CaptionBadge
                caption={data.status}
                resources={resources}
                choiceList="communicationStatus"
                className="mr-1"
            />
            {actionChoice ? actionChoice.value : data.action}
            <br />
            <FormatCompanyNameLink values={data} />
        </div>
    );
};

const CalendarEventContentVisitAction = ({ resources, data }) => {
    const visitActionActivityRegarding = _.get(resources, `choiceList.visitActionActivityRegarding`, []);
    const choicelist = visitActionActivityRegarding.find(x => x.key === data.activityRegarding);
    return (
        <span>
            <CaptionBadge
                caption={data.status}
                resources={resources}
                choiceList="communicationStatus"
                className="mr-1"
            />
            {choicelist ? choicelist.value.replace('Visit Action - ', '') : data.activityRegarding}
            <br />
            <FormatCompanyNameLink values={data} />
        </span>
    );
};

const CalendarEventContentDay = ({ type, data }) => {
    return (
        <span className="text-truncate">
            {type}
            {data.notes ? ` - ${data.notes}` : ''}
        </span>
    );
};

const CalendarEventContent = ({ resources, type: { name }, data, id }) => {
    switch (name) {
        case eventTypes.FaceToFaceMeeting.name:
        case eventTypes.TelephoneMeeting.name:
            return <CalendarEventContentMeeting resources={resources} data={data} />;
        case eventTypes.ScheduledContact.name:
            return <CalendarEventContentScheduledContact resources={resources} data={data} />;
        case eventTypes.VisitReportAction.name:
            return <CalendarEventContentVisitAction resources={resources} data={data} />;
        case eventTypes.AdminDay.name:
            return <CalendarEventContentDay type="Admin Day" data={data} />;
        case eventTypes.Holiday.name:
            return <CalendarEventContentDay type="Holiday" data={data} />;
        case eventTypes.Event.name:
            return <CalendarEventContentDay type="Event" data={data} />;
        default:
            return <pre>{JSON.stringify(data, null, 2)}</pre>;
    }
};

const mapStateToProps = state => ({
    resources: state.resources
});

export default connect(mapStateToProps)(CalendarEventContent);
