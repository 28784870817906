import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { closeModal, FORM_PATHS } from '../../../actions/modals';
import { AppModalHeader } from '../../common/AppModalHeader';
import CompleteMeetingForm from '../Forms/CompleteMeetingForm';

class CompleteMeetingModal extends React.Component {
    render() {
        const { modals, setModalClosed } = this.props;
        return modals.CompleteMeetingModal.isOpen ? (
            <Modal isOpen toggle={() => setModalClosed()} className={this.props.className}>
                <AppModalHeader toggle={() => setModalClosed()}>Complete Meeting</AppModalHeader>
                <ModalBody>
                    <CompleteMeetingForm
                        onCancel={() => setModalClosed()}
                        meetingId={modals.CompleteMeetingModal.data.meetingId}
                        companyId={modals.CompleteMeetingModal.data.companyId}
                    />
                </ModalBody>
            </Modal>
        ) : (
            ''
        );
    }
}

const mapStateToProps = state => ({
    modals: state.modals
});

const mapDispatchToProps = dispatch => ({
    setModalClosed: () => dispatch(closeModal(FORM_PATHS.CompleteMeetingModal.modalName))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompleteMeetingModal);
