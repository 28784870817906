import React from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ENDPOINTS } from '../../../actions/Api/constants';

export const CompanyCommunicationsContext = React.createContext({
    companyId: undefined,
    communications: [],
    isLoading: false
});

const CompanyCommunicationsProvider = ({ companyId, isLoading, communications, children }) => {
    return (
        <CompanyCommunicationsContext.Provider value={{ companyId, communications, isLoading }}>
            {children}
        </CompanyCommunicationsContext.Provider>
    );
};

const makeMapStateToProps = () => {
    const getState = createSelector(
        [
            state => _.get(state.loadingStates, `${ENDPOINTS.API.COMPANY}.isLoading`, false),
            (state, { companyId }) => state.communications.filter(x => x.company.id === companyId)
        ],
        (isLoading, communications) => ({
            isLoading,
            communications
        })
    );
    return (state, props) => getState(state, props);
};

export default connect(makeMapStateToProps)(CompanyCommunicationsProvider);
