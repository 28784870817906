import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { FORM_NAMES } from '../../../actions/formNames';
import ReactiveForm from '../../common/ReactiveForm';
import { apiEventPost } from '../../../actions/Api/Event/apiEventPost';
import regDef from '../regDefs';
import { FormatDate } from '../../common/FormatDate';

const FORM_NAME = FORM_NAMES.EDIT_DAY;

const ViewRegistrationForm = ({ onCancel, registration: { registrationId }, registrations, resources }) => {
    console.log(registrationId);
    const registration = useMemo(() => registrations.find(reg => reg.registrationId === registrationId), [
        registrationId,
        registrations
    ]);

    return (
        <ReactiveForm
            initialValues={registration}
            cancel={onCancel}
            resources={resources}
            formName={FORM_NAME}
            handleSubmit={onCancel}
            hideCancelButton={true}
            saveButtonContent={'Close'}
        >
            <ReactiveForm.Section title="Company" grow={false}>
                <ReactiveForm.DisplayInColumns columnClasses={['col-12 col-sm-6 text-left']}>
                    <ReactiveForm.ColumnValue columnDef={regDef.salesChannel} />
                    <ReactiveForm.ColumnValue columnDef={regDef.ucn} />
                </ReactiveForm.DisplayInColumns>
            </ReactiveForm.Section>
            <ReactiveForm.Section title="Dealer" grow={false}>
                <ReactiveForm.DisplayInColumns columnClasses={['col-12 col-sm-6 text-left']}>
                    <ReactiveForm.ColumnValue columnDef={regDef.dealerCode} />
                    <ReactiveForm.ColumnValue columnDef={regDef.dealerName} />
                </ReactiveForm.DisplayInColumns>
            </ReactiveForm.Section>
            <ReactiveForm.Section title="Vehicle" grow={false}>
                <ReactiveForm.DisplayInColumns columnClasses={['col-12 col-sm-6 text-left']}>
                    <ReactiveForm.ColumnValue columnDef={regDef.vehicleDescription} />
                    <ReactiveForm.ColumnValue columnDef={regDef.carLine} />
                    <ReactiveForm.ColumnValue columnDef={regDef.registrationNumber} />
                    <ReactiveForm.Value
                        title={regDef.registrationDate.title}
                        value={value => <FormatDate date={value} />}
                    />
                    <ReactiveForm.ColumnValue columnDef={regDef.modelDescription} />
                    <ReactiveForm.ColumnValue columnDef={regDef.variantCode} />
                    <ReactiveForm.ColumnValue columnDef={regDef.vin} />
                </ReactiveForm.DisplayInColumns>
            </ReactiveForm.Section>
            <ReactiveForm.Section title="Style" grow={false}>
                <ReactiveForm.DisplayInColumns columnClasses={['col-12 col-sm-6 text-left']}>
                    <ReactiveForm.ColumnValue columnDef={regDef.colourCode} />
                    <ReactiveForm.ColumnValue columnDef={regDef.colourDescription} />
                    <ReactiveForm.ColumnValue columnDef={regDef.paintType} />
                </ReactiveForm.DisplayInColumns>
            </ReactiveForm.Section>
            <ReactiveForm.Section />
        </ReactiveForm>
    );
};

const mapStateToProps = state => ({
    registrations: state.registrations,
    resources: state.resources,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    save: (event, cancel) => {
        dispatch(apiEventPost(event));
        cancel();
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewRegistrationForm);
