import React from 'react';
import Dropzone from 'react-dropzone';
import connect from 'react-redux/es/connect/connect';
import { uploadFile } from '../../../actions/Api/uploadFile';

class ReactiveFormDropzone extends React.Component {
    state = { files: [] };

    onDrop = files => {
        const { setFieldValue, field } = this.props;
        setFieldValue(field, '', true);
        files.forEach(file => {
            const reader = new FileReader();

            reader.onload = () => {
                const fileAsBinaryString = reader.result;

                this.uploadEvidence(fileAsBinaryString, file);

                //
                // do whatever you want with the file content
            };
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');

            //reader.readAsBinaryString(file);
            reader.readAsDataURL(file);
        });

        this.setState({
            files: [...files]
        });
    };

    uploadEvidence = (fileAsBinaryString, file) => {
        const { setFieldValue, field, s3bucket, values, imageWidth, imageHeight } = this.props;
        uploadFile({
            file: fileAsBinaryString,
            name: file.name,
            type: file.type,
            bucket: s3bucket,
            imageWidth,
            imageHeight
        })
            .then(result => {
                setFieldValue(field, values[field] ? `${values[field]}; result.data` : result.data, true);
            })
            .catch(error => {
                /*toast.update(toastId, {
                    render: error ? error.message : 'Error Saving',
                    type: toast.TYPE.ERROR,
                    autoClose: 5000
                });
                this.setIsLoading(false, ...user);*/
                console.log(error);
            });
    };

    renderFiles = text => (
        <div>
            {this.state.files.length === 0 && text}
            <ul>
                {this.state.files.map(f => (
                    <li key={f.name}>
                        {f.name} - {f.size} bytes
                    </li>
                ))}
            </ul>
        </div>
    );

    render() {
        const { values, field, accept, multiple = true } = this.props;
        return (
            <>
                <div className="mt-3 mb-4 ml-2">
                    {' '}
                    {values[field]
                        ? values[field].split('; ').map(url => (
                              <a key={url} href={url}>
                                  {url}
                              </a>
                          ))
                        : null}
                </div>
                <Dropzone
                    accept={accept}
                    className="w-100 mt-4 mb-4 border border-primary hover-pointer bg-light p-2"
                    style={{ minHeight: '100px' }}
                    multiple={multiple}
                    onDrop={this.onDrop}
                >
                    {({ isDragActive, isDragReject, acceptedFiles, rejectedFiles }) => {
                        if (isDragActive) {
                            return 'This file is authorized';
                        }
                        if (isDragReject) {
                            return 'This file is not authorized';
                        }
                        return acceptedFiles.length || rejectedFiles.length
                            ? this.renderFiles(
                                  `Accepted ${acceptedFiles.length}, rejected ${rejectedFiles.length} files`
                              )
                            : this.renderFiles(this.props.title);
                    }}
                </Dropzone>
            </>
        );
    }
}

const mapStateToProps = state => ({
    resources: state.resources
});

const mapDispatchToProps = dispatch => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReactiveFormDropzone);
