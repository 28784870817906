import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { getUserFromState } from '../../../selectors/Api/User/userSelector';
import { getOpportunitiesFromState } from '../../../selectors/Api/Opportunity/opportunitySelector';
import OpportunitiesTableCard from './OpportunitiesTableCard';
import opportunity from '../opportunity';
import pipe from 'lodash/fp/pipe';
import sortBy from 'lodash/fp/sortBy';
import filter from 'lodash/fp/filter';
import allPass from 'lodash/fp/allPass';
import moment from 'moment';
//import _ from 'lodash';
/*
const filterIsAssignedToUser = (opportunities, userId) =>
  opportunities.filter(oppo => {
    const id = _.get(oppo, 'assignee.id', '');
    return id && id.toString() === userId.toString();
  });
*/
const opportunityInProgress = oppo =>
    ['ProspectEarlyStages', 'ProspectDecisionPoint', 'ImminentOpportunity'].indexOf(oppo.stage) > -1;
const opportunityPastEndDate = oppo => moment(oppo.estimatedConversionDate).isBefore(moment());

export const filterAssigneeOutstandingOpportunities = pipe(
    //filterIsAssignedToUser,
    filter(allPass([opportunityInProgress, opportunityPastEndDate])),
    sortBy('CreatedDate')
);

class OutstandingOpportunitiesTable extends React.Component {
    render() {
        const { opportunities, isLoading = false } = this.props;

        return (
            <div className="mt-5">
                <OpportunitiesTableCard
                    rows={opportunities}
                    isLoading={isLoading}
                    title="Outstanding Opportunities"
                    columns={[
                        opportunity.companyName,
                        opportunity.description,
                        opportunity.year,
                        opportunity.estimatedConversionDate,
                        opportunity.likelihood
                    ]}
                />
            </div>
        );
    }
}

const makeMapStateToProps = () => {
    const getState = createSelector(
        [getOpportunitiesFromState, getUserFromState],
        (opportunities, user) => ({
            opportunities: filterAssigneeOutstandingOpportunities(opportunities /*, user.userId*/)
        })
    );
    return (state, props) => getState(state, props);
};

export default connect(makeMapStateToProps)(OutstandingOpportunitiesTable);
