import React, { useContext } from 'react';
import RegistrationsTableCard from './RegistrationsTableCard';
import { CompanyRegistrationsContext } from '../../Company/Provider/CompanyRegistrationsProvider';

const CompanyRegistrationsTable = () => {
    const { registrations } = useContext(CompanyRegistrationsContext);
    return <RegistrationsTableCard rows={registrations} isLoading={false} tableIsNotInCard={true} />;
};

export default CompanyRegistrationsTable;
