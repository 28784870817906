import * as yup from 'yup';

export const mandatoryString = message =>
    yup
        .string()
        .nullable()
        .min(1)
        .required(message);

export const fieldDefMandatoryString = columnDef => mandatoryString(`${columnDef.title} is required`);

export const mandatoryStringFields = columnDefs =>
    columnDefs.reduce((acc, columnDef) => {
        acc[columnDef.field] = fieldDefMandatoryString(columnDef);
        return acc;
    }, {});
