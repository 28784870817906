import React, { useContext } from 'react';
import OpportunitiesTableCard from './OpportunitiesTableCard';
import { CompanyOpportunitiesContext } from '../../Company/Provider/CompanyOpportunitiesProvider';
import opportunity from '../opportunity';

export default ({ showFilter }) => {
    const { opportunities, isLoading } = useContext(CompanyOpportunitiesContext);
    return (
        <OpportunitiesTableCard
            showFilter={showFilter}
            rows={opportunities}
            isLoading={isLoading}
            title="Opportunities"
            tableIsNotInCard={true}
            columns={[
                opportunity.year,
                opportunity.estimatedConversionDate,
                opportunity.description,
                opportunity.stage,
                opportunity.likelihood
            ]}
        />
    );
};
