import React from 'react';
import CompanyTabs from '../../Company/Tabs/CompanyTabs';
import CompanyVisitActionsTable from '../../VisitActions/Tables/CompanyVisitActionsTable';
import CompanyCommunicationsTable from '../../Communication/Tables/CompanyCommunicationsTable';
import CompanyPrimaryPeople from '../../Person/Components/CompanyPrimaryPeople';
import CompanyDetails from '../../Company/Components/CompanyDetails';
import CompanyPrimaryAddressesDetails from '../../Address/Components/CompanyPrimaryAddressesDetails';
import CompanyActions from '../../Company/Components/CompanyActions';
import CompanyProvider from '../../Company/Provider/CompanyProvider';
import CompanyPageTitleContent from '../../Company/Components/CompanyPageTitleContent';
import CompanyTitle from '../../Company/Components/CompanyTitle';
import CompanyIsLoadingSpinner from '../../Company/Components/CompanyIsLoadingSpinner';
import PageSection from '../../common/PageSection';
import RedirectNotLoggedIn from '../RedirectNotLoggedIn';
import { apiCompanyOffline } from '../../../actions/Api/Company/apiCompanyOffline';
import { visitActionsQuery } from '../../../actions/Api/VisitAction/apiVisitActionsFilter';
import { apiOpportunityFilter } from '../../../actions/Api/Opportunity/apiOpportunityFilter';
import { apiUserLogPost } from '../../../actions/Api/User/apiUserLogPost';
import { apiCompanyTerms } from '../../../actions/Api/Terms/apiCompanyTerms';
import { apiCompanyDemos } from '../../../actions/Api/Demo/apiCompanyDemos';
import { apiMeetingFilter } from '../../../actions/Api/Meeting/apiMeetingFilter';
import { apiRegistrationCompany } from '../../../actions/Api/Registrations/apiRegistrationCompany';
import Layout3Columns, { Column1, Column2, Column3 } from '../../Dashboard/Components/Layout3Columns';
import CompanyMeetingsTable from '../../Meeting/Tables/CompanyMeetingsTable';
import { apiCommunicationFilter } from '../../../actions/Api/Communication/apiCommunicationFilter';
import Next7DaysAgendaCard from '../../Calendar/Cards/Next7DaysAgendaCard';

export default ({
    location: { pathname },
    match: {
        params: { companyId: strCompanyId, companyName }
    },
    history
}) => {
    const companyId = parseInt(strCompanyId, 10);
    return (
        <CompanyProvider companyId={companyId}>
            <PageSection
                onBackClicked={history.goBack}
                title={<CompanyTitle />}
                titleExtra={<CompanyIsLoadingSpinner />}
                titleContent={<CompanyPageTitleContent />}
            >
                <RedirectNotLoggedIn
                    onLoggedInDispatchArray={[
                        apiCompanyOffline(companyId, true),
                        visitActionsQuery()
                            .filterCompany(companyId)
                            .setFetchCompany(false)
                            .run(),
                        apiOpportunityFilter({ companyId }),
                        apiUserLogPost(companyName, 'Company', pathname),
                        apiCompanyTerms(companyId),
                        apiCompanyDemos(companyId),
                        apiRegistrationCompany(companyId),
                        apiMeetingFilter({ companyId }),
                        apiCommunicationFilter({ companyId })
                    ]}
                />
                <Layout3Columns>
                    <Column1>
                        <CompanyDetails hideCompanyName />
                        <CompanyPrimaryPeople />
                        <CompanyPrimaryAddressesDetails />
                    </Column1>
                    <Column2>
                        <CompanyTabs />
                        <CompanyMeetingsTable />
                        <CompanyCommunicationsTable />
                        <CompanyVisitActionsTable />
                    </Column2>
                    <Column3>
                        <CompanyActions />
                        <Next7DaysAgendaCard companyId={companyId} userId={undefined} title="Upcoming" />
                    </Column3>
                </Layout3Columns>
            </PageSection>
        </CompanyProvider>
    );
};
