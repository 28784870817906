import {
    basicColumnDef,
    booleanColumnDef,
    choiceListColumnDef,
    dateColumnDef,
    idColumnDef,
} from '../common/FilteredTable/tableAndFilterHelpers';
import React from 'react';
import ColumnTypeFormatCompanyName from '../common/Table/ColumnTypeFormatCompanyName';

const opportunity = {
    id: idColumnDef('id', 'opportunityId'),
    company: {
        ...basicColumnDef('Company', 'companyDescriptor'),
    },
    companyId: {
        ...basicColumnDef('Company Id', 'companyId'),
    },
    companyName: {
        ...basicColumnDef('Company Name', 'companyName'),
        mapping: (resources, values, field, action) => (values && values.company ? values.company.descriptor : ''),
        mapToDisplayedValue: (resources, values, field, actions) => (
            <ColumnTypeFormatCompanyName actions={actions} values={values} />
        ),
    },
    description: {
        ...basicColumnDef('Description', 'description'),
    },
    note: {
        ...basicColumnDef('Details', 'note'),
        type: 'textarea',
    },
    lostReasonOther: {
        ...basicColumnDef('Lost Reason Other', 'lostReasonOther'),
        type: 'textarea',
    },
    estimatedConversionDate: {
        ...dateColumnDef('Projected Conversion Date', 'estimatedConversionDate', true, { displayEmptyAs: '-' }),
    },
    model: {
        ...choiceListColumnDef('Model', 'model', 'opportunityModel'),
        emptyOption: 'Select a model...',
    },
    stage: {
        ...choiceListColumnDef('Stage', 'stage', 'opportunityStage'),
        emptyOption: 'Select a Stage...',
    },
    type: {
        ...choiceListColumnDef('type', 'type', 'opportunityType'),
        emptyOption: 'Select a type...',
    },
    unitPotential: {
        ...basicColumnDef('Volume Potential', 'unitPotential'),
        type: 'number',
    },
    updatedDate: {
        ...dateColumnDef('Updated', 'updatedDate'),
    },
    termsLoaded: {
        ...booleanColumnDef('Have the terms been loaded with the leasing company?', 'termsLoaded'),
    },
    termsLoadedChoiceList: {
        ...choiceListColumnDef(
            'Have the terms been loaded with the leasing company?',
            'termsLoaded',
            'opportunityTermsLoaded'
        ),
        emptyOption: 'Select whether the terms have been loaded...',
    },
    likelihood: {
        ...choiceListColumnDef('Likelihood', 'likelihood', 'opportunityLikelihood'),
        emptyOption: 'How Likely is this Opportunity...',
    },
    likelihoodJustification: {
        ...basicColumnDef('Likelihood Justification', 'likelihoodJustification'),
        emptyOption: "Only mandatory if likelihood is 'Unknown'",
    },
    fuelType: {
        ...choiceListColumnDef('Fuel Type', 'fuelType', 'opportunityFuelType'),
        emptyOption: 'Select a fuel type...',
    },
    wonLostReason: {
        ...choiceListColumnDef('Won / Lost Reason', 'wonLostReason', 'opportunityWonLostReason'),
        emptyOption: 'Select the reason this opportunity was won or lost...',
    },
    product: {
        ...choiceListColumnDef('Product', 'product', 'opportunityProduct', {
            renderAsBasicText: true,
            displayEmptyAs: '-',
        }),
        emptyOption: 'Select the product...',
        right: false,
    },
    competitorLostTo: {
        ...basicColumnDef('Competitor Lost To', 'competitorLostTo'),
    },
    assignee: {
        ...choiceListColumnDef('Assignee', 'assigneeId', 'communicationAssignee'),
        emptyOption: 'Assign the opportunity...',
    },
    leasingCompany: {
        ...choiceListColumnDef('Leasing Company', 'leasingCompany', 'opportunityLeasingCompany'),
        emptyOption: 'Select the leasing company...',
    },
    leasingCompanyPerson: {
        ...choiceListColumnDef('Leasing Company Person', 'leasingCompanyPerson.id', 'opportunityLeasingCompanyPerson'),
        emptyOption: 'Select the contact from the leasing company...',
    },
    primaryContact: {
        ...basicColumnDef('Person', 'primaryContact.id'),
    },
    year: {
        ...choiceListColumnDef('Opportunity Year', 'year', 'opportunityYear', {
            renderAsBasicText: true,
            displayEmptyAs: '-',
        }),
        emptyOption: 'Select the year...',
        right: false,
    },
};

export default opportunity;
