import { basicColumnDef, idColumnDef } from '../common/FilteredTable/tableAndFilterHelpers';

const companySummaryDef = {
    id: idColumnDef('id', 'companyId'),
    descriptor: {
        ...basicColumnDef('Company', 'descriptor')
    },
    postcode: {
        ...basicColumnDef('Postcode', 'postcode')
    },
    ucn: {
        ...basicColumnDef('UCN Number', 'ucn')
    }
};

export default companySummaryDef;
