import {
    basicColumnDef,
    dateColumnDef,
    idColumnDef,
    choiceListColumnDef
} from '../common/FilteredTable/tableAndFilterHelpers';

export default {
    id: idColumnDef('id', 'termsId'),
    typeOfTerms: {
        ...choiceListColumnDef('Type of Terms', 'typeOfTerms', 'termsType', {
            renderAsBasicText: true,
            displayEmptyAs: '-'
        }),
        right: false
    },
    actualStartDate: {
        ...dateColumnDef('Actual Start Date', 'actualStartDate', false, { displayEmptyAs: '-' })
    },
    actualEndDate: {
        ...dateColumnDef('Actual End Date', 'actualEndDate', false, { displayEmptyAs: '-' })
    },
    requestedByName: {
        ...basicColumnDef('Requested By', 'requestedByName', { displayEmptyAs: '-' }),
        type: 'text'
    },
    status: {
        ...choiceListColumnDef('Status', 'status', 'termsStatus', { renderAsBasicText: true, displayEmptyAs: '-' }),
        type: 'text'
    },
    contactName: {
        ...basicColumnDef('Terms Contact', 'contactName', { displayEmptyAs: '-' }),
        type: 'text'
    }
};
