import { isError } from '../../Loading/isError';
import { toastr } from 'react-redux-toastr';
import { ENDPOINTS } from '../constants';
import { getAxiosPostOfflineRequest, REST_TIME_FORMAT } from '../apiHelpers';
import moment from 'moment';
import _ from 'lodash';

export const apiOpportunityUpdateStage = (opportunityId, opportunityStage) => async (dispatch, getState) => {
    const opportunity = _.find(_.get(getState(), 'opportunities', []), x => x.opportunityId === opportunityId);
    if (opportunity) {
        opportunity.stage = opportunityStage;
        dispatch(apiOpportunityEdit(opportunity));
    } else {
        console.warn(`Could not find an agreement Opportunity of id:${opportunityId}`);
    }
};

export const apiOpportunityUpdateStatus = (opportunityId, opportunityStatus) => async (dispatch, getState) => {
    const opportunity = _.find(_.get(getState(), 'opportunities', []), x => x.opportunityId === opportunityId);
    if (opportunity) {
        opportunity.status = opportunityStatus;
        dispatch(apiOpportunityEdit(opportunity));
    } else {
        console.warn(`Could not find an agreement Opportunity of id:${opportunityId}`);
    }
};

export const apiOpportunityEdit = opportunity => async (dispatch, getState) => {
    const type = ENDPOINTS.API.OPPORTUNITY_POST;

    try {
        opportunity.updatedDate = moment().format(REST_TIME_FORMAT);
        opportunity.dealership = { id: getState().user.dealershipId };
        opportunity.volumePotential = opportunity.totalUnits;
        const data = { ...opportunity };
        if (data.opportunityId < 0) {
            data.opportunityId = null;
            data.createdDate = moment().format(REST_TIME_FORMAT);
        }

        const axiosRequest = getAxiosPostOfflineRequest(['opportunity'], {}, data);

        let unmodifiedOpportunity = getState().opportunities.find(x => opportunity.opportunityId === x.opportunityId);
        if (!unmodifiedOpportunity) {
            unmodifiedOpportunity = opportunity;
        }

        dispatch({
            type,
            data: { ...opportunity, syncing: true },
            meta: {
                offline: {
                    effect: axiosRequest,
                    commit: { type: ENDPOINTS.API.OPPORTUNITY_POST_COMMIT, meta: { opportunity } },
                    rollback: { type: ENDPOINTS.API.OPPORTUNITY_POST_ROLLBACK, meta: { unmodifiedOpportunity } }
                }
            }
        });

        return true;
    } catch (error) {
        isError(dispatch, type, true, error.message, {});
        toastr.error(type, `Response ${error.message}`);
        return false;
    }
};
