import React from 'react';
import { mapToRows } from '../../common/Table/addColumnsToRow';
import { TableDefinition } from '../../common/FilteredTable/tableAndFilterHelpers';
import person from '../person';
import ChoiceListFilteredTableNoDashboardData from '../../common/FilteredTable/ChoiceListFilteredTableNoDashboardData';

class PersonTable extends React.Component {
    static defaultProps = {
        breakpoint: 500,
        itemsPerPage: 5,
        noItemsText: 'There are no people',
        columns: [
            person.descriptor,
            person.businessEmailLink,
            person.businessPhone,
            person.ddiPhone,
            person.mobilePhone,
            person.gdprPreferencesInTable
        ]
    };

    render() {
        const {
            loadingStates,
            rows,
            resources,
            columns,
            title,
            breakpoint,
            itemsPerPage,
            noItemsText,
            ...rest
        } = this.props;
        const tableDefinition = new TableDefinition(columns);

        return (
            <ChoiceListFilteredTableNoDashboardData
                id={'PersonTable'}
                tableDefinition={tableDefinition}
                breakpoint={breakpoint}
                noItemsText={noItemsText}
                itemsPerPage={itemsPerPage}
                rows={mapToRows(rows, person.id, resources, tableDefinition.columns, undefined, undefined)}
                isLoading={false}
                tableIsNotInCard={true}
                cardButtons={[]}
                title={title}
                {...rest}
            />
        );
    }
}

export default PersonTable;
