import React from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import CompanyProvider from '../../Company/Provider/CompanyProvider';
import { apiCommunicationPost } from '../../../actions/Api/Communication/apiCommunicationPost';
import { apiCommunicationGet } from '../../../actions/Api/Communication/apiCommunication';

export const CommunicationContext = React.createContext({
    profile: {},
    communication: {}
});

class CommunicationProvider extends React.Component {
    getStateAndHelpers({ communicationId, loadingStates, resources, communication, visitActions, saveCommunication }) {
        return {
            communicationId,
            loadingStates,
            resources,
            communication,
            saveCommunication,
            visitActions
        };
    }

    componentDidMount() {
        this.props.getCommunication(this.props.communicationId);
    }

    render() {
        const { children, communication } = this.props;
        return (
            <CommunicationContext.Provider value={this.getStateAndHelpers(this.props)}>
                {communication ? (
                    <CompanyProvider companyId={communication.company.id}>{children}</CompanyProvider>
                ) : null}
            </CommunicationContext.Provider>
        );
    }
}

const makeMapStateToProps = () => {
    const getState = createSelector(
        [
            state => state.loadingStates,
            state => state.resources,
            (state, { communicationId }) =>
                state.communications.find(communication => communication.communicationId === communicationId)
        ],
        (loadingStates, resources, communication) => ({
            loadingStates,
            resources,
            communication
        })
    );
    return (state, props) => getState(state, props);
};

const mapDispatchToProps = dispatch => ({
    saveCommunication: communication => dispatch(apiCommunicationPost(communication)),
    getCommunication: communicationId => dispatch(apiCommunicationGet(communicationId))
});

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(CommunicationProvider);
