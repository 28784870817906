import React, { useContext } from 'react';
import { connect } from 'react-redux';
// import { communicationSelected } from '../../../actions/Navigation/Communication/communicationSelected';
import meeting from '../meeting';
import { CompanyMeetingsContext } from '../../Company/Provider/CompanyMeetingsProvider';
import MeetingsTableCard from './MeetingsTableCard';
import { meetingSelected } from '../../../actions/Navigation/Meeting/meetingSelected';

const CompanyMeetingsTable = ({ updateMeeting }) => {
    let { companyId, meetings, isLoading } = useContext(CompanyMeetingsContext);
    meetings.map(x => (x.assigneeId = x.assignee.id));
    meetings.map(x => (x.personId = x.person.id));
    return (
        <MeetingsTableCard
            rows={meetings.filter(meeting => meeting.action === 'Meeting')}
            companyId={companyId}
            editRow={updateMeeting}
            buttons={[]}
            isLoading={isLoading}
            columns={[
                meeting.startDate,
                meeting.startTime,
                meeting.activityRegarding,
                meeting.assigneeId,
                meeting.status
            ]}
            title={'Meetings'}
            breakpoint={860}
            initialSortColumn={'start'}
            initialSortOrder={'asc'}
        />
    );
};

const mapDispatchToProps = dispatch => ({
    //updateCommunication: communication => dispatch(communicationSelected(communication))
    updateMeeting: meeting => dispatch(meetingSelected(meeting))
});

export default connect(
    null,
    mapDispatchToProps
)(CompanyMeetingsTable);
