import { ENDPOINTS } from '../../../actions/Api/constants';
import { RESET_REDUCER } from '../../../actions/resetReducers';
import _ from 'lodash';

// Is only ever called with a companyId
export function registrations(state = [], { type, data, companyId }) {
    switch (type) {
        case ENDPOINTS.API.COMPANY_REGISTRATIONS_GET:
            return [...state.filter(x => _.get(x, 'company.id') !== companyId), ...data];
        case RESET_REDUCER:
            return [];
        default:
            return state;
    }
}
