import React, { useContext } from 'react';
import TermsTableCard from './TermsTableCard';
import { CompanyTermsContext } from '../../Company/Provider/CompanyTermsProvider';

const CompanyTermsTable = () => {
    const { terms, isLoading } = useContext(CompanyTermsContext);

    return <TermsTableCard rows={terms} isLoading={isLoading} tableIsNotInCard={true} />;
};

export default CompanyTermsTable;
