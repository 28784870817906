import _ from 'lodash';
import { FORM_PATHS, SET_MODAL_OPEN } from '../actions/modals';

export function modals(
    //TODO: create this state from FormPaths
    state = {
        EditAgreementTermModal: {
            name: FORM_PATHS.EditAgreementTerm.modalName,
            isOpen: false,
            data: { termId: -1 }
        },
        EditCommunicationModal: {
            name: FORM_PATHS.EditCommunicationModal.modalName,
            isOpen: false,
            data: { companyId: -1 }
        },
        AgreementOpportunityModal: {
            name: FORM_PATHS.AgreementOpportunityModal.modalName,
            isOpen: false,
            data: { agreementOpportunityId: -1, companyId: -1 }
        },
        EditMeetingModal: {
            name: FORM_PATHS.EditMeetingModal.modalName,
            isOpen: false,
            data: { companyId: -1 }
        },
        CancelCommunicationModal: {
            name: FORM_PATHS.CancelCommunicationModal.modalName,
            isOpen: false,
            data: { companyId: -1 }
        },
        CompleteMeetingModal: {
            name: FORM_PATHS.CompleteMeetingModal.modalName,
            isOpen: false,
            data: { companyId: -1 }
        },
        EditVisitActionModal: {
            name: FORM_PATHS.EditVisitActionModal.modalName,
            isOpen: false,
            data: { communicationId: -1 }
        },
        EditEventModal: {
            name: FORM_PATHS.EditEventModal.modalName,
            isOpen: false,
            data: { companyId: -1 }
        },
        EditOpportunityModal: {
            name: FORM_PATHS.EditOpportunity.modalName,
            isOpen: false,
            data: { companyId: -1 }
        },
        EditAddressModal: {
            name: FORM_PATHS.EditAddress.modalName,
            isOpen: false,
            data: { addressId: -1, companyId: -1 }
        },
        PersonEditModal: {
            name: FORM_PATHS.EditPerson.modalName,
            isOpen: false,
            data: { person: {} }
        },
        CreateLeadModal: {
            name: FORM_PATHS.CreateLead.modalName,
            isOpen: false,
            data: { person: {} }
        }
    },
    action
) {
    switch (action.type) {
        case SET_MODAL_OPEN:
            const newState = _.clone(state);
            newState[action.name] = { isOpen: action.isOpen, data: action.data };
            return newState;
        default:
            return state;
    }
}
