import { Dispatch } from 'redux';
import { IAction, ENDPOINTS } from '../constants';
import { isError } from '../../Loading/isError';
import { toastr } from 'react-redux-toastr';
import { makePostRequest } from '../apiHelpers';
import { IMeeting } from '../../../reducers/Api/Meeting';
import { apiCompanies } from '../Company/apiCompanies';

export interface IMeetingFilter {
	meetingId?: number;
	companyId?: number;
	assigneeId?: number;
	personId?: number;
	startDate?: Date;
	endDate?: Date;
	status?: string[];
}

const type = ENDPOINTS.API.MEETING_FILTER;

export const apiMeetingFilter = (filter: IMeetingFilter, fetchCompanies: boolean = true) => async (
	dispatch: (action: any) => void,
	getState: () => any
) => {
	try {
		if (!getState().offline.online) {
			return false;
		}

		const response: IMeeting[] = (await makePostRequest(['meeting', 'filter'], {}, filter)).data;

		const companyIds: number[] = response.reduce((acc: number[], next: IMeeting) => {
			if (next.company && next.company.id && !acc.includes(next.company.id)) {
				return [...acc, next.company.id];
			}
			return acc;
		}, []);

		if (fetchCompanies) {
			dispatch(apiCompanies(companyIds));
		}

		dispatch({
			type,
			data: response,
			filter
		});

		return true;
	} catch (err) {
		isError(dispatch, type, true, err.message, {});

		if (getState().user.userId > -1) {
			toastr.error(type, `Response ${err.message}`);
		}

		return false;
	}
};
