import React from 'react';
import { Button } from 'reactstrap';

//export const DeleteTableRowButton = props => TableRowButton(props, 'Delete', 'trash', 'danger');
//export const CloneTableRowButton = props => TableRowButton(props, 'Clone', 'copy', 'primary');
//export const EditTableRowButton = props => TableRowButton(props, 'Edit', 'pencil', 'primary');
//export const ViewAllTableRowButton = props => TableRowButton(props, 'View All', 'th-list', 'primary');
//export const ApproveTableRowButton = props => TableRowButton(props, 'Approve', 'thumbs-o-up', 'primary');
//export const AddendumTableRowButton = props => TableRowButton(props, 'Create Addendum', 'paperclip', 'primary');
//export const TickTableRowButton = props => TableRowButton(props, 'Confirm', 'check', 'primary');
//export const CrossTableRowButton = props => TableRowButton(props, 'Cancel', 'times', 'danger');
//export const OpenAsPdfTableRowButton = props => TableRowButton(props, 'View PDF', 'file', 'primary');
//export const HistoryTableRowButton = props => TableRowButton(props, 'History', 'history', 'secondary');
//export const JsonTableRowButton = props => TableRowButton(props, 'View Data Dev', 'eye', 'secondary');
//export const EditItemRowButton = props => TableRowButton(props, 'Edit', 'pencil', 'primary');
//export const DeclineTableRowButton = props => TableRowButton(props, 'Decline', 'thumbs-o-down', 'danger');
export const AddTableRowButton = props => TableRowButton(props, 'Add', 'plus', 'primary');
export const CollapseTableRowButton = props => (
    <i
        className={`fa d-inline fa-lg ${
            props.collapsed ? 'fa-chevron-down' : 'fa-chevron-up'
        } simple-card-dimmed text-primary hover-pointer`}
        onClick={() => props.onClick()}
    />
);

/*
{
    const { collapsed, ...rest } = props;
    return TableRowButton(
        { ...rest },
        collapsed ? 'Expand' : 'Hide',
        collapsed ? 'chevron-down' : 'chevron-up',
        'primary'
    );
};*/
export const FilterTableRowButton = props => TableRowButton(props, 'Filter', 'filter', 'primary');
//export const MoveUpTableRowButton = props => TableRowButton(props, 'Move Up', 'chevron-up', 'primary');
//export const MoveDownTableRowButton = props => TableRowButton(props, 'Move Down', 'chevron-down', 'primary');
//export const TryAgainButton = props => TableRowButton(props, 'Retry', 'refresh', 'primary');
const TableRowButton = (props, title, icon, outline) => (
    <Button
        title={title}
        className={`simple-card-dimmed  btn-sm bg-white-not-hover btn-outline-${outline} ${props.className} mx-1`}
        {...props}
    >
        <i
            className={`fa d-inline fa-lg fa-${icon}${props.disabled ? ' text-muted' : ''}${
                props.children ? ' mr-2' : ''
            }`}
        />
        {props.children}
    </Button>
);
