import React from 'react';
import {
    AdminDayIcon,
    EventIcon,
    FaceToFaceMeetingIcon,
    HolidayIcon,
    ScheduledContactIcon,
    TelephoneMeetingIcon,
    VisitReportActionIcon
} from './Icons/EventIcons';
import { meetingSelected } from '../../actions/Navigation/Meeting/meetingSelected';
import { communicationSelected } from '../../actions/Navigation/Communication/communicationSelected';
import { editVisitAction } from '../../actions/Navigation/VisitAction/editVisitAction';

const eventTypes = {
    FaceToFaceMeeting: {
        name: 'Face to Face meeting',
        className: 'background-color-3',
        icon: className => <FaceToFaceMeetingIcon className={className} />,
        edit: meetingSelected
    },
    TelephoneMeeting: {
        name: 'Telephone meeting',
        className: 'background-color-7',
        icon: className => <TelephoneMeetingIcon className={className} />,
        edit: meetingSelected
    },
    ScheduledContact: {
        name: 'Scheduled Contact',
        className: 'background-color-4',
        icon: className => <ScheduledContactIcon className={className} />,
        edit: communicationSelected
    },
    VisitReportAction: {
        name: 'Visit Report Action',
        className: 'background-color-5',
        icon: className => <VisitReportActionIcon className={className} />,
        edit: editVisitAction
    },
    AdminDay: {
        name: 'Admin Day',
        className: 'background-color-2',
        icon: className => <AdminDayIcon className={className} />
    },
    Event: {
        name: 'Event',
        className: 'background-color-8',
        icon: className => <EventIcon className={className} />
    },
    Holiday: {
        name: 'Holiday',
        className: 'background-color-1',
        icon: className => <HolidayIcon className={className} />
    }
};

export default eventTypes;
