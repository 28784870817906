import { makePostRequest } from '../apiHelpers';
import { ENDPOINTS } from '../constants';
import { toastr } from 'react-redux-toastr';

import { isError } from '../../Loading/isError';

export const apiUserLogPost = (descriptor, entity, url) => async (dispatch, getState) => {
    const type = ENDPOINTS.API.USERLOG_POST;
    try {
        if (!getState().offline.online) {
            return false;
        }

        const result = await makePostRequest(
            ['user', 'log', 'history'],
            {},
            { descriptor, entity, url, dealerId: getState().user.dealershipId }
        );

        dispatch({
            type,
            data: result.data
        });

        return true;
    } catch (error) {
        isError(dispatch, type, true, error.message, {});
        toastr.error(type, `Response ${error.message}`);
        return false;
    }
};
