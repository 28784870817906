import moment from 'moment';

//export const zeroIfUndefined = f => (f === undefined ? 0 : f);

//export const displayDate = date => moment(Date.parse(date)).format('YYYY-MM-DD hh:mm:ss');

//export const daysSince = date => moment(Date.now()).diff(moment(Date.parse(date)), 'days');

export const makeSafeUrl = urlParts => `/${urlParts.map(x => encodeURIComponent(x)).join('/')}`;

export const safeDispatchPush = (dispatch, push, urlParts) => dispatch(push(makeSafeUrl(urlParts)));

export const createFakeId = () => 0 - moment().valueOf();

export const webSiteHttp = value => {
    let href = value;
    if (href && !href.startsWith('http') && !href.startsWith('https')) {
        href = `http://${href}`;
    }
    return href;
};
export const hexToRgb = hex =>
    hex
        .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
        .substring(1)
        .match(/.{2}/g)
        .map(x => parseInt(x, 16));

export const rgbaOpacity = (colorArray, opacity, isDisabled) =>
    isDisabled ? 'darkgrey' : `rgba(${colorArray[0]},${colorArray[1]},${colorArray[2]},${opacity})`;

export function niceColor(hex) {
    let { h, s, l } = hexToHsl(hex);
    // Same hue but invert the saturation and lightness
    s = (s + 0.5) % 1;
    l = (l + 0.5) % 1;
    return `hsl(${h * 360}, ${s * 100}%, ${l * 100}%)`;
}

function hexToHsl(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    const r = parseInt(result[1], 16) / 255;
    const g = parseInt(result[2], 16) / 255;
    const b = parseInt(result[3], 16) / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);

    let h = (max + min) / 2;
    let s = (max + min) / 2;
    let l = (max + min) / 2;

    if (max === min) {
        h = 0;
        s = 0;
    } else {
        const diff = max - min;
        s = l > 0.5 ? diff / (2 - max - min) : diff / (max + min);
        switch (max) {
            case r:
                h = (g - b) / diff + (g < b ? 6 : 0);
                break;

            case g:
                h = (b, r) / diff + 2;
                break;

            case b:
                h = (r - g) / diff + 4;
                break;
            default:
                break;
        }
        h /= 6;
    }

    return { h, s, l };
}

export const prettyPrintNumber = x => {
    let parts = x ? x.toString().split('.') : [''];
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
};
