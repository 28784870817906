import { apiResource } from '../Api/Resource/apiResource';
import { push } from 'connected-react-router';
import { retryErrors } from '../Api/RetryErrors/retryErrors';
import { dashboardGet } from '../dashboard/dashboard';

//import { apiUser } from '../User/apiUser';

export const apiUserLoggedIn = () => async dispatch => {
    dispatch(dashboardGet());
    dispatch(retryErrors());
    dispatch(apiResource());

    dispatch(push('/dashboard/home'));
};
