import { useMemo } from 'react';
import _ from 'lodash';

function setDefaultFieldValues(initialValues = {}, columnDefAndDefaultValues, defaultValues) {
    return columnDefAndDefaultValues.reduce(
        (acc, { field, convert }) => {
            const existingValue = initialValues[field];

            acc[field] = existingValue || _.get(defaultValues, field, '');

            if (convert === 'toString') {
                acc[field] = acc[field].toString();
            } else if (typeof convert === 'function') {
                acc[field] = convert(acc[field]);
            }

            return acc;
        },
        { ...initialValues }
    );
}

const useInitialValues = (formValue, columnDefAndDefaultValues, defaultValues) => {
    return useMemo(() => setDefaultFieldValues(formValue, columnDefAndDefaultValues, defaultValues), [
        formValue,
        columnDefAndDefaultValues,
        defaultValues
    ]);
};

export default useInitialValues;
