import { ENDPOINTS } from '../../../actions/Api/constants';
import { getFleetProfileUpdateErrors } from '../FleetProfiles/index';
import { toastr } from 'react-redux-toastr';
import { getLeadUpdateErrors } from '../Leads/index';
import _ from 'lodash';
import { createFakeId } from '../../util';
import { getPersonUpdateErrors } from '../People/person';
import { getCommunicationUpdateErrors } from '../Communications';

const defaultState = [];

const communication_post_commit_error = (state, action) => {
    const {
        meta: { modified },
        payload: { data: remote }
    } = action;

    const errors = getCommunicationUpdateErrors(modified, remote);

    return errors.length === 0 ? state : [{ table: 'Communication', type: 'Update', errors }, ...state];
};

const lead_post_commit_error = (state, action) => {
    const {
        meta: { unmodifiedLead, lead }
    } = action;

    const errors = getLeadUpdateErrors(lead, unmodifiedLead);
    return errors.length === 0 ? state : [{ table: 'Lead', type: 'Update', errors }, ...state];
};

const fleet_profile_post_commit_error = (state, action) => {
    const {
        meta: { unmodifiedFleetProfile, fleetProfile },
        payload
    } = action;
    const errors = getFleetProfileUpdateErrors(fleetProfile, unmodifiedFleetProfile);
    const responseCode = _.has(payload, 'response.status') ? _.get(payload, 'response.status') : '';
    return errors.length === 0
        ? state
        : [
              {
                  table: 'FleetProfile',
                  type: 'Update',
                  errors,
                  action,
                  responseCode,
                  id: createFakeId()
              },
              ...state
          ];
};

const person_post_commit_error = (state, action) => {
    const {
        meta: { unmodifiedPerson, person },
        payload
    } = action;
    const errors = getPersonUpdateErrors(person, unmodifiedPerson);
    const responseCode = _.has(payload, 'response.status') ? _.get(payload, 'response.status') : '';
    return errors.length === 0
        ? state
        : [
              {
                  table: 'People',
                  type: 'Update',
                  errors,
                  action,
                  responseCode,
                  id: person.personId
              },
              ...state
          ];
};

export const CLEAR_ERRORS = 'CLEAR ERRORS';
export const CLEAR_ERROR = 'CLEAR ERROR';

export const errors = (state = defaultState, action) => {
    switch (action.type) {
        //case RESET_REDUCER:
        //    return defaultState;
        case 'Offline/JS_ERROR':
            console.error('Offline/JS_ERROR', action);
            break;
        case ENDPOINTS.API.COMMUNICATION_POST_ROLLBACK:
            const rollbackTo = {
                ...action.meta.unmodified,
                syncing: false,
                error: action.payload.message
            };
            return [rollbackTo, ...state.filter(oldComm => rollbackTo.communicationId !== oldComm.communicationId)];
        case ENDPOINTS.API.FLEET_PROFILE_POST_ROLLBACK:
            return fleet_profile_post_commit_error(state, action);
        case ENDPOINTS.API.COMMUNICATION_POST_COMMIT:
            return communication_post_commit_error(state, action);
        case ENDPOINTS.API.PERSON_POST_ROLLBACK:
            return person_post_commit_error(state, action);
        case CLEAR_ERROR:
            return [...state.filter(x => action.id !== x.id)];
        case CLEAR_ERRORS:
            return [];
        case ENDPOINTS.API.LEAD_POST_ROLLBACK:
            return lead_post_commit_error(state, action);
        default:
            return state;
    }
};

export const toastrError = (error, _action) => {
    switch (_action.type) {
        case ENDPOINTS.API.USER_UPDATE:
            toastr.error('ERROR UPDATING USER', `Could not update ${_action.data.email} in dealership`);
            break;
        case ENDPOINTS.API.USER_REMOVE_FROM_DEALERSHIP:
            toastr.error(
                'ERROR REMOVING USER',
                `Could not remove ${_action.meta.offline.rollback.meta.unmodified.email} in dealership`
            );
            break;
        default:
            console.warn(`No message defined for user on error`, _action, error);
            break;
    }
};
