import { ENDPOINTS } from '../../../actions/Api/constants';
import { RESET_REDUCER } from '../../../actions/resetReducers';

export function address(state = {}, action) {
    switch (action.type) {
        case ENDPOINTS.API.ADDRESS:
            const { data } = action;
            return {
                ...state,
                data
            };
        case ENDPOINTS.API.ADDRESS_BY_ID:
            const { data: address } = action;
            return {
                address
            };
        case RESET_REDUCER:
            return {};

        default:
            return state;
    }
}
