import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AddTableRowButton } from '../../common/Buttons/TableButtons';
import { createOpportunity } from '../../../actions/Navigation/Opportunity/createOpportunity';

class AddOpportunityToCompanyButton extends React.Component {
    static defaultProps = {};
    static propTypes = {
        companyId: PropTypes.number.isRequired
    };

    render = () => {
        const { companyId, addOpportunity } = this.props;

        return <AddTableRowButton onClick={() => addOpportunity(companyId)} />;
    };
}

const mapDispatchToProps = dispatch => ({
    addOpportunity: companyId => dispatch(createOpportunity(companyId))
});

export default connect(
    undefined,
    mapDispatchToProps
)(AddOpportunityToCompanyButton);
