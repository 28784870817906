import * as yup from 'yup';

const validation = yup.object().shape({
    companyName: yup.string().required('Company Name is required'),
    brand: yup.string(),
    website: yup.string().required('Website is required'),
    phoneNumber: yup.string().required('Phone number is required'),
    salutation: yup.string(),
    jobTitle: yup.string(),
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    mobile: yup.string(),
    email: yup
        .string()
        .email('Invalid email address')
        .required('Email is required'),
    address1: yup.string().required('Address line 1 is required'),
    address2: yup.string(),
    address3: yup.string(),
    address4: yup.string(),
    city: yup.string(),
    county: yup.string(),
    postcode: yup.string().required('Postcode is required'),
    fleetSize: yup
        .number()
        .integer()
        .min(0)
        .max(1000000),
    numberOfCars: yup
        .number()
        .integer()
        .min(0)
        .max(1000000),
    numberOfVans: yup
        .number()
        .integer()
        .min(0)
        .max(1000000)
        .required('Number of LCVs is required')
});

export default validation;
