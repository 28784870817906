import { isDealerOrAlm } from '../../../reducers/Api/User';
import _ from 'lodash';

export const getUserIdForFilter = state => {
    const {
        user,
        user: { userId, dealershipUserId }
    } = state;
    return isDealerOrAlm(user) ? dealershipUserId : userId;
};
export const getUserFromState = state => state.user;
export const getUserIdFromState = state => state.user.userId;

export const getBrowsingHistoryFromState = state => _.get(state, 'user.browsingHistory', []);
export const getUserDealershipIdFromState = state => _.get(state, 'user.dealershipId', undefined);
