import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { apiCommunicationPost } from '../../../actions/Api/Communication/apiCommunicationPost';
import { REST_TIME_FORMAT } from '../../../actions/Api/apiHelpers';
import { FORM_NAMES } from '../../../actions/formNames';
import ReactiveForm from '../../common/ReactiveForm';
import communication from '../communication';
import validation from '../communication.validation';
import _ from 'lodash';

const FORM_NAME = FORM_NAMES.EDIT_COMMUNICATION;

class EditCommunicationForm extends React.Component {
    handleSubmit = formValues => {
        const communication = {
            ...formValues,
            assigneeId: parseInt(formValues.assigneeId, 10)
        };
        this.props.save(communication, this.props.onCancel);
    };

    emptyForm = () => {
        const {
            user: { userId, descriptor },
            companyId,
            communicationId
        } = this.props;

        const startDate = moment()
            .hour(9)
            .minute(0)
            .seconds(0)
            .milliseconds(0)
            .format(REST_TIME_FORMAT);

        const endDate = startDate;

        return {
            communicationId,
            activityRegarding: '',
            action: '',
            subject: `${this.props.companies.find(x => x.companyId === companyId).descriptor}`,
            assigneeId: userId.toString(),
            assigneeName: descriptor,
            assignee: {
                id: userId,
                descriptor
            },
            status: '',
            body: '',
            companyId,
            company: {
                id: companyId,
                descriptor: `${this.props.companies.find(x => x.companyId === companyId).descriptor}`
            },
            startDate,
            endDate
        };
    };

    render() {
        const { onCancel, resources, communications, communicationId } = this.props;

        let initialValues = _.cloneDeep(communications.find(x => x.communicationId === communicationId));

        if (!initialValues && communicationId < 0) {
            initialValues = this.emptyForm();
            initialValues.communicationId = communicationId;
        }

        if (initialValues) {
            initialValues.assigneeId =
                initialValues.assignee && initialValues.assignee.id ? initialValues.assignee.id.toString() : null;
            initialValues.companyId =
                initialValues.company && initialValues.company.id ? initialValues.company.id.toString() : null;
            initialValues.status = initialValues.status || 'Pending';
            initialValues.activityRegarding = initialValues.activityRegarding || 'BSDRBSMCompanyFollowUpCall';
            initialValues.action = initialValues.action || 'PhoneOut';
        }

        return (
            <ReactiveForm
                initialValues={initialValues}
                cancel={onCancel}
                resources={resources}
                formName={FORM_NAME}
                validationSchema={validation}
                handleSubmit={this.handleSubmit}
            >
                <ReactiveForm.Section>
                    <ReactiveForm.Date columnDef={communication.startDate} />
                    <ReactiveForm.Choicelist columnDef={communication.activityRegarding} disabled={true} />
                    <ReactiveForm.Choicelist columnDef={communication.action} />
                    <ReactiveForm.Text columnDef={communication.subject} />
                    <ReactiveForm.Choicelist columnDef={communication.assigneeId} />
                    <ReactiveForm.Choicelist columnDef={communication.status} />
                    <ReactiveForm.Text columnDef={communication.body} />
                </ReactiveForm.Section>
            </ReactiveForm>
        );
    }
}

const mapStateToProps = state => ({
    communications: state.communications,
    companies: state.companies,
    user: state.user,
    opportunities: state.opportunities,
    people: state.people,
    resources: state.resources
});

const mapDispatchToProps = dispatch => ({
    save: (communication, cancel) => {
        dispatch(apiCommunicationPost(communication));
        if (cancel) {
            cancel();
        }
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditCommunicationForm);
