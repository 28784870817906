import axios from 'axios';
import { isLoading } from '../../Loading/isLoading';
import { isError } from '../../Loading/isError';
import { toastr } from 'react-redux-toastr';
import { makePath } from '../../Api/apiHelpers';
import { API_LOGIN_ENDPOINTS } from '../ApiAuthEndpoints';
import { makeAuthUrl } from '../apiAuthHelpers';

export const apiAuthPasswordForgot = emailAddress => async (dispatch, getState) => {
    const type = API_LOGIN_ENDPOINTS.API.API_AUTH_PASSWORD_FORGOT;

    isLoading(dispatch, type, true, {});
    try {
        const result = await axios({
            method: 'POST',
            url: makeAuthUrl(getState(), makePath(['auth', 'password', 'forgot']), {
                EmailAddress: emailAddress,
                ReturnUrl: `${window.location.origin}/resetpassword/`
            }), //TODO: Should not go here
            headers: { 'Content-Type': 'application/json' },
            data: {}
        });

        //const { data: user } = result;

        isLoading(dispatch, type, false, {});
        dispatch({ type, data: { sentTo: emailAddress } });
        return result || true;
    } catch (error) {
        isError(dispatch, type, true, error.message, {});
        toastr.error(type, `Response ${error.message}`);
        return false;
    }
};
