import React from 'react';
import connect from 'react-redux/es/connect/connect';
import FilteredRows from './FilteredRows';
import TextFilter from './TextFilter';

class ChoiceListAndTextFilteredRows extends React.Component {
    render() {
        const { rows, searchFields, showFilter, filters } = this.props;

        return (
            <TextFilter rows={rows} searchFields={searchFields}>
                {({ searchBox, textFilteredRows }) => (
                    <FilteredRows
                        showFilter={showFilter}
                        filterOptions={filters}
                        resources={this.props.resources}
                        rows={textFilteredRows}
                        searchBox={searchBox}
                    >
                        {({ filter, filteredRows }) => this.props.children({ filter, filteredRows })}
                    </FilteredRows>
                )}
            </TextFilter>
        );
    }
}

const mapStateToProps = state => ({
    resources: state.resources
});

export default connect(mapStateToProps)(ChoiceListAndTextFilteredRows);
