import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import moment from 'moment';
import SimpleCard from '../../common/Card/SimpleCard';
import { generateFourWeeksCounts, MiniCalendar } from '../../common/MiniCalendar/index';
import { safeDispatchPush } from '../../../reducers/util';

const UpcomingCommunications = ({ meetings, user: { userId }, viewDay, className }) => {
    const userPendingMeetings = meetings.filter(x => x.assignee.id === userId && x.status === 'Pending');

    const weeks = generateFourWeeksCounts();
    weeks.forEach(week =>
        week.forEach(day => {
            day.count = userPendingMeetings.filter(x => moment(x.startDate).isSame(day.day, 'day')).length;
        })
    );
    const total = weeks.reduce(
        (fourWeekTotal, week) => fourWeekTotal + week.reduce((weekTotal, day) => weekTotal + day.count, 0),
        0
    );

    return (
        <div className={className}>
            <SimpleCard title={'Upcoming Meetings'}>
                <div className=" pb-3 d-grid grid-cols-2">
                    <div className="text-center grid-js-vcenter">
                        <div className="h5 small">Meetings Pending</div>
                        <div className="h1 logo-font">{total}</div>
                        <div className="h6 small">Next 4 weeks</div>
                    </div>
                    <div className="grid-js-center grid-js-vcenter">
                        <MiniCalendar month={weeks} clickDay={viewDay} highlightedDay={moment()} />
                    </div>
                </div>
            </SimpleCard>
        </div>
    );
};

const mapStateToProps = state => ({
    user: state.user,
    meetings: state.meetings
});

const mapDispatchToProps = dispatch => ({
    viewDay: momentDay =>
        safeDispatchPush(dispatch, push, ['calendar', 'day', momentDay.year(), momentDay.month() + 1, momentDay.date()])
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpcomingCommunications);
