import { ENDPOINTS } from '../constants';
import { apiGet } from '../apiHelpers';

export const apiCompany = companyId => async (dispatch, getState) => {
    if (!companyId) {
        return false;
    }

    let path;

    const dealershipId = getState().user.dealershipId;
    if (dealershipId) {
        path = ['company', dealershipId, companyId];
    } else {
        path = ['company', companyId];
    }

    dispatch(apiGet(ENDPOINTS.API.COMPANY, path));
};
