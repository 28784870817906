import React from 'react';
import moment from 'moment';
import SimpleCard from '../../common/Card/SimpleCard';
import { getDaysInFuture } from '../../../reducers/Api/helper';
import _ from 'lodash';
import UnfilteredAgendaCard from '../Cards/UnfilteredAgendaCard';
import CalendarEventsProvider from '../Components/CalendarEventsProvider';
import EditEventModal from '../../Events/Modals/EditEventModal';

const Next7DaysAgendaCard = ({ userId, companyId, title }) => (
    <EditEventModal>
        {({ editEvent }) => (
            <CalendarEventsProvider
                start={moment().startOf('day')}
                end={moment()
                    .startOf('day')
                    .add(7, 'days')}
                userId={userId}
                companyId={companyId}
                editEvent={editEvent}
            >
                {({ events, filter, isLoading }) => (
                    <SimpleCard title={`${title} (${events.length})`} isLoading={isLoading}>
                        <UnfilteredAgendaCard
                            agenda={_.orderBy(events, 'start')}
                            showDayName
                            daysArray={getDaysInFuture(7)}
                        />
                        <hr />
                        <span className="font-weight-bold mr-3">Filter:</span>
                        {filter}
                    </SimpleCard>
                )}
            </CalendarEventsProvider>
        )}
    </EditEventModal>
);

export default Next7DaysAgendaCard;
