import React from 'react';
import { Row } from 'reactstrap';
import { SubSectionTitle } from './SubSectionTitle';
import PropTypes from 'prop-types';

const FormSubSection = props => {
    const { title, className, children, sectionNumber } = props;
    return (
        <div className={`pt-3 ${className} form-subsection`}>
            {title ? (
                <React.Fragment>
                    <SubSectionTitle number={sectionNumber} title={title} />
                </React.Fragment>
            ) : null}
            <Row>{children}</Row>
        </div>
    );
};

FormSubSection.propTypes = {
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
    children: PropTypes.node
};

export default FormSubSection;
