import React from 'react';
import Table from '../../common/Table/index';
import connect from 'react-redux/es/connect/connect';
import { apiAddressById } from '../../../actions/Api/Address/apiAddressById';
import { AlertIfNoRows } from '../../common/Table/AlertIfNoRows';

class AddressSearchResultTable extends React.Component {
    mapToRow = address => ({
        id: address.id,
        streetAddress: address.streetAddress + ', ' + address.place,
        onClick: () => {
            this.props.addressSearch(address.id);
        }
    });

    render() {
        const { addresses, breakpoint, isLoading, noResults } = this.props;
        return (
            <div style={{ overflowY: 'auto', height: 265 }}>
                {(() => {
                    if (noResults) {
                        return <AlertIfNoRows message={'No results found'} />;
                    }
                })()}

                <Table
                    id={'AddressSearchTable'}
                    breakpoint={breakpoint}
                    itemsPerPage={100}
                    headings={[
                        {
                            sort: false,
                            value: '',
                            field: 'streetAddress',
                            primary: true
                        }
                    ]}
                    rows={addresses ? addresses.map(this.mapToRow) : []}
                    isLoading={isLoading}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    addressSearch: id => dispatch(apiAddressById(id))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddressSearchResultTable);
