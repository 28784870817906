import React from 'react';
import { connect } from 'react-redux';
import CalendarEventContent from './CalendarEventContent';

const EventWrapper = ({ id, start, end, type, customEdit, data, editItem, showTimes = true }) => {
    const { className, icon, edit } = type;

    return (
        <div
            key={id}
            className={`w-100 p-2 hover-pointer background-hover d-grid ${className}`}
            style={{
                gridTemplateColumns: showTimes ? 'auto auto minmax(0, 1fr)' : 'auto 1fr',
                gridColumnGap: '8px'
            }}
            onClick={() => (customEdit && customEdit()) || (edit && editItem(data, edit))}
        >
            {icon('')}
            {showTimes && (
                <span className="text-nowrap" style={{ fontSize: '0.8em' }}>
                    {start.format('HH:mm')} - {end.format('hh:mm')}
                </span>
            )}
            <CalendarEventContent key={id} type={type} data={data} id={id} />
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    editItem: (item, edit) => dispatch(edit(item))
});

export default connect(
    undefined,
    mapDispatchToProps
)(EventWrapper);
