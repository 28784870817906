import 'core-js/fn/array/from';
import 'core-js/fn/array/fill';
import 'core-js/fn/object/entries';

import React from 'react';
import ReactDOM /*, { render }*/ from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from './store';
import App from './containers/app';
import { withRouter } from 'react-router';
import registerServiceWorker from './registerServiceWorker';

import './css/app.css';

const BlockAvoider = withRouter(App);

const target = document.querySelector('#root');

export const persistCallback = () => {
    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <div>
                    <BlockAvoider />
                </div>
            </ConnectedRouter>
        </Provider>,
        target
    );
};

registerServiceWorker();
