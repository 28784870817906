import { toastr } from 'react-redux-toastr';
import { isLoading } from '../Loading/isLoading';
import { isError } from '../Loading/isError';

export const DASHBOARD_GET = 'DASHBOARD_GET';

export const dashboardGet = () => async (dispatch, getState) => {
    const type = DASHBOARD_GET;

    isLoading(dispatch, type, true, {});
    try {
        const data = require(`./${process.env.REACT_APP_DASHBOARD}`);
        dispatch({ type, data });
        isLoading(dispatch, type, false, {});
        return true;
    } catch (error) {
        isError(dispatch, type, true, error.message, {});
        toastr.error(type, `Response ${error.message}`);
        return false;
    }
};
