export const FORM_NAMES = {
    ADDRESS: 'addressForm',
    PERSON: 'personForm',
    OPPORTUNITY: 'opportunityForm',
    EDIT_DAY: 'dayForm',
    EDIT_VISIT_ACTION: 'editVisitActionForm',
    EDIT_MEETING: 'editMeetingForm',
    EDIT_COMMUNICATION: 'editCommunicationForm',
    LEAD: 'leadForm',
    SUPPORT: 'supportForm',
    EDIT_USER: 'EDIT_USER',
    AGREEMENT_OPPORTUNITY: 'agreementOpportunityForm'
};
