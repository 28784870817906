import React from 'react';
import { connect } from 'react-redux';
import SimpleCardCollapsable from '../../common/Card/SimpleCardCollapsable';
import { CompanyConsumer } from '../../Company/Provider/CompanyProvider';
import UpdateFleetProfileEditForm from '../Forms/UpdateFleetProfileEditForm';

const CompanyFleetProfileEditCard = () => (
    <CompanyConsumer>
        {({ isLoading }) => (
            <SimpleCardCollapsable title={'Fleet Profile'} collapseName="Company Fleet Profile" isLoading={isLoading}>
                <UpdateFleetProfileEditForm />
            </SimpleCardCollapsable>
        )}
    </CompanyConsumer>
);

const mapStateToProps = state => ({
    user: state.user
});

export default connect(
    mapStateToProps,
    undefined
)(CompanyFleetProfileEditCard);
