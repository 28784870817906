import React, { useContext } from 'react';
import { CompanyContext } from '../Provider/CompanyProvider';
import SyncingOrErrorIndicator from '../../common/SyncingOrErrorIndicator';

const CompanyIsLoadingSpinner = () => {
    const {
        company: { loading }
    } = useContext(CompanyContext);

    return <SyncingOrErrorIndicator isLoading={loading} />;
};

export default CompanyIsLoadingSpinner;
