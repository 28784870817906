import { ENDPOINTS, IAction } from '../../../actions/Api/constants';
import { RESET_REDUCER } from '../../../actions/resetReducers';
import _ from 'lodash';
import { setPropertiesSyncing } from "../helper";
import meeting from "../../../containers/Meeting/meeting";
import { IMeetingFilter } from '../../../actions/Api/Meeting/apiMeetingFilter';
import moment from "moment";

export interface IMetaData {
	id?: number;
	descriptor?: string;
}

export interface IMeeting {
	meetingId: number;
	createdBy: number;
	createdDate: Date;
	updatedBy: number;
	updatedDate: number;
	descriptor: string;
	activityRegarding: string;
	startDate: Date;
	endDate: string;
	status: string;
	location: string;
	meetingMode: string;
	details: string;
	feedback: string;
	rating: string; // TODO make enum
	roiBooked: string; // TODO make enum or bool
	notes: string;
	subject: string;
	company?: IMetaData;
	person?: IMetaData;
	assignee?: IMetaData;
}

const update = (state: IMeeting[], update: IMeeting[], filter: IMeetingFilter): IMeeting[] => [
	...update.map(meeting => ({...meeting, personId: _.get(meeting, 'person.id'), assigneeId: _.get(meeting, 'assignee.id')})),
	...filterState(state, filter, update)
];

export function filterState(state: IMeeting[], filter:IMeetingFilter, newData:IMeeting[]): IMeeting[] {
	const matchesState = filterToMatchFunction(filter);
	const filteredState = state.filter(
		x => !newData.some(newComm => x.meetingId === newComm.meetingId) && !matchesState(x)
	);
	return filteredState;
};

export function filterToMatchFunction({ meetingId, assigneeId, status, companyId, startDate, endDate }: IMeetingFilter):Function {
	const start = startDate === undefined ? undefined : moment(startDate);
	const end = endDate === undefined ? undefined : moment(endDate);

	return (meeting:IMeeting)=> {
		return (!companyId || _.get(meeting, 'company.id','') === companyId) &&
			(!assigneeId || _.get(meeting, 'assignee.id','') === assigneeId) &&
			((!start && !end ) || ((!start || start.isBefore(moment(meeting.startDate))) && (!end || end.isAfter(moment(meeting.startDate))))) &&
			(!status || status.indexOf(meeting.status) > -1) &&
			(!meetingId || meeting.meetingId === meetingId);
	};
}

const idField = meeting.id.field;

function updateStateBeforeSendingToServer(state: IMeeting[], {data}: IAction){
	const original = state.find((x:any) => data[idField] === x[idField]);
	if(original)
	{
		setPropertiesSyncing(original, data);
	}
	data.syncing = true;
	return [
		data,
		...state.filter((x:any) => x[idField] !== data[idField])
	];
}

function postCommit(state:IMeeting[], action:IAction){
	const {
		meta: { modified },
		payload: { data: remote }
	} = action;
	remote.assigneeId = _.get(remote, 'assignee.id');
	return [
		remote,
		...state.filter((x:any) => modified[idField] !== x[idField] && x[idField] !== remote[idField])
	];
}

export default (state: IMeeting[] = [], action: IAction) => {
	switch (action.type) {
		case RESET_REDUCER:
			return [];
		case ENDPOINTS.API.MEETING_POST:
			return  updateStateBeforeSendingToServer(state, action);
		case ENDPOINTS.API.MEETING_POST_COMMIT:
			return postCommit(state, action);
		case ENDPOINTS.API.MEETING_FILTER:
			return update(state, action.data, action.filter);
		default:
			return state;
	}
};

