import React from 'react';
import PropTypes from 'prop-types';
import { FieldLabel } from '../DisplayField/FieldLabel';

const FormikLabel = props => {
    const { fieldName, title } = props;
    return (
        <label htmlFor={fieldName}>
            <FieldLabel>{title || fieldName}</FieldLabel>
        </label>
    );
};

FormikLabel.propTypes = {
    formName: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired
};

export default FormikLabel;
