import React, { useContext } from "react";
import { push } from "connected-react-router";
import {
  cancelCommunication,
  CREATE_COMMUNICATION_TYPE_CANCEL
} from "../../../actions/Navigation/Communication/cancelCommunication";

import { connect } from "react-redux";
import { createVisitAction } from "../../../actions/Navigation/VisitAction/createVisitAction";
import { createOpportunity } from "../../../actions/Navigation/Opportunity/createOpportunity";
import ActionButtons from "../../common/ActionButtons/ActionButtons";
import { safeDispatchPush } from "../../../reducers/util";
import { apiCalendarInvite } from "../../../actions/Api/Communication/apiCalendarInvite";
import { CompanyContext } from "../../Company/Provider/CompanyProvider";
import { MeetingContext } from "../../Meeting/Provider/MeetingProvider";
import {
  completeMeeting,
  CREATE_COMMUNICATION_TYPE_COMPLETE
} from "../../../actions/Navigation/Meeting/completeMeeting";

const addActionButton = (
  actions,
  addButton,
  buttonText,
  fontawesomeIcon,
  bootstrapColor,
  onClickAction
) => {
  if (addButton) {
    actions.push({
      buttonText,
      fontawesomeIcon,
      bootstrapColor,
      onClickAction
    });
  }
};

function ViewMeetingPageButtons({
  formikProps,
  user,
  url,
  addOpportunity,
  createVisitAction,
  sendEmail,
  sendCalendarInvite,
  cancelCommunication,
  completeCommunication
}) {
  const { meeting } = useContext(MeetingContext);
  const { company } = useContext(CompanyContext);
  const actions = [];
  addActionButton(
    actions,
    true,
    "Add Opportunity",
    "fa-plus",
    "secondary",
    () => addOpportunity(company.companyId)
  );
  addActionButton(
    actions,
    true,
    "Add Visit Action",
    "fa-plus",
    "secondary",
    () => {
      createVisitAction(company.companyId, meeting.meetingId);
    }
  );
  addActionButton(
    actions,
    meeting.status === "Overdue" || meeting.status === "Pending",
    "Complete meeting",
    "fa-check",
    "success",
    () => completeCommunication(meeting, CREATE_COMMUNICATION_TYPE_COMPLETE)
    //formikProps.handleSubmit()
  );

  addActionButton(
    actions,
    true,
    "Create visit report email",
    "fa-envelope",
    "success",
    () => sendEmail(meeting.meetingId)
  );

  addActionButton(
    actions,
    meeting.status === "Pending" && user.userId === meeting.assignee.id,
    "Send invite to my calendar",
    "fa-calendar",
    "success",
    () => sendCalendarInvite(meeting.meetingId, url)
  );
  addActionButton(
    actions,
    meeting.status === "Pending",
    "Cancel Appointment",
    "fa-ban",
    "danger",
    () => cancelCommunication(meeting, CREATE_COMMUNICATION_TYPE_CANCEL)
  );

  return <ActionButtons actions={actions} />;
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  cancelCommunication: (communication, type) =>
    dispatch(cancelCommunication(communication, type)),
  completeCommunication: (meeting, type) =>
    dispatch(completeMeeting(meeting, type)),
  sendEmail: communicationId =>
    safeDispatchPush(dispatch, push, [
      "communication",
      "email",
      communicationId
    ]),
  createVisitAction: (companyId, parentCommunicationId) => {
    dispatch(createVisitAction(companyId, parentCommunicationId));
  },
  addOpportunity: companyId => dispatch(createOpportunity(companyId)),
  sendCalendarInvite: (communicationId, url) =>
    dispatch(apiCalendarInvite(communicationId, url))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewMeetingPageButtons);
