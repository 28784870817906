import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { apiMeetingPost } from '../../../actions/Api/Meeting/apiMeetingPost';
import { REST_TIME_FORMAT } from '../../../actions/Api/apiHelpers';
import { FORM_NAMES } from '../../../actions/formNames';
import { getPrimaryAddress } from '../../../reducers/Api/Company/company';
import ReactiveForm from '../../common/ReactiveForm';
import meeting from '../meeting';
import validation from './validation';

const FORM_NAME = FORM_NAMES.EDIT_MEETING;

class EditMeetingForm extends React.Component {
    handleSubmit = formValues => {
        formValues.descriptor = formValues.subject;
        const meeting = {
            ...formValues,
            assignee: {
                id: parseInt(formValues.assigneeId, 10)
            },
            roiBooked: formValues.roiBooked ? 'Y' : 'N'
        };
        this.props.save(meeting, this.props.onCancel);
    };

    emptyForm = () => {
        const {
            user: { userId, descriptor },
            meetingId,
            companyId,
            person
        } = this.props;

        const startDate = moment()
            .hour(9)
            .minute(0)
            .seconds(0)
            .format(REST_TIME_FORMAT);

        const endDate = moment()
            .hour(17)
            .minute(0)
            .seconds(0)
            .format(REST_TIME_FORMAT);

        return {
            activityRegarding: '',
            action: 'Meeting',
            companyId,
            meetingId,
            opportunityId: '',
            subject: '',
            details: '',
            status: 'Pending',
            location: '',
            assigneeId: userId.toString(),
            assigneeName: descriptor,
            assignee: {
                id: userId,
                descriptor
            },
            personId: (person && person.id) || '',
            person: {
                id: (person && person.id) || '',
                descriptor: (person && person.descriptor) || ''
            },
            company: {
                id: companyId,
                descriptor: `${this.props.companies.find(x => x.companyId === companyId).descriptor}`
            },
            startDate,
            endDate,
            createdBy: userId,
            createdDate: undefined,
            updatedBy: userId,
            updatedDate: undefined,
            roiBooked: false
        };
    };

    render() {
        const { meetingId, companyId, meetings, companies, onCancel, resources } = this.props;
        let initialValues = meetings.find(x => x.meetingId === meetingId);

        if (!initialValues && meetingId < 0) {
            initialValues = this.emptyForm();
            initialValues.meetingId = meetingId;
        }

        initialValues.assigneeId = ((initialValues.assignee && initialValues.assignee.id) || '').toString();
        initialValues.companyId = ((initialValues.company && initialValues.company.id) || '').toString();
        initialValues.personId = ((initialValues.person && initialValues.person.id) || '').toString();

        const primaryAddress = getPrimaryAddress(companies, companyId);
        if (primaryAddress) {
            initialValues.location = primaryAddress.postCode;
        }

        return (
            <ReactiveForm
                initialValues={initialValues}
                cancel={onCancel}
                resources={resources}
                formName={FORM_NAME}
                validationSchema={validation}
                handleSubmit={this.handleSubmit}
            >
                <ReactiveForm.Section>
                    <ReactiveForm.Date showTimeSelect={true} columnDef={meeting.startDate} />
                    <ReactiveForm.Date showTimeSelect={true} columnDef={meeting.endDate} />
                    <ReactiveForm.Choicelist columnDef={meeting.activityRegarding} />
                    <ReactiveForm.Choicelist columnDef={meeting.action} disabled />
                    <ReactiveForm.Text columnDef={meeting.subject} />
                    <ReactiveForm.Choicelist columnDef={meeting.assigneeId} />
                    <ReactiveForm.Choicelist columnDef={meeting.status} />
                    <ReactiveForm.Text columnDef={meeting.location} />
                    <ReactiveForm.Choicelist columnDef={meeting.meetingMode} />
                    <ReactiveForm.Text columnDef={meeting.details} />
                    <ReactiveForm.Checkbox columnDef={meeting.roiBooked} />
                </ReactiveForm.Section>
            </ReactiveForm>
        );
    }
}

const mapStateToProps = state => ({
    meetings: state.meetings,
    communications: state.communications,
    companies: state.companies,
    user: state.user,
    opportunities: state.opportunities,
    people: state.people,
    resources: state.resources
});

const mapDispatchToProps = dispatch => ({
    save: (meeting, cancel) => {
        dispatch(apiMeetingPost(meeting));
        if (cancel) {
            cancel();
        }
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditMeetingForm);
