import React from 'react';
import {
    basicColumnDef,
    choiceListColumnDef,
    dateColumnDef,
    idColumnDef
} from '../common/FilteredTable/tableAndFilterHelpers';
import ColumnTypeFormatCompanyName from '../common/Table/ColumnTypeFormatCompanyName';

const visitAction = {
    id: idColumnDef('id', 'visitActionId'),
    activityRegarding: {
        ...choiceListColumnDef('Actvity Regarding', 'activityRegarding', 'visitActionActivityRegarding', {
            renderAsBasicText: true,
            displayEmptyAs: '-'
        }),
        emptyOption: 'Choose an appropriate activity regarding...'
    },
    eventType: {
        ...choiceListColumnDef('Event Type', 'eventType', 'communicationAction'), //TODO: Really?
        emptyOption: 'Choose an appropriate activity regarding...'
    },
    assignee: {
        ...choiceListColumnDef('Assignee', 'assigneeId', 'communicationAssignee'),
        emptyOption: 'Assign the visit action...'
    },
    companyDescriptor: {
        ...basicColumnDef('Company', 'companyDescriptor'),
        searchable: true
    },
    companyName: {
        ...basicColumnDef('Company Name', 'companyName'),
        mapping: (resources, values) => (values && values.company ? values.company.descriptor : ''),
        mapToDisplayedValue: (resources, values, field, actions) => (
            <ColumnTypeFormatCompanyName actions={actions} values={values} />
        )
    },
    endDate: {
        ...dateColumnDef('Close Date', 'endDate'),
        searchable: true
    },
    notes: {
        ...basicColumnDef('Notes', 'notes')
    },
    startDate: {
        ...dateColumnDef('Communication Date', 'startDate', true),
        searchable: true
    },
    status: {
        ...choiceListColumnDef('Status', 'status', 'visitActionStatus'),
        emptyOption: 'Choose the status of the visit report action...'
    },
    subject: {
        ...basicColumnDef('Subject', 'subject')
    },
    action: {
        ...choiceListColumnDef('Action', 'action', 'visitActionAction'),
        emptyOption: 'Select an action...',
        searchable: true
    },
    details: {
        ...basicColumnDef('Details', 'details')
    }
};

export default visitAction;
