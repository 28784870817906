import React from 'react';
import FleetProfileItem from './FleetProfileItem';
import MapArray from '../../common/MapArray';

const FleetProfileSection = props => {
    const { name, fields, index, data, cancel, allowFullWidth } = props;
    return (
        <ul className={`list-group ${index === 0 && allowFullWidth ? 'full-if-less-than-3' : ''} mb-3 no-shadow`}>
            {name && <li className="list-group-item list-group-item-grey">{name}</li>}
            <MapArray from={fields} data={data} map={(fields, index, data) => ({ ...fields, data })}>
                <FleetProfileItem cancel={cancel} />
            </MapArray>
        </ul>
    );
};

export default FleetProfileSection;
