import ActionButtons from '../../common/ActionButtons/ActionButtons';
import React from 'react';
import ActionButton from '../../common/ActionButtons/ActionButton';

const CalendarActionButtons = ({ getDefaultDate, createEvent }) => {
    return (
        <ActionButtons>
            <ActionButton
                buttonText="Add Event"
                fontawesomeIcon="fa-plus"
                bootstrapColor="secondary"
                onClickAction={() => createEvent('CSMEvent', { startDate: getDefaultDate().toDate() })}
            />
            <ActionButton
                buttonText="Add Admin Day"
                fontawesomeIcon="fa-plus"
                bootstrapColor="secondary"
                onClickAction={() => createEvent('CSMAdminDay', { startDate: getDefaultDate().toDate() })}
            />
            <ActionButton
                buttonText="Add Holiday"
                fontawesomeIcon="fa-plus"
                bootstrapColor="secondary"
                onClickAction={() => createEvent('CSMAnnualLeave', { startDate: getDefaultDate().toDate() })}
            />
        </ActionButtons>
    );
};

export default CalendarActionButtons;
