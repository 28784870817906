import { isLoading } from '../../Loading/isLoading';
import { isError } from '../../Loading/isError';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';
import { makePostRequest } from '../apiHelpers';
import { ENDPOINTS } from '../constants';
import { apiCompany } from '../Company/apiCompany';
import { apiOpportunityFilter } from '../Opportunity/apiOpportunityFilter';
import { dispatchServiceIsLoading } from '../../../reducers/Rest/loadingStates';
import { isOfflne } from '../../../reducers/Api/helper';
import moment from 'moment';

export const visitActionsQuery = (query = {}, assignedToCurrentUser = false, fetchCompany = true, user) => ({
    //filterAssignee: assigneeId => visitActionsQuery(query, assignedToCurrentUser, fetchCompany, assigneeId),

    //CompanyPage
    setFetchCompany: shouldFetch => visitActionsQuery(query, assignedToCurrentUser, shouldFetch, user),
    //CompanyPage
    filterCompany: companyId => visitActionsQuery({ companyId, ...query }, assignedToCurrentUser, fetchCompany, user),
    //meetingPage
    filterMeeting: meetingId => visitActionsQuery({ meetingId, ...query }, assignedToCurrentUser, fetchCompany, user),

    //filterEventType: eventType =>
    // visitActionsQuery({ eventType, ...query }, assignedToCurrentUser, fetchCompany, user),

    //calendarPage
    isAssignedToCurrentUser: () => visitActionsQuery(query, true, fetchCompany, user),

    //Next7Days
    //calendarPage
    isBetween: (startDate, endDate) =>
        visitActionsQuery(
            {
                startDate: startDate instanceof moment ? startDate.format('YYYY-MM-DD') : startDate,
                endDate: endDate instanceof moment ? endDate.format('YYYY-MM-DD') : endDate,
                ...query
            },
            assignedToCurrentUser,
            fetchCompany,
            user
        ),
    //Next7Days
    statusIn: status => visitActionsQuery({ status, ...query }, assignedToCurrentUser, fetchCompany, user),
    run: () => apiVisitActionsFilter(query, assignedToCurrentUser, user, fetchCompany)
});

export const apiVisitActionsFilter = (
    filter,
    assignedToCurrentUser = false,
    user = undefined,
    fetchCompany = true
) => async (dispatch, getState) => {
    const { companyId, meetingId, startDate, endDate, status } = filter;

    if (!getState().offline.online) {
        return false;
    }

    const type = ENDPOINTS.API.VISIT_ACTIONS_FILTER_COMMIT;

    const data = {
        companyId,
        meetingId,
        startDate,
        endDate,
        status,
        //eventType,
        //subject,
        assigneeId: assignedToCurrentUser ? (user ? user : getState().user.userId) : undefined
        //dealershipId: getState().user.dealershipId
    };

    try {
        dispatchServiceIsLoading(dispatch, type, true, {});

        const result = await makePostRequest(['visitaction', 'filter'], {}, data);

        const companies = getState().companies;
        const companyIds = _.uniq(
            result.data.map(x => x.company.id).filter(x => !companies.some(c => x === c.companyId))
        );

        if (isOfflne() && fetchCompany) {
            companyIds.forEach(companyId => {
                dispatch(apiCompany(companyId));
                dispatch(apiOpportunityFilter({ companyId }));
            });
        }

        dispatch({ type: ENDPOINTS.API.VISIT_ACTIONS_FILTER_COMMIT, payload: { data: result.data, filter: data } });
        isLoading(dispatch, type, false, {});
        return true;
    } catch (error) {
        isError(dispatch, type, true, error.message, {});
        if (getState().user.userId > -1) {
            toastr.error(type, `Response ${error.message}`);
        }
        return false;
    }
};
