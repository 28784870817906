import React from 'react';
import connect from 'react-redux/es/connect/connect';
import _ from 'lodash';
import ChoiceListFilteredTableNoDashboardData from '../../common/FilteredTable/ChoiceListFilteredTableNoDashboardData';
import { TableDefinition } from '../../common/FilteredTable/tableAndFilterHelpers';
import { mapToRows } from '../../common/Table/addColumnsToRow';
import { getLoadingState } from '../../../reducers/Rest/loadingStates';
import { ENDPOINTS } from '../../../actions/Api/constants';
import demosDef from '../demosDef';

class DemosTableCard extends React.Component {
    static defaultProps = {
        breakpoint: 500,
        itemsPerPage: 5,
        noItemsText: 'There are no demos',
        columns: [
            demosDef.demoRequired,
            demosDef.driverName,
            demosDef.registrationNumber,
            demosDef.requestedCar,
            demosDef.demoEndDate,
            demosDef.status
        ]
    };

    render() {
        const { rows, resources, columns, loadingStates, noItemsText, breakpoint, itemsPerPage } = this.props;

        const isLoading = getLoadingState(loadingStates, ENDPOINTS.API.COMPANY_DEMOS_GET).isLoading;
        const rowsSorted = [
            ..._.orderBy(rows.filter(x => x.demoEndDate !== null), ['demoEndDate'], ['desc']),
            ...rows.filter(x => x.demoEndDate === null)
        ];

        const tableDefinition = new TableDefinition(columns);
        return (
            <ChoiceListFilteredTableNoDashboardData
                id="Demos"
                title="Demos"
                noItemsText={noItemsText}
                tableIsNotInCard={true}
                showFilter={true}
                itemsPerPage={itemsPerPage}
                breakpoint={breakpoint}
                cardButtons={[]}
                isLoading={isLoading}
                tableDefinition={tableDefinition}
                rows={mapToRows(rowsSorted, demosDef.id, resources, tableDefinition.columns, undefined, undefined)}
            />
        );
    }
}

const mapStateToProps = state => ({
    loadingStates: state.loadingStates,
    resources: state.resources
});

export default connect(mapStateToProps)(DemosTableCard);
