import React, { useContext } from 'react';
import { connect } from 'react-redux';
import CommunicationsTableCard from './CommunicationsTableCard';
import { communicationSelected } from '../../../actions/Navigation/Communication/communicationSelected';
import communication from '../communication';
import { CompanyCommunicationsContext } from '../../Company/Provider/CompanyCommunicationsProvider';

function CompanyCommunicationsTable({ updateCommunication }) {
    const { companyId, communications } = useContext(CompanyCommunicationsContext);
    return (
        <CommunicationsTableCard
            rows={communications}
            companyId={companyId}
            editRow={updateCommunication}
            buttons={['addCommunication']}
            isLoading={false}
            columns={[
                communication.startDate,
                communication.activityRegarding,
                communication.assigneeId,
                communication.status,
                communication.body
            ]}
            title={'Communications'}
            breakpoint={860}
            initialSortColumn={'startDate'}
            initialSortOrder={'asc'}
        />
    );
}

const mapDispatchToProps = dispatch => ({
    updateCommunication: communication => dispatch(communicationSelected(communication))
});

export default connect(
    null,
    mapDispatchToProps
)(CompanyCommunicationsTable);
