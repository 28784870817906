import React from 'react';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import _ from 'lodash';
import PropTypes from 'prop-types';

class TextFilter extends React.Component {
    state = { search: '' };

    static propTypes = {
        rows: PropTypes.array.isRequired,
        searchFields: PropTypes.arrayOf(
            PropTypes.shape({
                path: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired
            })
        )
    };

    static defaultProps = {
        searchFields: []
    };

    filterRows(rows, search, searchFields) {
        return search && searchFields
            ? rows.filter(row =>
                  searchFields.some(field => {
                      const value = _.get(row, field.path, '');
                      return value && value.toLowerCase().includes(search);
                  })
              )
            : rows;
    }

    render() {
        const { children, rows, searchFields } = this.props;
        return children({
            searchBox:
                searchFields && searchFields.length ? (
                    <InputGroup className="w-100 mb-2">
                        <Input
                            value={this.state.search}
                            placeholder={`Filter by ${searchFields.map(x => x.name).join(', ')}`}
                            onChange={event => this.setState({ search: event.target.value })}
                        />
                        <InputGroupAddon addonType="append">
                            <Button onClick={() => this.setState({ search: '' })}>
                                <i className={`fa lg d-inline" ${this.state.search ? 'fa-times' : 'fa-search'}`} />
                            </Button>
                        </InputGroupAddon>
                    </InputGroup>
                ) : null,
            textFilteredRows: this.filterRows(rows, this.state.search.toLowerCase(), searchFields)
        });
    }
}

export default TextFilter;
