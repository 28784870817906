import { IEvent } from '../../../reducers/Api/Events';
import { Dispatch } from 'redux';
import { IAction, ENDPOINTS } from '../constants';
import { isError } from '../../Loading/isError';
import { toastr } from 'react-redux-toastr';
import { makePostRequest } from '../apiHelpers';

export interface IEventFilter {
	assigneeId?: number;
	startDate?: Date;
	endDate?: Date;
	status?: string[];
}

const type = ENDPOINTS.API.EVENT_FILTER;

export const apiEventFilter = (filter: IEventFilter) => async (dispatch: Dispatch<IAction>, getState: () => any) => {
	try {
		if (!getState().offline.online) {
			return false;
		}

		const response: IEvent[] = (await makePostRequest(['event', 'filter'], {}, filter)).data;

		dispatch({
			type,
			data: response,
		});

		return true;
	} catch (err) {
		isError(dispatch, type, true, err.message, {});

		if (getState().user.userId > -1) {
			toastr.error(type, `Response ${err.message}`);
		}

		return false;
	}
};
