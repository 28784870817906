import React from 'react';
import _ from 'lodash';
import FormikForm from './FormikForm';
import { Formik } from 'formik';
import { generateSchema, generateSchemaFromDefinition } from './formSchemaGenerator';
import MapArray from '../../common/MapArray';
import { FormSection } from './FormFieldFactory';

const isMatch = (matchType, matchValue, value) => {
    switch (matchType) {
        case '*':
            return true;
        case '=':
            return matchValue === value;
        default:
            return false;
    }
};

/*
Applies rules based on the field to set values in other fields.

match * will match everythign
match = will test the value in the current field to a specified value.

set will set the value to a specified value. If there is no to property the value of the target field
will be set to the current field.

"beforeSubmit": [
                        {
                            "match": "*",
                            "set": [
                                { "field": "eventType" },
                                { "field": "engageEntityType" }
                            ]
                        },
                        {
                            "match": "=",
                            "value": "a",
                            "set": [
                                { "field": "eventType", "to": "value" },
                                { "field": "engageEntityType", "to": "value" }
                            ]
                        }
                    ]
 */
const processSubmit = (form, onSubmit, formDefinition) => {
    formDefinition.sections.forEach(section =>
        section.fields
            .filter(field => field.hasOwnProperty('beforeSubmit'))
            .forEach(field => {
                field.beforeSubmit.forEach(beforeSubmit => {
                    let value = form[field.fieldName];
                    if (beforeSubmit.hasOwnProperty('convertToInt')) {
                        form[field.fieldName] =
                            beforeSubmit.convertToInt && typeof value === 'string' ? parseInt(value) : value;
                    }
                    if (isMatch(beforeSubmit.match, beforeSubmit.value, value)) {
                        beforeSubmit.set.forEach(
                            action => (form[action.field] = action.hasOwnProperty('to') ? action.to : value)
                        );
                    }
                });
            })
    );
    onSubmit(form);
};

const isNew = (formDefinition, initialValues) => formDefinition.id && _.get(initialValues, formDefinition.id, -1) < 0;

const FormWithSchema = props => {
    const {
        completeSchema,
        formDefinition,
        initialValues,
        resources,
        cancel,
        formName,
        hideMetaData,
        debugSections,
        onSubmit,
        optionsData //For custom options lists
    } = props;

    const schema = completeSchema
        ? generateSchema(completeSchema, formDefinition.sections)
        : generateSchemaFromDefinition(formDefinition, isNew(formDefinition, initialValues));

    if (formDefinition.numbered) {
        for (let n = 0; n < formDefinition.sections.length; ++n) {
            formDefinition.sections[n].sectionNumber = n + 1;
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={x => processSubmit(x, onSubmit, formDefinition)}
            render={formikProps => {
                formikProps.formName = formName;
                return (
                    <FormikForm
                        formikProps={formikProps}
                        cancel={cancel}
                        hideMetaData={hideMetaData}
                        resources={resources}
                        debugSections={[{ name: 'Form Values', object: formikProps.values }, ...debugSections]}
                    >
                        <MapArray
                            from={_.sortBy(formDefinition.sections, ['order'])}
                            map={(section, index, data) => ({ ...section, index, ...data })}
                            data={{ formikProps, optionsData, resources }}
                        >
                            <FormSection />
                        </MapArray>
                    </FormikForm>
                );
            }}
        />
    );
};

export default FormWithSchema;
