import React from 'react';
import PropTypes from 'prop-types';
import { NavRouterLink } from './NavRouterLink/index';
import moment from 'moment';

const NavigationItems = props => {
    const { onLinkOpen, navigation } = props;

    const className = 'd-none d-md-inline-flex';

    return (
        <React.Fragment>
            {navigation &&
                navigation.home &&
                !navigation.home.hideFromToolbar && (
                    <NavRouterLink
                        onLinkOpen={onLinkOpen}
                        icon="home"
                        className={className}
                        isVisible
                        name="Home"
                        link="/dashboard/home"
                    />
                )}
            {navigation &&
                navigation.companies &&
                !navigation.companies.hideFromToolbar && (
                    <NavRouterLink
                        onLinkOpen={onLinkOpen}
                        icon="building"
                        className={className}
                        isVisible
                        name="Companies"
                        link="/dashboard/companies"
                    />
                )}
            {navigation &&
                navigation.calendar &&
                !navigation.calendar.hideFromToolbar && (
                    <NavRouterLink
                        onLinkOpen={onLinkOpen}
                        icon="calendar"
                        className={className}
                        isVisible
                        name="Calendar"
                        link={`/calendar/month/${moment().year()}/${moment().month() + 1}/${moment().date()}`}
                    />
                )}
            {navigation &&
                Object.entries(navigation)
                    .filter(([key, value]) => value.isNamedDashboard && !value.hideFromToolbar)
                    .map(([key, value]) => (
                        <NavRouterLink
                            key={key}
                            onLinkOpen={onLinkOpen}
                            icon={value.icon}
                            className={className}
                            isVisible
                            name={value.name}
                            link={`/dashboard/${key}`}
                        />
                    ))}
        </React.Fragment>
    );
};

NavigationItems.propTypes = {
    onLinkOpen: PropTypes.func,
    isSidebar: PropTypes.bool
};

export default NavigationItems;
