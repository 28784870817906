import React from 'react';
import { SubSectionTitle } from '../SubSection/SubSectionTitle';
import { SET_COLLAPSED } from '../../../reducers/collapsed';
import { connect } from 'react-redux';
import BaseCard from './BaseCard';

class SimpleCardCollapsable extends React.Component {
    render = () => {
        const { buttons, children, title, className, isLoading, collapseName, collapsed, setCollapsed } = this.props;

        const isCollapsed = collapseName ? collapsed[collapseName] : false;
        return (
            <BaseCard
                title={
                    title ? (
                        <SubSectionTitle
                            key="card title"
                            title={title}
                            buttons={isCollapsed ? null : buttons}
                            isLoading={isLoading}
                            collapseName={collapseName}
                            collapsed={isCollapsed}
                            toggleCollapsed={setCollapsed}
                        />
                    ) : (
                        ''
                    )
                }
                className={className}
                children={isCollapsed ? null : children}
            />
        );
    };
}

const mapStateToProps = state => ({
    collapsed: state.collapsed
});

const mapDispatchToProps = dispatch => ({
    setCollapsed: (collapseName, isCollapsed) =>
        dispatch({ type: SET_COLLAPSED, data: { name: collapseName, isCollapsed } })
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SimpleCardCollapsable);
