import {
    basicColumnDef,
    choiceListColumnDef,
    dateColumnDef,
    idColumnDef
} from '../common/FilteredTable/tableAndFilterHelpers';

const demosDef = {
    id: idColumnDef('id', 'demoId'),
    demoRequired: {
        ...dateColumnDef('Demo Required Date', 'demoRequired', false, { displayEmptyAs: '-' }),
        searchable: true
    },
    driverName: {
        ...basicColumnDef('Driver', 'driverName'),
        mapping: (_, values) => {
            let result = `${values['driverFirstName'] || ''} ${values['driverLastName'] || ''}`.trim();
            if (!result) result = '-';
            return result;
        },
        searchable: true
    },
    driverFirstName: {
        ...basicColumnDef('Driver Name', 'driverFirstName')
    },
    driverLastName: {
        ...basicColumnDef('Driver Name', 'driverLastName')
    },
    registrationNumber: {
        ...basicColumnDef('Vehicle Registration Number', 'registrationNumber', { displayEmptyAs: '-' })
    },
    requestedCar: {
        ...basicColumnDef('Requested Car', 'requestedCar', { displayEmptyAs: '-' })
    },
    demoEndDate: {
        ...dateColumnDef('Demo End Date', 'demoEndDate', false, { displayEmptyAs: '-' })
    },
    status: {
        ...choiceListColumnDef('Status', 'status', 'demoStatus')
    }
};

export default demosDef;
