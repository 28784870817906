import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { getResources } from '../../../selectors/Api/Resources/resourcesSelector';
import VisitActionsTableCard from '../../VisitActions/Tables/VisitActionsTableCard';
import { visitActionsSelector } from '../../../selectors/Api/VisitActions/visitActionsSelector';
import { getUserIdFromState } from '../../../selectors/Api/User/userSelector';
import visitAction from '../visitAction';
import { outstandingVisitActions } from '../../../reducers/Api/VisitActions/VisitAction';

const OutstandingVisitActionsTable = ({ visitActions }) => (
    <VisitActionsTableCard
        columns={[visitAction.startDate, visitAction.companyName, visitAction.activityRegarding]}
        title="Outstanding Visit Actions"
        rows={visitActions}
    />
);

const makeMapStateToProps = () => {
    const getState = createSelector(
        [visitActionsSelector, getResources, getUserIdFromState],
        (visitActions, resources, userId) => ({
            visitActions: outstandingVisitActions(visitActions, userId),
            resources
        })
    );
    return (state, props) => getState(state, props);
};

export default connect(makeMapStateToProps)(OutstandingVisitActionsTable);
