import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import CreateLeadForm, { LEAD_FORM_NAME } from '../Forms/CreateLeadForm';
import { closeModal, FORM_PATHS } from '../../../actions/modals';

class CreateLeadModal extends React.Component {
    render() {
        const { modals, setModalClosed } = this.props;

        if (!modals.CreateLeadModal.isOpen) {
            return '';
        }

        return (
            <Modal isOpen toggle={() => setModalClosed()} className="modal-lg">
                <AppModalHeader toggle={() => setModalClosed()}>
                    {`${modals.CreateLeadModal.data.leadId < 0 ? 'Create' : 'Edit'} ${
                        this.props.dashboard.forms[LEAD_FORM_NAME]
                            ? this.props.dashboard.forms[LEAD_FORM_NAME].title
                            : 'Request'
                    }`}
                </AppModalHeader>
                <ModalBody>
                    <CreateLeadForm leadId={modals.CreateLeadModal.data.leadId} cancel={() => setModalClosed()} />
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    modals: state.modals,
    dashboard: state.dashboard
});

const mapDispatchToProps = dispatch => ({
    setModalClosed: () => dispatch(closeModal(FORM_PATHS.CreateLead.modalName))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateLeadModal);
