import { ENDPOINTS } from '../../../actions/Api/constants';
import { RESET_REDUCER } from '../../../actions/resetReducers';
import moment from 'moment';

const initialState = [
    {
        id: 99999,
        companyId: -5
    }
];

function filterToMatchFunction({ assigneeId, companyId, endDate, startDate, stages, ...rest }) {
    const start = startDate === undefined ? undefined : moment(startDate);
    const end = endDate === undefined ? undefined : moment(endDate);
    return opportunity => {
        const startDate = moment(opportunity.startDate);
        return (
            (!assigneeId || opportunity.assigneeId === assigneeId) &&
            (!companyId || opportunity.companyId === companyId) &&
            (!stages || stages.indexOf(opportunity.stage) > -1) &&
            ((!start && !end) || ((!end || startDate.isBefore(end)) && (!start || startDate.isAfter(start))))
        );
    };
}

const filterState = (state, filter, newData) => {
    //console.log(filter);
    //console.log(state);

    const matchesState = filterToMatchFunction(filter);

    const filteredState = state.filter(
        x => !newData.some(newComm => x.opportunityId === newComm.opportunityId) && !matchesState(x)
    );
    //console.log("state length before filter", state.length);
    //console.log("state length after filter", filteredState.length);

    return filteredState;
};

export const opportunities = (state = initialState, action) => {
    switch (action.type) {
        case RESET_REDUCER:
            return initialState;
        case ENDPOINTS.API.OPPORTUNITY_FILTER_COMMIT:
            const {
                payload: { data, filter }
            } = action;
            return [...data, ...filterState(state, filter, data)];

        case ENDPOINTS.API.OPPORTUNITY_POST_ROLLBACK:
            const rollbackTo = action.meta.unmodifiedOpportunity;

            if (rollbackTo.opportunityId < 0) {
                return [...state.filter(opportunity => rollbackTo.opportunityId !== opportunity.opportunityId)];
            }
            return updateOpportunityInState(rollbackTo, state);
        case ENDPOINTS.API.OPPORTUNITY_POST_COMMIT:
            const {
                meta: { opportunity: localOpportunity },
                payload: { data: remoteOpportunity }
            } = action;
            return [
                remoteOpportunity,
                ...state.filter(
                    x =>
                        localOpportunity.opportunityId !== x.opportunityId &&
                        remoteOpportunity.opportunityId !== x.opportunityId
                )
            ];
        case ENDPOINTS.API.OPPORTUNITY_POST:
            return updateOpportunityInState(action.data, state);
        default:
            return state;
    }
};

const updateOpportunityInState = (opportunity, state) => [
    opportunity,
    ...state.filter(x => opportunity.opportunityId !== x.opportunityId)
];
