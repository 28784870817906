import React, { useContext } from 'react';
import Tabs, { TabsButtons, TabsContent, TabsGroup, TabsTab } from '../../common/Tabs';
import { FilterTableRowButton } from '../../common/Buttons/TableButtons';
import BaseCard from '../../common/Card/BaseCard';
import UpdateFleetProfileEditForm from '../../FleetProfile/Forms/UpdateFleetProfileEditForm';
import CompanyOpportunitiesTable from '../../Opportunity/Tables/CompanyOpportunitiesTable';
import CompanyRegistrationsTable from '../../Registrations/Tables/CompanyRegistrationsTable';
import CompanyDemosTable from '../../Demos/Tables/CompanyDemosTable';
import AddOpportunityToCompanyButton from '../Components/AddOpportunityToCompanyButton';
import CompanyTermsTable from '../../Terms/Tables/CompanyTermsTable';
import { CompanyTermsContext } from '../Provider/CompanyTermsProvider';
import { CompanyDemosContext } from '../Provider/CompanyDemosProvider';
import { CompanyRegistrationsContext } from '../Provider/CompanyRegistrationsProvider';
import { CompanyOpportunitiesContext } from '../Provider/CompanyOpportunitiesProvider';
import useToggleOpen from '../../common/Hooks/useToggleOpen';

const CompanyTabs = () => {
    const { toggle, isOpen } = useToggleOpen();
    const { terms } = useContext(CompanyTermsContext);
    const { demos } = useContext(CompanyDemosContext);
    const { opportunities, companyId } = useContext(CompanyOpportunitiesContext);
    const { registrations } = useContext(CompanyRegistrationsContext);
    return (
        <BaseCard>
            <Tabs>
                <TabsGroup>
                    <TabsTab>Profile</TabsTab>
                    <TabsContent>
                        <UpdateFleetProfileEditForm />
                    </TabsContent>
                </TabsGroup>
                <TabsGroup>
                    <TabsTab>Opportunities ({opportunities.length})</TabsTab>
                    <TabsButtons>
                        <FilterTableRowButton onClick={toggle} />
                        <AddOpportunityToCompanyButton companyId={companyId} />
                    </TabsButtons>
                    <TabsContent>
                        <CompanyOpportunitiesTable showFilter={isOpen} />
                    </TabsContent>
                </TabsGroup>
                <TabsGroup>
                    <TabsTab>Terms ({terms.length})</TabsTab>
                    <TabsContent>
                        <CompanyTermsTable />
                    </TabsContent>
                </TabsGroup>
                <TabsGroup>
                    <TabsTab>Demos ({demos.length})</TabsTab>
                    <TabsContent id="demo">
                        <CompanyDemosTable />
                    </TabsContent>
                </TabsGroup>
                <TabsGroup>
                    <TabsTab>Registrations ({registrations.length})</TabsTab>
                    <TabsContent id="demo">
                        <CompanyRegistrationsTable />
                    </TabsContent>
                </TabsGroup>
            </Tabs>
        </BaseCard>
    );
};

export default CompanyTabs;
