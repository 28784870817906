import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Filter from '../FilteredTable/Filter';
import { RenderFilters } from './RenderFilters';
import _ from 'lodash';

const FilteredRows = ({ rows, children, showCount, showFilter, searchBox, resources, filterOptions }) => {
    const [filtered, setFiltered] = useState([]);
    const [filters, setFilters] = useState([]);

    const updateFilters = () => setFilters((filterOptions || []).map(x => new Filter(x, resources)));

    useEffect(
        () => {
            if (_.get(filters, 'length', []) !== _.get(filterOptions, 'length', [])) {
                updateFilters();
            }
        },
        [filters]
    );

    useEffect(() => updateFilters(), [resources]);

    useEffect(
        () => {
            let filteredRows = rows;
            filters.forEach(filter => {
                filteredRows = filter.filterRows(filteredRows);
            });
            setFiltered(filteredRows);
        },
        [rows, filters]
    );

    const updateFilter = (field, option, value) => {
        const filterIndex = filters.findIndex(x => x.field === field);

        if (filterIndex > -1) {
            filters[filterIndex] = filters[filterIndex].updateOption(option, value);
            setFilters([...filters]);
        }
    };

    return children({
        filter: (
            <RenderFilters
                rowCount={filtered.length}
                filteredRows={filtered}
                filters={filters}
                showFilter={showFilter}
                showCount={showCount}
                searchBox={searchBox}
                updateFilter={updateFilter}
            />
        ),
        filteredRows: filtered
    });
};

const mapStateToProps = state => ({
    resources: state.resources
});

export default connect(mapStateToProps)(FilteredRows);
