import { ADD_ROI_BEARER_TOKEN } from '../../store';
import { cookieGetToken, isCookieLoggedIn } from '../../reducers/cookies';
import axios from 'axios';
import { isLoading } from '../Loading/isLoading';
import { isError } from '../Loading/isError';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import apiEndpoint from '../apiEndpoint';

export const makeUrl = (state, endpoint, urlParams) =>
    `${apiEndpoint}${endpoint}${urlParams ? joinUrlParms(urlParams) : ''}`;

export const makePostRequest = (pathArray, urlParams, data) => axios(getAxiosPostRequest(pathArray, urlParams, data));
export const makePutRequest = (pathArray, urlParams, data) => axios(getAxiosPutRequest(pathArray, urlParams, data));
export const makeDeleteRequest = (pathArray, urlParams, data) =>
    axios(getAxiosDeleteRequest(pathArray, urlParams, data));

export const getAxiosDeleteRequest = (pathArray, urlParams, data) => ({
    method: 'DELETE',
    url: makeUrl(undefined, makePath(pathArray), urlParams),
    headers: makeHeaders(),
    data
});
export const getAxiosPostRequest = (pathArray, urlParams, data) => ({
    method: 'POST',
    url: makeUrl(undefined, makePath(pathArray), urlParams),
    headers: makeHeaders(),
    data
});

export const getAxiosPutRequest = (pathArray, urlParams, data) => ({
    method: 'PUT',
    url: makeUrl(undefined, makePath(pathArray), urlParams),
    headers: makeHeaders(),
    data
});

export const getAxiosPostOfflineRequest = (pathArray, urlParams, data) => ({
    method: 'POST',
    url: makeUrl(undefined, makePath(pathArray), urlParams),
    headers: makeHeadersOffline(),
    data
});

export const makeGetRequest = (pathArray, urlParams = {}) => axios(getAxiosGetRequest(pathArray, urlParams));

export const getAxiosGetRequest = (pathArray, urlParams = {}) => ({
    method: 'GET',
    url: makeUrl(undefined, makePath(pathArray), urlParams),
    headers: makeHeaders()
});

export const getAxiosGetOfflineRequest = (pathArray, urlParams = {}) => ({
    method: 'GET',
    url: makeUrl(undefined, makePath(pathArray), urlParams),
    headers: makeHeaders()
});

export const makeHeaders = () => {
    const token = cookieGetToken();
    return {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
        // 'Cache-Control': 'no-cache'
    };
};

export const getRestFormatDateTimeNow = () => moment().format(REST_TIME_FORMAT);

export const makeHeadersOffline = () => {
    return {
        'Content-Type': 'application/json',
        Authorization: ADD_ROI_BEARER_TOKEN
    };
};

export const REST_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';

export const makePath = parts =>
    parts.reduce((acc, part) => {
        if (part) {
            return `${acc}/${part}`;
        }
        return acc;
    }, '');

export const joinUrlParms = urlParams =>
    Object.keys(urlParams)
        .filter(param => urlParams[param] !== undefined)
        .reduce((acc, key) => `${acc}${acc === '' ? '?' : '&'}${key}=${urlParams[key]}`, '');

export const apiGet = (
    type,
    pathArray,
    urlParams = {},
    onResult = undefined,
    dispatchResultAsType = true,
    onError
) => async (dispatch, getState) => {
    if (abortRequest(getState)) {
        return false;
    }

    isLoading(dispatch, type, true, {});
    try {
        const result = await makeGetRequest(pathArray, urlParams);

        if (dispatchResultAsType) {
            dispatch({ type, data: result.data });
        }

        if (result.data && onResult) {
            dispatch(onResult(result));
        }

        isLoading(dispatch, type, false, {});
        return result || true;
    } catch (error) {
        handleError(dispatch, type, error, onError);
    }
};

//For when a get is called post due to passing data
export const apiGetPost = (
    type,
    pathArray,
    data,
    urlParams = {},
    onResult = undefined,
    dispatchResultAsType = true,
    onError
) => async (dispatch, getState) => {
    if (abortRequest(getState)) {
        return false;
    }

    isLoading(dispatch, type, true, {});
    try {
        const result = await makePostRequest(pathArray, urlParams, data);

        if (dispatchResultAsType) {
            dispatch({ type, data: result.data });
        }

        if (result.data && onResult) {
            dispatch(onResult(result));
        }

        isLoading(dispatch, type, false, {});
        return result || true;
    } catch (error) {
        handleError(dispatch, type, error, onError);
    }
};

export const abortRequest = getState => !isCookieLoggedIn() || !getState().offline.online;

export const apiRequest = ({
    httpMethod,
    type,
    pathArray,
    data = {},
    urlParams = {},
    onResult = undefined,
    dispatchResultAsType = true,
    onError = undefined,
    onResultWithDispatch = undefined
}) => async (dispatch, getState) => {
    if (abortRequest(getState)) {
        return false;
    }

    isLoading(dispatch, type, true, data);
    try {
        let result;
        switch (httpMethod) {
            case 'PUT':
                result = await makePutRequest(pathArray, urlParams, data);
                break;
            case 'POST':
                result = await makePostRequest(pathArray, urlParams, data);
                break;
            case 'DELETE':
                result = await makeDeleteRequest(pathArray, urlParams, data);
                break;
            case 'GET':
            default:
                result = await makeGetRequest(pathArray, urlParams, data);
                break;
        }

        if (dispatchResultAsType) {
            dispatch({ type, data: result.data });
        }

        if (result.data && onResult) {
            dispatch(onResult(result));
        }

        if (result.data && onResultWithDispatch) {
            onResultWithDispatch(dispatch, result);
        }

        isLoading(dispatch, type, false, {});
        return result || true;
    } catch (error) {
        handleError(dispatch, type, error, onError);
    }
};

export const apiPut = (
    type,
    pathArray,
    data,
    urlParams = {},
    onResult = undefined,
    dispatchResultAsType = true,
    onError
) => async (dispatch, getState) => {
    if (abortRequest(getState)) {
        return false;
    }

    isLoading(dispatch, type, true, {});
    try {
        const result = await makePutRequest(pathArray, urlParams, data);

        if (dispatchResultAsType) {
            dispatch({ type, data: result.data });
        }

        if (result.data && onResult) {
            dispatch(onResult(result));
        }

        isLoading(dispatch, type, false, {});
        return result || true;
    } catch (error) {
        handleError(dispatch, type, error, onError);
    }
};

const handleError = (dispatch, type, error, onError) => {
    if (onError) {
        return onError(dispatch, type, error.response ? error.response.data.error : error);
    } else {
        isError(dispatch, type, true, error.message, {});
        console.log(error);
        toastr.error(
            type,
            `Response ${error.response && error.response.data ? error.response.data.error.message : error.message}`
        );
        return false;
    }
};

export const apiDelete = (
    type,
    pathArray,
    data,
    urlParams = {},
    onResult = undefined,
    dispatchResultAsType = true,
    onError
) => async (dispatch, getState) => {
    if (abortRequest(getState)) {
        return false;
    }

    isLoading(dispatch, type, true, {});
    try {
        const result = await makeDeleteRequest(pathArray, urlParams, data);

        if (dispatchResultAsType) {
            dispatch({ type, data: result.data });
        }

        if (result.data && onResult) {
            dispatch(onResult(result));
        }

        isLoading(dispatch, type, false, {});
        return result || true;
    } catch (error) {
        handleError(dispatch, type, error, onError);
    }
};

export const offlinePost = (
    type,
    commit,
    rollback,
    modified,
    unmodified,
    data,
    id,
    pathArray,
    urlParams
) => dispatch => {
    const effect = getAxiosPostOfflineRequest(pathArray, urlParams, data);
    unmodified.error = undefined;
    data.createdDate = data.createdDate || getRestFormatDateTimeNow();
    data.updatedDate = getRestFormatDateTimeNow();
    dispatch({
        type,
        data: { ...modified, syncing: true },
        entityId: `${type}-${id}`, //Used in store.js to see if offline updates are targeting the same entity
        meta: {
            offline: {
                effect,
                commit: { type: commit, meta: { modified } },
                rollback: { type: rollback, meta: { unmodified, postedData: data } }
            }
        }
    });
};
