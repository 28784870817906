import { basicColumnDef, idColumnDef, choiceListColumnSettings } from '../common/FilteredTable/tableAndFilterHelpers';

const eventDef = {
    id: idColumnDef('id', 'eventId'),
    eventType: {
        ...basicColumnDef('Event Type', 'eventType'),
        ...choiceListColumnSettings('communicationEventType')
    },
    startDate: {
        ...basicColumnDef('Start Date', 'startDate'),
        type: 'date',
        showTimeSelect: true
    },
    endDate: {
        ...basicColumnDef('End Date', 'endDate'),
        type: 'date',
        showTimeSelect: true
    },
    body: {
        ...basicColumnDef('Notes', 'notes'),
        type: 'textarea'
    }
};

export default eventDef;
