import * as yup from 'yup';
import _ from 'lodash';
import moment from 'moment';

const findAllFields = schema => {
    let allFields = [];
    schema.forEach(section => {
        allFields = [...allFields, ...section.fields];
    });
    allFields = allFields.map(x => x.fieldName);
    return _.uniq(allFields);
};

export const generateSchema = (completeSchema, formDefinition) => {
    const shape = findAllFields(formDefinition).reduce((acc, field) => {
        if (completeSchema[field]) {
            acc[field] = completeSchema[field];
        }
        return acc;
    }, {});
    return yup.object().shape(shape);
};

export const generateSchemaFromDefinition = ({ sections }, isNew, schema = {}) =>
    yup.object().shape(
        sections.reduce((acc, section) => {
            section.fields
                .filter(field => field.schema || field.yup)
                .forEach(field => {
                    if (field.yup) {
                        acc[field.fieldName] = field.yup;
                    } else if (field.schema) {
                        field.type === 'div'
                            ? createYup(field.schema, isNew, field.fields, acc)
                            : (acc[field.fieldName] = createYup(field.schema, isNew, field.fields));
                    }
                });
            return acc;
        }, schema)
    );

const createYup = ({ type, required, negative, invalidEmail, inFutureIfNew, length }, isNew, fields, schema) => {
    switch (type) {
        case 'div':
            return generateSchemaFromDefinition({ sections: [{ fields }] }, isNew, schema);
        case 'date':
            return required
                ? yup.date().required(required)
                : inFutureIfNew
                ? yup.date().test('inFuture', inFutureIfNew, (x, y, z) => !isNew || moment(x).isAfter(moment()))
                : yup.date();
        case 'bool':
            return required ? yup.bool().required(required) : yup.bool();
        case 'email':
            return required
                ? yup
                      .string()
                      .email(invalidEmail)
                      .required(required)
                : yup.string().email(invalidEmail);
        case 'positiveNumber':
            return required
                ? yup
                      .number()
                      .positive(negative)
                      .required(required)
                : yup.number();
        default:
            let stringValidator = yup.string();
            stringValidator = required ? stringValidator.required(required) : stringValidator.nullable();
            if (length) {
                stringValidator = stringValidator.min(length.min, length.message);
                stringValidator = stringValidator.max(length.max, length.message);
            }
            return stringValidator;
    }
};

export const generateInitialValues = formDefinition =>
    formDefinition.sections.reduce((acc, section) => {
        section.fields.forEach(
            field => (acc[field.fieldName] = field.hasOwnProperty('defaultValue') ? field.defaultValue : '')
        );
        return acc;
    }, {});
