import React, { useMemo } from 'react';
import ColumnTypeDate from '../Table/ColumnTypeDate';
import moment from 'moment';
import CaptionBadge from './CaptionBadge';
import ColumnTypeFormatWebsiteLink from '../Table/ColumnTypeFormatWebsiteLink';
import { PreventClickThrough } from '../Table/PreventClickThrough';
import _ from 'lodash';

export const makeTableColumns = (columnDefinitions, columns) =>
    columns.reduce((arr, column) => {
        if (typeof column === 'string') {
            const col = columnDefinitions[column];
            //Is this a bit crazy code? I think I may have to work out what it does when I see it
            (!col && !console.warn(`Column: ${column} is not predefined for Communications Table`)) || arr.push(col);
        } else {
            arr.push(column);
        }
        return arr;
    }, []);

export const makeTableSearchFields = (defs, columns) =>
    columns.reduce((arr, column) => {
        if (defs[column]) {
            arr.push(defs[column]);
        }
        return arr;
    }, []);

export const makeTableFilters = (filterDefinitions, filters) =>
    filters.reduce((arr, filter) => {
        if (typeof filter === 'string') {
            const col = filterDefinitions[filter];
            !col || arr.push(col);
        } else {
            arr.push(filter);
        }
        return arr;
    }, []);

//export const makeFilters = filters => filters.map((filter, index) => ({ ...filter, index }));
//Choicelist can be an array of choicelist names, or a single choicelist name as a string
export const filterSpec = (label, field, choiceList, unselected) => ({
    label,
    field,
    choiceList,
    unselected,
    index: 0
});
/*
export const filterSpecRange = (label, field, choiceList, rangeUnit, unselected) => ({
    label,
    field,
    choiceList,
    rangeUnit,
    unselected,
    index: 0
});
*/
export const idColumnDef = (title, field) => ({
    ...basicColumnDef(title, field),
    mapping: (resources, values) => values[field]
});

export const basicColumnDef = (title, field, options = {}) => {
    const { displayEmptyAs = undefined } = options;
    const result = {
        title,
        field,
        sort: true
    };
    if (displayEmptyAs) {
        result.mapping = (resources, values, field, action) => (values[field] ? values[field] : displayEmptyAs);
    }
    return result;
};

/*
export const textAreaColumnDef = (title, field) => ({
    title,
    field,
    type: 'textarea'
});
*/

export const websiteColumnDef = (title, field) => ({
    ...basicColumnDef(title, field),
    valueToSortOnMapping: (resources, values) => values[field],
    mapToDisplayedValue: (resources, values) => <ColumnTypeFormatWebsiteLink values={{ webSite: values[field] }} />
});

export const emailColumnDef = (title, field) => ({
    ...basicColumnDef(title, field),
    valueToSortOnMapping: (resources, values) => values[field],
    mapToDisplayedValue: (resources, values) => (
        <a href={`mailto:${values[field]}`} onClick={PreventClickThrough}>
            {values[field]}
        </a>
    )
});

export const phoneColumnDef = (title, field) => ({
    ...basicColumnDef(title, field),
    valueToSortOnMapping: (resources, values) => values[field],
    mapToDisplayedValue: (resources, values) => (
        <a href={`tel:${values[field]}`} onClick={PreventClickThrough}>
            <span>
                <i className="fa fa-phone" /> {values[field]}
            </span>
        </a>
    )
});

export const booleanColumnDef = (title, field) => ({
    ...basicColumnDef(title, field),
    valueToSortOnMapping: (resources, values) => values[field],
    mapToDisplayedValue: (resources, values) => values[field]
});

export const dateColumnDef = (title, field, showSync = false, options = {}) => {
    const { displayEmptyAs = undefined } = options;
    return {
        ...basicColumnDef(title, field),
        mapping: (resources, values) => moment(values[field]).format(process.env.REACT_APP_DATE_FORMAT),
        valueToSortOnMapping: (resources, values) => values[field],
        mapToDisplayedValue: (resources, values) => (
            <ColumnTypeDate values={values} field={field} showSync={showSync} displayEmptyAs={displayEmptyAs} />
        ),
        type: 'date'
    };
};

export const timeColumnDef = (title, field, showSync = false) => ({
    ...basicColumnDef(title, field),
    mapping: (_resources, values) => moment(values[field]).format(process.env.REACT_APP_TIME_FORMAT)
});

export const choiceListColumnDef = (title, field, choiceListName, options = {}) => {
    const result = {
        ...basicColumnDef(title, field),
        ...choiceListColumnSettings(choiceListName, field)
    };

    const { renderAsBasicText = false, displayEmptyAs = '' } = options;
    if (renderAsBasicText) {
        result.mapToDisplayedValue = (resources, values, field) => {
            const key = values[field];
            if (!key) return displayEmptyAs;

            const choiceList = _.get(resources, `choiceList[${choiceListName}]`, []);

            if (!choiceList) return key;

            const choiceListMatch = choiceList.find(x => x.key === key.toString());
            if (!choiceListMatch) return key;

            return choiceListMatch.value;
        };
    }

    return result;
};

export const choiceListColumnSettings = (choiceList, defaultField) => ({
    right: true,
    choiceList: choiceList,
    type: 'captionBadge',
    mapToDisplayedValue: (resources, values, field) => (
        <CaptionBadge choiceList={choiceList} caption={values[field || defaultField]} resources={resources} />
    )
});

export const useMemoTableDefinition = columns => useMemo(() => new TableDefinition(columns), [columns]);

export class TableDefinition {
    constructor(columns) {
        this.columns = columns;

        this.filters = columns
            .filter(x => x !== undefined && x.choiceList)
            .map(({ field, title, choiceList, rangeUnit, unselected }) => ({
                field,
                label: title,
                choiceList,
                index: 0,
                rangeUnit,
                unselected
            }));

        this.searchFields = columns
            .filter(x => x !== undefined && x.searchable)
            .map(columnDef => ({
                path: columnDef.path || columnDef.field,
                name: columnDef.title
            }));
    }
}

export const mapColumnDefToChoiceList = columnDef =>
    columnDef
        ? {
              fieldName: columnDef.field,
              title: columnDef.title,
              emptyOption: columnDef.emptyOption,
              choiceList: columnDef.choiceList
          }
        : undefined;

export const mapColumnDefToCheckbox = columnDef =>
    columnDef
        ? {
              fieldName: columnDef.field,
              title: columnDef.title
          }
        : undefined;

export const mapColumnDefToDate = columnDef =>
    columnDef
        ? {
              fieldName: columnDef.field,
              title: columnDef.title,
              showTimeSelect: columnDef.showTimeSelect
          }
        : undefined;

export const mapColumnDefToText = columnDef =>
    columnDef
        ? {
              fieldName: columnDef.field,
              title: columnDef.title,
              type: columnDef.type
          }
        : undefined;
