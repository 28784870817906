import React from 'react';
import { FormatDate } from '../../common/FormatDate';
import { DisplayField } from '../../common/DisplayField/index';
import SelectInlineChoicelist from '../../common/InlineEdit/SelectInlineChoicelist';
import Map from '../../Address/Map';
import { webSiteHttp } from '../../../reducers/util';
import GdprOverview from '../../Gdpr/GdprOverview';
import { DisplayIcon } from '../../common/DisplayIcon';
import DashboardFieldChoiceList from './DashboardFieldChoiceList';
import DashboardFieldEditLink from './DashboardFieldEditLink';
import _ from 'lodash';
import PersonDescriptor from '../../Person/Cards/PersonDescriptor';

const saveDealerStatus = (meta, value) => {
    const { save, choiceListName2 } = meta;

    return (
        <SelectInlineChoicelist
            choiceListName={choiceListName2}
            value={value || 'Not specified'}
            multiple={false}
            objectId={value}
            required
            propertyName="status"
            title="Status"
            save={(_, val) => save(val)}
            stack
        />
    );
};

export const DashboardFieldColumnDef = ({
    item,
    dashboardFieldType,
    columnDef: { title, field, type, showTime = true }
}) => (
    <DashboardField
        fieldProps={{
            title,
            value: _.get(item, field, ''),
            meta: item,
            type: dashboardFieldType || type,
            showTime
        }}
    />
);

const DashboardField = props => {
    const {
        fieldProps: { title, value, type, meta, showTime }
    } = props;

    switch (type) {
        case 'companyName':
            const { companyId, descriptor } = meta;
            return <DisplayField title={title} value={value} link={`/company/${companyId}/${descriptor}`} />;

        case 'personName':
            return <DisplayField title={title} value={<PersonDescriptor {...meta} />} primary />;

        case 'phone':
            return (
                <DisplayField
                    fontawesome="fa-phone"
                    title={title}
                    indicateIsEmpty
                    value={value && <a href={`tel:${value}`}>{value}</a>}
                />
            );

        case 'mobile':
            return (
                <DisplayField
                    fontawesome="fa-mobile"
                    title={title}
                    indicateIsEmpty
                    value={value && <a href={`tel:${value}`}>{value}</a>}
                />
            );

        case 'email':
            return <DisplayField title={title} fontawesome="fa-at" value={<a href={`mailto:${value}`}>{value}</a>} />;
        case 'map':
            return <DisplayField key="Location" title="Location" value={<Map address={value} />} primary />;
        case 'choiceList':
            return <DashboardFieldChoiceList {...props} />;
        case 'dealerStatus':
            return saveDealerStatus(meta, value);
        case 'link':
            let href = webSiteHttp(value);
            return <DisplayField title={title} value={<DisplayIcon href={href} />} />;
        case 'edit-link':
            return <DashboardFieldEditLink {...props} />;
        case 'textArray':
            const combinedValues = value
                .join(' ')
                .replace(/\s{2,}/, ' ')
                .trim();
            return <DisplayField title={title} value={combinedValues} />;
        case 'gdpr':
            return <DisplayField title={title} value={<GdprOverview person={meta} />} />;
        case 'text':
        case 'textarea':
            return <DisplayField title={title} value={isNullOrUndefinedOrEmpty(value) ? 'Unknown' : value} />;
        case 'metaData':
            return <DisplayField title={title} value={value ? value.descriptor : 'Unknown'} />;
        case 'date':
            return <DisplayField title={title} value={<FormatDate date={value} showTime={showTime} />} />;
        case 'yesno':
            return <DisplayField title={title} value={value ? 'Yes' : 'No'} />;
        default:
            console.warn(`Unknown field type found in schema: ${title} ${type}`);
            return null;
    }
};

const isNullOrUndefinedOrEmpty = val => val === undefined || val === null || val === '';
