import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { AppLogo } from '../../app/AppLogo';
import { Formik } from 'formik';
import FormikSelectChoicelist from '../../common/FormkControls/FormikSelectChoicelist';
import * as Yup from 'yup';
import { apiDealershipUsers } from '../../../actions/Api/Dealership/apiDealershipUsers';

const schema = Yup.object().shape({
    dealership: Yup.string().required()
});

class SelectDealershipForm extends React.Component {
    handleSubmit = form => {
        this.props.dealershipSelected(
            form.dealership,
            this.props.user.dealerships.find(x => x.userId === form.dealership).descriptor
        );
    };

    render() {
        const { className, user } = this.props;

        if (!user.dealerships) {
            return <div />;
        }

        const initialValues = {
            dealership: undefined
        };

        return (
            <Formik
                initialValues={initialValues}
                onSubmit={this.handleSubmit}
                validationSchema={schema}
                render={formikProps => {
                    formikProps.formName = 'dealershipSelectForm';
                    return (
                        <form className={className} onSubmit={formikProps.handleSubmit}>
                            <div className="w-100 text-center mb-4">
                                <h6 className="mb-0">Sign in to</h6>
                                <AppLogo />
                            </div>
                            <div className="mb-4">
                                <FormikSelectChoicelist
                                    emptyOption="Select a Dealership"
                                    fieldName="dealership"
                                    hideLabel={true}
                                    options={user.dealerships.map(dealership => ({
                                        key: dealership.userId,
                                        value: dealership.descriptor
                                    }))}
                                    {...formikProps}
                                />
                            </div>
                            <Button type="submit" color="primary" className="w-100">
                                Select Dealership
                            </Button>
                        </form>
                    );
                }}
            />
        );
    }
}

const mapStateToProps = state => ({
    loadingStates: state.loadingStates,
    resetPassword: state.resetPassword,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    dealershipSelected: (dealershipId, dealershipDescriptor) =>
        dispatch(apiDealershipUsers(dealershipId, dealershipDescriptor))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectDealershipForm);
