import React, { useState } from 'react';

function Tabs({ defaultGroup = 0, children }) {
    const [active, setActive] = useState(defaultGroup);

    return (
        <div>
            <div className="d-flex px-1 py-4">
                <div className="flex-grow-1">
                    {/* returns each Tabs.Name */}
                    {React.Children.map(children, (group, index) => {
                        const tab = group.props.children.find(child => child.type.name === TabsTab.name);
                        return tab ? React.cloneElement(tab, { index, active, setActive }) : undefined;
                    })}
                </div>
                <div className="flex-shrink-0">
                    {React.Children.map(children, (group, index) => {
                        const tabButtons = group.props.children.find(child => child.type.name === TabsButtons.name);
                        return tabButtons ? React.cloneElement(tabButtons, { index, active, setActive }) : undefined;
                    })}
                </div>
            </div>
            {React.Children.map(children, (group, index) => {
                const tabContent = group.props.children.find(child => child.type.name === TabsContent.name);
                return tabContent ? React.cloneElement(tabContent, { index, active, setActive }) : undefined;
            })}
        </div>
    );
}

export const TabsGroup = ({ children }) => {
    return <div>{children}</div>;
};

export const TabsTab = ({ index, active, setActive, style = {}, children }) => {
    const styles = {
        background: 'none',
        border: 'none',
        borderBottom: 'none',
        cursor: 'pointer',
        fontSize: '1.25rem',
        margin: '0 0.25rem',
        outline: 'none',
        padding: '0.25rem',
        color: 'rgb(33, 37, 41)'
    };
    const stylesActive = { borderBottom: '2px solid rgb(217, 217, 217)', ...style.tabActive };
    const stylesDetermined = index === active ? { ...styles, ...stylesActive } : styles;
    return (
        <button style={stylesDetermined} onClick={() => setActive(index)}>
            {children}
        </button>
    );
};

export const TabsButtons = ({ index, active, children }) => {
    return index === active && <div>{children}</div>;
};

export const TabsContent = ({ index, active, children }) => {
    return index === active && <div>{children}</div>;
};

export default Tabs;
