import { ENDPOINTS } from '../constants';
import { apiUserLoggedIn } from '../../ApiAuth/userLoggedIn';
import { isLoading } from '../../Loading/isLoading';
import { makeGetRequest } from '../apiHelpers';
import { isError } from '../../Loading/isError';
import { toastr } from 'react-redux-toastr';

export const apiDealershipUsers = (dealershipId, dealershipDescriptor) => async dispatch => {
    const type = ENDPOINTS.API.DEALERSHIP_USERS_GET;

    isLoading(dispatch, type, true, {});
    try {
        const result = await makeGetRequest(['Dealership', 'users'], { dealershipId });

        isLoading(dispatch, type, false, {});
        dispatch({
            type,
            data: {
                dealershipId,
                dealershipDescriptor,
                dealershipUsers: result.data
            }
        });

        dispatch(apiUserLoggedIn());
        return result || true;
    } catch (error) {
        isError(dispatch, type, true, error.message, {});
        toastr.error(type, `Response ${error.message}`);
        return false;
    }
};
