import React, { useContext } from 'react';

import SimpleCard from '../../common/Card/SimpleCard';
import FormikSelectChoicelist from '../../common/FormkControls/FormikSelectChoicelist';
import FormikText from '../../common/FormkControls/FormikText';
import { CommunicationContext } from '../Provider/CommunicationProvider';

function AppointmentFeedbackForm({ formikProps }) {
    //TODO: Should this be meeting context
    const { communication } = useContext(CommunicationContext);

    return (
        <SimpleCard title="Appointment Feedback">
            <FormikSelectChoicelist
                emptyOption="Select a rating..."
                disabled={communication.status !== 'Pending'}
                fieldName="rating"
                choiceList="communicationAppointmentRating"
                {...formikProps}
            />
            <FormikText
                fieldName="feedback"
                {...formikProps}
                type="textarea"
                disabled={communication.status !== 'Pending'}
            />
        </SimpleCard>
    );
}

export default AppointmentFeedbackForm;
