import { combineReducers } from 'redux';
import { createForms } from 'react-redux-form';
import { connectRouter } from 'connected-react-router';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { loadingStates } from '../reducers/Rest/loadingStates';
import { emailSettings } from '../reducers/Rest/emailSettings';
import { opportunities } from '../reducers/Api/Opportunities';
import { people } from '../reducers/Api/People';
import { companies } from '../reducers/Api/Company';
import { companySummaries } from '../reducers/Api/CompanySummaries';
import { tableSortOrders } from '../reducers/table_sort_order';
import { popover } from '../reducers/Popover';
import { resources } from '../reducers/Api/Resources/index';
import { modals } from '../reducers/modals';
import { user } from '../reducers/Api/User/index';
import { errors } from '../reducers/Api/Errors/errors';
import { dashboard } from '../reducers/dashboard/dashboard';
import { resetPassword } from '../reducers/Api/Auth/resetPassword';
import demos from '../reducers/Api/Demo';
import { collapsed } from '../reducers/collapsed';
import { fleetProfiles } from '../reducers/Api/FleetProfiles/index';
import { leads } from '../reducers/Api/Leads/index';
import { settings } from '../reducers/Settings/Settings';
import { schema } from '../reducers/Schema/index';
import { registrations } from '../reducers/Api/Company/registrations';
import { reports } from '../reducers/Api/Reports/reports';
import { visitActions } from '../reducers/Api/VisitActions/visitActions';
import { address } from '../reducers/Api/Address/index';
import { terms } from '../reducers/Api/Terms';
import events from '../reducers/Api/Events';
import meetings from '../reducers/Api/Meeting';
import communicationsNew from '../reducers/Api/Communications';

export default history =>
    combineReducers({
        router: connectRouter(history),
        toastr: toastrReducer,
        companies,
        collapsed,
        dashboard,
        emailSettings,
        demos,
        errors,
        fleetProfiles,
        leads,
        resources,
        companySummaries,
        tableSortOrders,
        opportunities,
        communications: communicationsNew,
        modals,
        people,
        schema,
        registrations,
        loadingStates,
        settings,
        popover,
        user,
        resetPassword,
        //opportunitiesByCompany,
        reports,
        visitActions,
        address,
        terms,
        events,
        meetings,
        ...createForms({
            agreementForm: { notSet: true },
            editFieldForm: {
                value: '',
                title: ''
            },
            agreementTermForm: {}
            /*login: {
            emailAddress: process.env.REACT_APP_DEFAULT_USERNAME,
            password: process.env.REACT_APP_DEFAULT_PASSWORD
        }*/
        })
    });
