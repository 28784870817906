import React, {useEffect} from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import {Formik} from 'formik';
import * as yup from 'yup';
import {createOpportunity} from '../../../actions/Navigation/Opportunity/createOpportunity';
import {apiUserLogPost} from '../../../actions/Api/User/apiUserLogPost';
import CompanyFleetProfileEditCard from '../../FleetProfile/Cards/CompanyFleetProfileEditCard';
import MeetingNotes from '../../Communication/Components/MeetingNotes';
import AppointmentFeedbackForm from '../../Communication/Forms/AppointmentFeedbackForm';
import {DebugSection} from '../../common/DebugSection';
import PageSection from '../../common/PageSection';
import FormikErrors from '../../common/FormkControls/FormikErrors';
import CompanyDetails from '../../Company/Components/CompanyDetails';
import MeetingAndForm from '../../Meeting/Forms/MeetingAndForm';
import CommunicationPageTitleContent from '../../Communication/Components/CommunicationPageTitleContent';
import CompanyPrimaryAddressesDetails from '../../Address/Components/CompanyPrimaryAddressesDetails';
import {createSelector} from 'reselect';
import RedirectNotLoggedIn from '../RedirectNotLoggedIn';
import Layout3Columns, {Column1, Column2, Column3, Column3Top} from '../../Dashboard/Components/Layout3Columns';
import MeetingVisitActionsTable from '../../VisitActions/Tables/MeetingVisitActionsTable';
import {apiMeetingFilter} from '../../../actions/Api/Meeting/apiMeetingFilter';
import MeetingProvider from '../../Meeting/Provider/MeetingProvider';
import {apiMeetingPost} from '../../../actions/Api/Meeting/apiMeetingPost';
import ViewMeetingPageButtons from '../../Meeting/Components/ViewMeetingPageButtons';
import {visitActionsQuery} from '../../../actions/Api/VisitAction/apiVisitActionsFilter';
import MeetingPageOverview from '../../Meeting/Components/MeetingPageOverview';
import {apiCompanyOffline} from '../../../actions/Api/Company/apiCompanyOffline';

const schema = yup.object().shape({
    meetingNotes: yup.string(),
    rating: yup
        .string()
        .required()
        .nullable(),
    feedback: yup.string().required().nullable()
});

const saveVisitReport = (formikData, communicationOriginal, saveCommunication) => {
    const { meetingNotes, rating, feedback } = formikData;
    const communication = { ...communicationOriginal };
    communication.status = 'Complete';
    communication.notes = meetingNotes;
    communication.rating = rating;
    communication.feedback = feedback;
    saveCommunication(communication);
};

function MeetingPage({
    getCompany,
    meeting,
    descriptor,
    meetingId,
    history,
    location,
    saveCommunication,
    initialValues
}) {
    const companyId = _.get(meeting, 'company.id') || _.get(meeting, 'companyId');
    useEffect(
        () => {
            companyId && getCompany(companyId, true);
        },
        [companyId, getCompany]
    );

    return (
        <>
            <RedirectNotLoggedIn
                onLoggedInDispatchArray={[
                    apiUserLogPost(descriptor, 'Communication', location.pathname),
                    apiMeetingFilter({ meetingId: meetingId }),

                    visitActionsQuery()
                        .filterMeeting(meetingId)
                        .run()
                ]}
            />
            <MeetingProvider meetingId={meetingId}>
                <Formik
                    initialValues={initialValues}
                    onSubmit={formikData => saveVisitReport(formikData, meeting, saveCommunication)}
                    validationSchema={schema}
                    enableReinitialize={true}
                    render={formikProps => {
                        formikProps.formName = 'visitReportForm';
                        return (
                            <div>
                                <PageSection
                                    onBackClicked={history.goBack}
                                    title={meeting.subject ? meeting.subject : ''}
                                    titleContent={<CommunicationPageTitleContent />}
                                >
                                    <FormikErrors errors={formikProps.errors} />
                                    <Layout3Columns>
                                        <Column1>
                                            <MeetingPageOverview />
                                            <CompanyDetails />
                                            <MeetingAndForm />
                                            <CompanyPrimaryAddressesDetails />
                                        </Column1>
                                        <Column2>
                                            <CompanyFleetProfileEditCard/>
                                            <MeetingNotes formikProps={formikProps}/>
                                            <MeetingVisitActionsTable/>
                                            {meeting.status === 'Complete' && (
                                                <AppointmentFeedbackForm formikProps={formikProps}/>
                                            )}
                                        </Column2>
                                        <Column3>
                                            <DebugSection sections={[{ name: 'meeting', object: meeting }]} />
                                        </Column3>
                                        <Column3Top>
                                            <ViewMeetingPageButtons
                                                formikProps={formikProps}
                                                url={window.location.href}
                                            />
                                        </Column3Top>
                                    </Layout3Columns>
                                </PageSection>
                            </div>
                        );
                    }}
                />
            </MeetingProvider>
        </>
    );
}

const makeMapStateToProps = () => {
    const getState = createSelector(
        [
            state => state.meetings,
            state => state.communications,
            state => state.companies,
            (
                _,
                {
                    match: {
                        params: { descriptor, communicationId: strCommunicationId }
                    }
                }
            ) => ({ descriptor, strCommunicationId })
        ],
        (meetings, communications, companies, { strCommunicationId, descriptor }) => {
            const meetingId = Number.parseInt(strCommunicationId, 10);
            const meeting = meetings.find(meeting => meeting.meetingId === meetingId);

            //console.log(meeting)

            const communicationId = Number.parseInt(strCommunicationId, 10);
            const communication = communications.find(
                communication => communication.communicationId === communicationId
            );

            const company = communication
                ? companies.find(company => company.companyId === Number.parseInt(meeting.companyId))
                : {};

            //console.log(company)

            const initialValues = {
                notes: _.get(meeting, 'notes', ''),
                rating: _.get(meeting, 'rating', ''),
                feedback: _.get(meeting, 'feedback', ''),
                meeting,
                company
            };

            return {
                meetingId,
                meeting,
                company,
                initialValues,
                descriptor
            };
        }
    );
    return (state, props) => getState(state, props);
};

const mapDispatchToProps = dispatch => ({
    getCompany: companyId => dispatch(apiCompanyOffline(companyId, true)),
    saveCommunication: meeting => dispatch(apiMeetingPost(meeting)),
    addOpportunity: companyId => dispatch(createOpportunity(companyId))
});

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(MeetingPage);
