import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { REST_TIME_FORMAT } from '../../../actions/Api/apiHelpers';
import { FORM_NAMES } from '../../../actions/formNames';
import ReactiveForm from '../../common/ReactiveForm';

import validation from './day.validation';
import eventDef from '../eventDef';
import { apiEventPost } from '../../../actions/Api/Event/apiEventPost';

const FORM_NAME = FORM_NAMES.EDIT_DAY;

class EditEventForm extends React.Component {
    handleSubmit = values => {
        this.props.save(values, this.props.onCancel);
    };

    emptyForm = () => {
        const {
            user: { userId, descriptor },
            event: { eventType }
        } = this.props;
        // get communications from props and find communication matching com id
        const startDate = moment()
            .hour(9)
            .minute(0)
            .seconds(0)
            .format(REST_TIME_FORMAT);

        const endDate = moment()
            .hour(17)
            .minute(0)
            .seconds(0)
            .format(REST_TIME_FORMAT);

        return {
            status: 'Pending',
            eventType,
            assigneeId: userId,
            assigneeName: descriptor,
            assignee: {
                id: userId,
                descriptor: descriptor
            },
            createdBy: userId,
            updatedBy: userId,
            startDate,
            endDate,
            notes: '',
            companyId: -1
        };
    };

    render() {
        const {
            event: { eventId },
            event,
            resources,
            onCancel
        } = this.props;

        let initialValues = event;

        if (eventId < 0) {
            initialValues = this.emptyForm();
        }

        return (
            <ReactiveForm
                initialValues={initialValues}
                cancel={onCancel}
                resources={resources}
                formName={FORM_NAME}
                validationSchema={validation}
                handleSubmit={this.handleSubmit}
            >
                <ReactiveForm.Section>
                    <ReactiveForm.Date columnDef={eventDef.startDate} />
                    <ReactiveForm.Date columnDef={eventDef.endDate} />
                    <ReactiveForm.Text columnDef={eventDef.body} />
                </ReactiveForm.Section>
            </ReactiveForm>
        );
    }
}

const mapStateToProps = state => ({
    //events: state.events,
    resources: state.resources,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    save: (event, cancel) => {
        dispatch(apiEventPost(event));
        cancel();
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditEventForm);
