import React, { useContext } from 'react';
import { CompanyContext } from '../../Company/Provider/CompanyProvider';
import AddressTableCard from './AddressTableCard';

const AddressTable = ({ breakpoint }) => {
    const {
        company: { addresses, loading }
    } = useContext(CompanyContext);
    return <AddressTableCard rows={addresses} isLoading={loading} breakpoint={breakpoint} />;
};

export default AddressTable;
