import { ENDPOINTS } from '../../../actions/Api/constants';
import { RESET_REDUCER } from '../../../actions/resetReducers';
import _ from 'lodash';
import { setPropertiesSyncing } from '../helper';

const defaultState = [];
const initialState = JSON.parse(localStorage.getItem('reduxPersist:people')) || defaultState;

const updateFromCompany = (state, action) => {
    if (!action.data.people) {
        return state;
    }
    return [...action.data.people, ...state.filter(oldPerson => action.data.companyId !== oldPerson.companyId)];
};

const updatePersonPost = (state, action) => {
    const { data } = action;
    const original = state.find(x => data.personId === x.personId);
    if (original) {
        setPropertiesSyncing(original, data);
    }
    return updatePersonInState(data, state);
};

const updatePersonInState = (person, state) => [person, ...state.filter(x => person.personId !== x.personId)];

export function people(state = initialState, action) {
    switch (action.type) {
        case RESET_REDUCER:
            return initialState;
        case ENDPOINTS.API.COMPANY:
            return updateFromCompany(state, action);
        case ENDPOINTS.API.COMPANY_OFFLINE_COMMIT:
            return updateFromCompany(state, action.payload);
        case ENDPOINTS.API.PERSON_POST_ROLLBACK:
            const rollbackTo = action.meta.unmodified;
            const person = state.find(person => person.personId === rollbackTo.personId);
            person.syncing = false;
            person.error = action.payload.message;
            return updatePersonInState(_.clone(person), state);
        case ENDPOINTS.API.PERSON_POST_COMMIT:
            const {
                meta: { modified: localPerson },
                payload: { data: remotePerson }
            } = action;
            return [
                remotePerson,
                ...state.filter(x => localPerson.personId !== x.personId && remotePerson.personId !== x.personId)
            ];
        case ENDPOINTS.API.PERSON_POST:
            return updatePersonPost(state, action);
        case ENDPOINTS.API.PERSON:
            //TODO: person just returns one person now not an array so this is old
            return state;
        default:
            return state;
    }
}
