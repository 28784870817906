import React from 'react';
import { connect } from 'react-redux';
import MapArray from '../../common/MapArray';
import { FieldValue } from '../../common/DisplayField/FieldValue';
import { communicationSelected } from '../../../actions/Navigation/Communication/communicationSelected';
import { openMeeting } from '../../../actions/Navigation/Meeting/meetingSelected';
import { editVisitAction } from '../../../actions/Navigation/VisitAction/editVisitAction';
import AgendaDay from '../Components/AgendaDay';
import moment from 'moment';

const mapDay = (day, index, { ...data }) => ({
    day: day,
    first: index === 0,
    ...data
});

const UnfilteredAgendaCard = ({
    agenda,
    updateCommunication,
    editVisitAction,
    openMeeting,
    choiceList,
    showDayName
}) => {
    const now = moment().startOf('day');
    const days = new Array(7).fill().map((x, index) =>
        now
            .clone()
            .add(index, 'd')
            .format('DD/MM/YYYY')
    );

    return days.length === 0 ? (
        <FieldValue>No Events</FieldValue>
    ) : (
        <MapArray
            from={days}
            map={mapDay}
            data={{
                agenda,
                updateCommunication,
                editVisitAction,
                openMeeting,
                choiceList,
                showDayName
            }}
            mapKey={day => day}
        >
            <AgendaDay />
        </MapArray>
    );
};

const mapDispatchToProps = dispatch => ({
    updateCommunication: communication => dispatch(communicationSelected(communication)),
    editVisitAction: visitAction => dispatch(editVisitAction(visitAction)),
    openMeeting: ({ meetingId, descriptor }) => dispatch(openMeeting(meetingId, descriptor))
});

export default connect(
    undefined,
    mapDispatchToProps
)(UnfilteredAgendaCard);
