import { toastr } from 'react-redux-toastr';
import { isLoading } from '../Loading/isLoading';
import { isError } from '../Loading/isError';

export const DASHBOARD_GET_PAGE = 'DASHBOARD_GET_PAGE';

export const dashboardGetPage = page => async dispatch => {
    const type = DASHBOARD_GET_PAGE;
    isLoading(dispatch, type, true, {});
    try {
        const file = process.env.REACT_APP_DASHBOARD;
        const data = require(`./pages/${page}/${file}`);
        dispatch({ type, data });
        isLoading(dispatch, type, false, {});
        return true;
    } catch (error) {
        isError(dispatch, type, true, error.message, {});
        toastr.error(type, `Response ${error.message}`);
        return false;
    }
};
