import { ENDPOINTS } from '../../../actions/Api/constants';
import _ from 'lodash';

// Is only ever called with a companyId
export default (state = [], { type, data, companyId }) => {
    switch (type) {
        case ENDPOINTS.API.COMPANY_DEMOS_GET:
            return [...state.filter(x => _.get(x, 'companyId') !== companyId), ...data];
        default:
            return state;
    }
};
