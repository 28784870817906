import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { viewCompany } from '../../../actions/Navigation/viewCompany';
import ChoiceListFilteredTableNoDashboardData from '../../common/FilteredTable/ChoiceListFilteredTableNoDashboardData';
import companySummaryDef from '../companySummaryDef';
import { mapToRows } from '../../common/Table/addColumnsToRow';
import { TableDefinition } from '../../common/FilteredTable/tableAndFilterHelpers';

class CompaniesTable extends React.Component {
    static defaultProps = {
        breakpoint: 500,
        itemsPerPage: 5,
        noItemsText: 'There are no demos',
        columns: [companySummaryDef.descriptor, companySummaryDef.postcode, companySummaryDef.ucn]
    };

    render() {
        const { rows, breakpoint, isLoading, itemsPerPage, openCompany, resources, columns } = this.props;

        const tableDefinition = new TableDefinition(columns);

        return (
            <ChoiceListFilteredTableNoDashboardData
                id="CompanyTable"
                noItemsText="Nothing found"
                tableIsNotInCard={true}
                showFilter={true}
                itemsPerPage={itemsPerPage}
                breakpoint={breakpoint}
                cardButtons={[]}
                isLoading={isLoading}
                loadingText={'Loading Companies'}
                tableDefinition={tableDefinition}
                rows={mapToRows(rows, companySummaryDef.id, resources, tableDefinition.columns, openCompany, undefined)}
            />
        );
    }
}

const mapStateToProps = state => ({ resources: state.resources });

const mapDispatchToProps = dispatch => ({
    openCompany: ({ companyId, descriptor }) => dispatch(viewCompany(companyId, descriptor))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompaniesTable);
