export const schema = () => ({
    meeting: {
        createName: 'Create Meeting',
        updateName: 'Update Meeting',
        sections: [
            {
                name: '',
                fields: [
                    {
                        label: 'Event Type',
                        type: 'choiceList',
                        multiple: false,
                        field: 'eventType',
                        choiceList: 'communicationEventType',
                        emptyOption: 'Select an Event Type'
                    }
                ]
            }
        ]
    },
    fleetProfile: [
        {
            fields: [
                {
                    label: 'Total Fleet Size',
                    field: 'fleetSize',
                    type: 'sum',
                    fields: ['numberOfCars', 'numberOfCommercials']
                },
                {
                    label: 'Number of Cars',
                    type: 'number',
                    field: 'numberOfCars'
                },
                {
                    label: 'Number of Commercials',
                    type: 'number',
                    field: 'numberOfCommercials'
                },
                {
                    label: 'Number of Kia Cars',
                    type: 'number',
                    field: 'numberOfBrand'
                },
                {
                    label: 'Number of User Chooser',
                    type: 'number',
                    field: 'numberOfUserChooser'
                },
                {
                    label: 'Number of Restricted',
                    type: 'number',
                    field: 'numberOfRestricted'
                },
                {
                    label: 'Number of Autocratic',
                    type: 'number',
                    field: 'numberOfAutocratic'
                },
                {
                    label: 'Number of Salary Sacrifice',
                    type: 'number',
                    field: 'numberOfSalarySacrifice'
                },
                {
                    label: 'Primary Vehicle Use',
                    type: 'choiceList',
                    multiple: true,
                    field: 'primaryVehicleUse',
                    choiceList: 'fleetprofilePrimaryVehicleUse'
                }
            ]
        },
        {
            fields: [
                {
                    label: 'Kia Future Interest',
                    type: 'choiceList',
                    multiple: true,
                    field: 'futureInterest',
                    choiceList: 'fleetprofileFutureInterest'
                },
                {
                    label: 'Purchase Method',
                    type: 'choiceList',
                    multiple: true,
                    field: 'fundingMethod',
                    choiceList: 'fleetprofileFundingMethod'
                },
                {
                    label: 'Change Cycle (Months)',
                    type: 'choiceList',
                    multiple: true,
                    field: 'changeCycleMonths',
                    choiceList: 'fleetprofileChangeCycleMonths'
                },
                {
                    label: 'Change Cycle (Miles)',
                    type: 'choiceList',
                    multiple: true,
                    field: 'changeCycleMiles',
                    choiceList: 'fleetprofileChangeCycleMiles'
                },
                {
                    label: 'Fuel Policy',
                    type: 'choiceList',
                    multiple: true,
                    field: 'fuelPolicy',
                    choiceList: 'fleetprofileFuelPolicy'
                },
                {
                    label: 'CO₂ Cap',
                    type: 'choiceList',
                    multiple: false,
                    field: 'co2Cap',
                    choiceList: 'fleetprofileCo2Cap'
                },
                {
                    label: 'Other Manufacturers on Fleet',
                    type: 'choiceList',
                    multiple: true,
                    field: 'otherManufacturers',
                    choiceList: 'fleetprofileOtherManufacturers'
                },
                {
                    label: 'Current Funders',
                    type: 'choiceList',
                    multiple: true,
                    field: 'currentFunder',
                    choiceList: 'fleetprofileCurrentFunder'
                }
            ]
        }
    ]
});
