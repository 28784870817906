import React, { useContext, memo } from 'react';
import { CompanyContext } from '../../Company/Provider/CompanyProvider';

import { DashboardFieldColumnDef } from '../../Dashboard/Components/DashboardField';
import SimpleCard from '../../common/Card/SimpleCard';
import companyDef from '../companyDef';

const CompanyDetails = ({ hideCompanyName }) => {
    const { company = {} } = useContext(CompanyContext);
    const isLoading = company.loading;

    return <CompanyDetailsCard company={company} isLoading={isLoading} hideCompanyName={hideCompanyName} />;
};

export default CompanyDetails;

const CompanyDetailsCard = memo(({ company, isLoading, hideCompanyName }) => (
    <SimpleCard title="Company Information" isLoading={isLoading}>
        {!hideCompanyName && <DashboardFieldColumnDef item={company} columnDef={companyDef.name} />}
        <DashboardFieldColumnDef item={company} columnDef={companyDef.businessPhone} />
        <DashboardFieldColumnDef item={company} columnDef={companyDef.webSite} />
        <DashboardFieldColumnDef item={company} columnDef={companyDef.completedMeetings} />
        <DashboardFieldColumnDef item={company} columnDef={companyDef.lastMeetingDate} />
        <DashboardFieldColumnDef item={company} columnDef={companyDef.registrationsInLast12Months} />
        <DashboardFieldColumnDef item={company} columnDef={companyDef.demosInLast12Months} />
        <DashboardFieldColumnDef item={company} columnDef={companyDef.totalRegistrations} />
        <DashboardFieldColumnDef item={company} columnDef={companyDef.totalDemos} />
    </SimpleCard>
));
