import React from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ENDPOINTS } from '../../../actions/Api/constants';

export const CompanyTermsContext = React.createContext({
    companyId: undefined,
    terms: [],
    isLoading: false
});

const CompanyTermsProvider = ({ companyId, isLoading, terms, children }) => {
    return (
        <CompanyTermsContext.Provider value={{ companyId, terms, isLoading }}>{children}</CompanyTermsContext.Provider>
    );
};

const makeMapStateToProps = () => {
    const getState = createSelector(
        [
            state => _.get(state.loadingStates, `${ENDPOINTS.API.COMPANY}.isLoading`, false),
            (state, { companyId }) => state.terms.filter(term => term.companyId === companyId)
        ],
        (isLoading, terms) => ({
            isLoading,
            terms
        })
    );
    return (state, props) => getState(state, props);
};

export default connect(makeMapStateToProps)(CompanyTermsProvider);
