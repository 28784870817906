import { basicColumnDef } from '../common/FilteredTable/tableAndFilterHelpers';

const companyDef = {
    name: {
        ...basicColumnDef('Company Name', 'name'),
        type: 'companyName'
    },
    businessPhone: {
        ...basicColumnDef('Company Telephone Number', 'businessPhone'),
        type: 'phone'
    },
    webSite: {
        ...basicColumnDef('Company Website', 'webSite'),
        type: 'link'
    },
    completedMeetings: {
        ...basicColumnDef('Number of Completed Meetings', 'completedMeetings'),
        type: 'text'
    },
    lastMeetingDate: {
        ...basicColumnDef('Last Completed Meeting Date', 'lastMeetingDate'),
        type: 'date',
        showTime: false
    },
    registrationsInLast12Months: {
        ...basicColumnDef('Total Registrations Over 12 Months', 'registrationsInLast12Months'),
        type: 'text'
    },
    totalRegistrations: {
        ...basicColumnDef('Total Number of Registrations', 'totalRegistrations'),
        type: 'text'
    },
    demosInLast12Months: {
        ...basicColumnDef('Number of Demos Over 12 Months', 'demosInLast12Months'),
        type: 'text'
    },
    totalDemos: {
        ...basicColumnDef('Total Number of Demos', 'totalDemos'),
        type: 'text'
    },
    descriptor: {
        ...basicColumnDef('Company', 'descriptor')
    },
    postcode: {
        ...basicColumnDef('Postcode', 'postcode')
    },
    ucn: {
        ...basicColumnDef('UCN Number', 'ucn')
    }
};

export default companyDef;
