//eslint-disable jsx-a11y/anchor-is-valid

import React from 'react';
import { PreventClickThrough } from './PreventClickThrough';
import { Link } from 'react-router-dom';

export const FormatCompanyNameLink = ({ values }) => {
    return (
        <span className="d-block text-truncate" onClick={event => PreventClickThrough(event)}>
            <Link to={`/company/${values.company.id}/${values.company.descriptor}`}>{values.company.descriptor}</Link>
        </span>
    );
};

const ColumnTypeFormatCompanyNameOneLine = ({ values, className }) => {
    return (
        values.company &&
        values.company.descriptor && (
            <span className={className ? className : ''} onClick={event => PreventClickThrough(event)}>
                <Link to={`/company/${values.company.id}/${values.company.descriptor}`}>
                    {values.company.descriptor}
                </Link>
            </span>
        )
    );
};

export default ColumnTypeFormatCompanyNameOneLine;
