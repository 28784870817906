import axios from 'axios';
import { isLoading } from '../Loading/isLoading';
import { API_LOGIN_ENDPOINTS } from '../ApiAuth/ApiAuthEndpoints';
import { replace } from 'connected-react-router';
import { makeAuthUrl } from './apiAuthHelpers';
import { makeHeaders, makePath } from '../Api/apiHelpers';

export const apiAuthUserLogout = (emailAddress, password) => async (dispatch, getState) => {
    const type = API_LOGIN_ENDPOINTS.API.API_AUTH_USER_LOGOUT;

    isLoading(dispatch, type, true, {});
    //   try {

    //dispatch({ type, data: [] });
    const data = { emailAddress, password };
    const result = await axios({
        method: 'POST',
        url: makeAuthUrl(getState(), makePath(['auth', 'logout']), {}),
        headers: makeHeaders(getState()),
        data
    });

    dispatch(replace('/'));

    //const { data: user } = result;
    /*if (user) {
            // pass the user here in case state has not updated
            const userData = await dispatch(apiUser(user));
            dispatch(apiCommunicationFilter({ startDate: '2018-06-06', assignedTo: userData.userId }, false, user));
            dispatch(apiResource(user));
            dispatch(push('/dashboard/home'));
        }*/

    isLoading(dispatch, type, false, {});
    dispatch({ type, data: result.data });
    return result || true;
    //  } catch (error) {
    //       isError(dispatch, type, true, error.message, {});
    //       toastr.error(type, `Response ${error.message}`);
    //       return false;
    //   }
};
