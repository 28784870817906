import { basicColumnDef, dateColumnDef, idColumnDef } from '../common/FilteredTable/tableAndFilterHelpers';

const regDef = {
    id: idColumnDef('id', 'registrationId'),
    carLine: {
        ...basicColumnDef('Car Line', 'carLine')
    },
    colourCode: {
        ...basicColumnDef('Colour Code', 'colourCode')
    },
    colourDescription: {
        ...basicColumnDef('Color Description', 'colourDescription')
    },
    dealerCode: {
        ...basicColumnDef('Dealer Code', 'dealerCode')
    },
    dealerName: {
        ...basicColumnDef('Dealer Name', 'dealerName')
    },
    modelDescription: {
        ...basicColumnDef('Model Description', 'modelDescription')
    },
    paintType: {
        ...basicColumnDef('Paint Type', 'paintType')
    },
    registrationDate: {
        ...dateColumnDef('Registration Date', 'registrationDate')
    },
    registrationNumber: {
        ...basicColumnDef('Registration Number', 'registrationNumber')
    },
    salesChannel: {
        ...basicColumnDef('Sales Channel', 'salesChannel')
    },
    ucn: {
        ...basicColumnDef('UCN', 'ucn')
    },
    variantCode: {
        ...basicColumnDef('Variant Code', 'variantCode')
    },
    vehicleDescription: {
        ...basicColumnDef('Vehicle Description', 'vehicleDescription')
    },
    vin: {
        ...basicColumnDef('VIN', 'vin')
    }
};

export default regDef;
