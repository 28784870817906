import React from 'react';
import { connect } from 'react-redux';
import { FormFeedback } from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import _ from 'lodash';
import { getChoiceList } from '../LabelText';
import FormikFormGroup from './FormikFormGroup';
import { DisplayField } from '../DisplayField/index';
import { Alert } from 'reactstrap';

class FormikSelectChoicelist extends React.Component {
    render() {
        const {
            choiceList,
            filter,
            filterOnFormValues,
            resources,
            mapChoiceValues,
            values,
            formName,
            fieldName,
            setFieldValue,
            onFormValueChanged,
            isMulti,
            title,
            children,
            hideLabel,
            touched,
            options,
            errors,
            emptyOption,
            disabled,
            noOptionsText,
            isOptionDisabled
        } = this.props;

        let useOptions = (options || getChoiceList(resources, choiceList, mapChoiceValues)).map(
            ({ key, value, metadata }) => ({
                value: key,
                label: value,
                metadata
            })
        );

        if (filter) {
            useOptions = useOptions.filter(filter);
        } else if (filterOnFormValues) {
            useOptions = useOptions.filter(x => filterOnFormValues(x, { values }));
        }

        if (noOptionsText && options.length === 0) {
            return <DisplayField title={title} value={<Alert color="warning">{noOptionsText}</Alert>} />;
        }

        const invalid = touched[fieldName] && errors[fieldName];
        const rawValue = _.get(values, fieldName, '');

        return (
            <FormikFormGroup fieldName={fieldName} formName={formName} title={title} hideLabel={hideLabel}>
                <Select
                    className="react-select"
                    isMulti={isMulti}
                    options={useOptions}
                    styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }}
                    onChange={a => {
                        const value = isMulti ? a.map(x => x.value).join(',') : a.value;
                        setFieldValue(fieldName, value);
                        if (onFormValueChanged) {
                            onFormValueChanged(values, fieldName, value);
                        }
                    }}
                    components={makeAnimated()}
                    isDisabled={disabled}
                    value={
                        isMulti
                            ? rawValue
                                ? rawValue.split(',').map(value => useOptions.find(x => x.value === value))
                                : []
                            : useOptions.find(x => x.value === rawValue)
                    }
                    placeholder={emptyOption}
                    isOptionDisabled={isOptionDisabled}
                />
                {invalid && (
                    <FormFeedback tooltip className={invalid ? 'd-block' : ''}>
                        {errors[fieldName]}
                    </FormFeedback>
                )}
                {children}
            </FormikFormGroup>
        );
    }
}

const mapStateToProps = state => ({
    resources: state.resources
});

const mapDispatchToProps = dispatch => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormikSelectChoicelist);
