import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import FormikLabel from './FormikLabel';

const FormikFormGroup = props => {
    const { formName, fieldName, children, title, hideLabel } = props;
    return (
        <FormGroup>
            {hideLabel ? null : <FormikLabel fieldName={fieldName} formName={formName} title={title} />}
            {children}
        </FormGroup>
    );
};

FormikFormGroup.propTypes = {
    formName: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired
};

export default FormikFormGroup;
