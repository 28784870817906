import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { apiAddressByPostcode } from '../../../actions/Api/Address/apiAddressByPostcode';
import { ENDPOINTS } from '../../../actions/Api/constants';
import FormikText from './FormikText';
import { Button, InputGroupAddon } from 'reactstrap';
import AddressSearchResultTable from '../../Address/Tables/AddressSearchResultTable';

class FormikAddressSearch extends React.Component {
    state = { allowAddressFieldsUpdate: true, noResults: false };
    updateField = (addressResultFieldName, addressResult) => {
        const { updatesFields, formikProps } = this.props;
        if (
            updatesFields[addressResultFieldName] &&
            this.props.formikProps.values[updatesFields[addressResultFieldName]] !==
                addressResult[addressResultFieldName]
        ) {
            formikProps.setFieldValue(updatesFields[addressResultFieldName], addressResult[addressResultFieldName]);
        }
    };

    componentWillReceiveProps(nextProps) {
        const {
            fieldName,
            formikProps: { values }
        } = this.props;

        let results = _.get(nextProps, 'addresses.data.summaries');

        if (results) {
            results.length === 0 ? this.setState({ noResults: true }) : this.setState({ noResults: false });
        }

        let resultPostcode = _.get(nextProps, 'addresses.address.postCode');
        let searchPostcode = values[fieldName];

        if (searchPostcode.length === 0) {
            this.setState({ noResults: false });
        }

        if (resultPostcode !== undefined) {
            resultPostcode = resultPostcode.replace(/\s+/g, '').toUpperCase();
        }

        if (searchPostcode !== undefined) {
            searchPostcode = searchPostcode.replace(/\s+/g, '').toUpperCase();
        }

        if (resultPostcode === searchPostcode && this.state.allowAddressFieldsUpdate) {
            const {
                addresses: { address }
            } = nextProps;
            this.setState({ allowAddressFieldsUpdate: false });
            this.updateField('address1', address);
            this.updateField('address2', address);
            this.updateField('address3', address);
            this.updateField('address4', address);
            this.updateField('city', address);
            this.updateField('county', address);
        }
    }

    handleClick = () => {
        this.setState({ allowAddressFieldsUpdate: true });
        const {
            fieldName,
            formikProps: { values },
            postCodeSearch
        } = this.props;

        const postcode = values[fieldName];
        if (postcode) {
            postCodeSearch(postcode);
        }
    };

    render() {
        const { title, fieldName, formikProps, addresses, isLoading } = this.props;

        return (
            <React.Fragment>
                <FormikText
                    type="text"
                    title={title}
                    fieldName={fieldName}
                    {...formikProps}
                    inputGroupAddons={
                        <InputGroupAddon addonType="append">
                            <Button
                                type="button"
                                color="info"
                                onClick={this.handleClick}
                                disabled={!formikProps.values[fieldName]}
                            >
                                Search
                            </Button>
                        </InputGroupAddon>
                    }
                />

                <AddressSearchResultTable
                    addresses={addresses.data ? addresses.data.summaries : ''}
                    isLoading={isLoading}
                    noResults={this.state.noResults}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    isLoading: state.loadingStates[ENDPOINTS.API.ADDRESS] && state.loadingStates[ENDPOINTS.API.ADDRESS].isLoading,
    addresses: state.address
});

const mapDispatchToProps = dispatch => ({
    postCodeSearch: postCode => dispatch(apiAddressByPostcode(postCode))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormikAddressSearch);
