import React from 'react';
import PropTypes from 'prop-types';
import { FormFeedback } from 'reactstrap';
import FormikFormGroup from './FormikFormGroup';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { REST_TIME_FORMAT } from '../../../actions/Api/apiHelpers';

class FormikDate extends React.Component {
    constructor(props) {
        super(props);
        this.pickerRef = null;
    }

    OnOutsideClick = () => {
        this.pickerRef.cancelFocusInput();
    };

    render = () => {
        const {
            children,
            values,
            formName,
            fieldName,
            setFieldValue,
            title,
            errors,
            touched,
            showTimeSelect,
            onChange
        } = this.props;
        const invalid = touched[fieldName] && errors[fieldName];

        const dateFormat = showTimeSelect
            ? `${process.env.REACT_APP_DATE_FORMAT} H:mm`
            : process.env.REACT_APP_DATE_FORMAT;

        return (
            <FormikFormGroup fieldName={fieldName} formName={formName} title={title}>
                <DatePicker
                    value={moment(values[fieldName]) && moment(values[fieldName]).format(dateFormat)}
                    selected={moment(values[fieldName])}
                    showTimeSelect={showTimeSelect}
                    timeFormat="H:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    ref={node => {
                        this.pickerRef = node;
                    }}
                    onClickOutside={this.OnOutsideClick}
                    minTime={moment()
                        .hours(9)
                        .minutes(0)}
                    maxTime={moment()
                        .hours(17)
                        .minutes(30)}
                    dateFormat={dateFormat}
                    className="w-100 rounded border form-control pl-2 py-2"
                    onChange={a => {
                        setFieldValue(
                            fieldName,
                            moment(a)
                                .startOf('minute')
                                .format(REST_TIME_FORMAT)
                        );
                        if (onChange) onChange(a);
                    }}
                    onChangeRaw={() => {
                        // do nothing
                    }}
                />
                {invalid && (
                    <FormFeedback tooltip className="d-block">
                        {errors[fieldName]}
                    </FormFeedback>
                )}
                {children}
            </FormikFormGroup>
        );
    };
}

FormikDate.propTypes = {
    values: PropTypes.object.isRequired,
    formName: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    type: PropTypes.string
};

FormikDate.defaultProps = {
    type: 'text'
};

export default FormikDate;
