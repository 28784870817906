import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router';
import ChoiceListFilteredTableNoDashboardData from '../../common/FilteredTable/ChoiceListFilteredTableNoDashboardData';
import { TableDefinition } from '../../common/FilteredTable/tableAndFilterHelpers';
import { mapToRows } from '../../common/Table/addColumnsToRow';
import { editOpportunity } from '../../../actions/Navigation/Opportunity/editOpportunity';
import { viewCompany } from '../../../actions/Navigation/viewCompany';
import opportunity from '../opportunity';

class OpportunitiesTableCardNew extends React.Component {
    static defaultProps = {
        breakpoint: 500,
        itemsPerPage: 5,
        noItemsText: 'There are no opportunities',
        columns: [
            opportunity.estimatedConversionDate,
            opportunity.type,
            opportunity.stage,
            opportunity.likelihood,
            opportunity.model
        ]
    };

    render() {
        const {
            isLoading,
            rows,
            resources,
            openCompany,
            tableIsNotInCard = false,
            cardButtons = [],
            editRow,
            title,
            columns,
            ...rest
        } = this.props;

        const tableDefinition = new TableDefinition(columns);
        return (
            <ChoiceListFilteredTableNoDashboardData
                id={title}
                tableDefinition={tableDefinition}
                rows={mapToRows(rows, opportunity.id, resources, tableDefinition.columns, values => editRow(values), {
                    openCompany
                })}
                isLoading={isLoading}
                tableIsNotInCard={tableIsNotInCard}
                cardButtons={cardButtons}
                title={title}
                {...rest}
            />
        );
    }
}

const mapStateToProps = state => ({
    resources: state.resources
});

const mapDispatchToProps = dispatch => ({
    editRow: opportunity => dispatch(editOpportunity(opportunity)),
    openCompany: (companyId, descriptor) => dispatch(viewCompany(companyId, descriptor))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(OpportunitiesTableCardNew));
