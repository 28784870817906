import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import LoginPage from '../Authentication/Pages/LoginPage';
import ReduxToastr from 'react-redux-toastr';
import PageWithNavigation from './PageWithNavigation';
import ForgottenPasswordPage from '../Authentication/Pages/ForgottenPasswordPage';
import ResetPasswordPage from '../Authentication/Pages/ResetPasswordPage';
import SetupAccountPage from '../Authentication/Pages/SetupAccountPage';
import Page404 from '../Authentication/Pages/Page404';
import SelectDealershipPage from '../Authentication/Pages/SelectDealershipPage';
import Modals from './Modals';
import { apiAuthUserLogout } from '../../actions/ApiAuth/apiAuthUserLogout';
import appSettings from '../../config/appSettings';
import RequestSupportPage from '../Support/Pages/RequestSupportPage';
import CalendarPage from './Pages/CalendarPage';
import PeoplePage from './Pages/PeoplePage';
import AddressesPage from './Pages/AddressesPage';
import SendVisitReportEmailPage from './Pages/SendVisitReportEmailPage';
import MeetingPage from './Pages/MeetingPage';
import CompanyPage from './Pages/CompanyPage';
import HomePage from './Pages/HomePage';
import CompaniesPage from './Pages/CompaniesPage';
import { Switch } from 'react-router';
import VersionsPage from './Pages/VersionsPage';

export const LANDING_PAGES = [
    { name: 'Companies', path: '/companies/' },
    {
        name: 'Agreements',
        path: '/agreements/'
    } /*{ name: 'ToDo', path: '/todo/' }*/
];

function updateInteraction() {
    lastInteraction = new Date();
}

function isLogOutTime(timeout) {
    const timeSinceLastInteraction = new Date() - lastInteraction;
    return timeSinceLastInteraction > timeout;
}

let lastInteraction;

class Example extends React.Component {
    componentDidMount = () => {
        try {
            updateInteraction();
            document.addEventListener('click', updateInteraction);
            document.addEventListener('keydown', updateInteraction);

            setInterval(async () => {
                if (this.props.user.userId > -1 && isLogOutTime(appSettings.timeout)) {
                    console.log('Logging out...');
                    this.props.logout();
                }
            }, 30000);
        } catch (e) {
            console.log(e);
        }

        window.addEventListener('beforeunload', this.onUnload);
    };

    componentWillUnmount = () => window.removeEventListener('beforeunload', this.onUnload);

    onUnload(e) {
        if (process.env.REACT_APP_NO_OFFLINE === 'true') localStorage.clear();
    }

    render = () => [
        <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position="bottom-center"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            key="toastr"
        />,
        <section key="index-section">
            {
                //TODO: Only add these if modal is open. They are all being rendered
            }
            <Modals />
            <Route exact path="/" component={LoginPage} />
            <Route exact path="/forgotpassword/" component={ForgottenPasswordPage} />
            <Route exact path="/selectdealership/" component={SelectDealershipPage} />
            <Route exact path="/resetpassword/:resetToken" component={ResetPasswordPage} />
            <Route exact path="/setupaccount/:resetToken" component={SetupAccountPage} />
            <Route
                path="/:id"
                render={() => (
                    <PageWithNavigation>
                        <Switch>
                            <Route exact path="/dashboard/home" component={HomePage} />
                            <Route exact path="/dashboard/companies" component={CompaniesPage} />
                            <Route exact path="/calendar/:view/:year/:month/:day" component={CalendarPage} />
                            <Route exact path="/company/:companyId/people" component={PeoplePage} />
                            <Route exact path="/company/address/:companyId" component={AddressesPage} />
                            <Route exact path="/communication/email/:meetingId" component={SendVisitReportEmailPage} />
                            <Route path="/meeting/view/:communicationId/:descriptor" component={MeetingPage} />
                            <Route path="/company/:companyId/:companyName" component={CompanyPage} />
                            <Route exact path="/forgotpassword/" />
                            <Route exact path="/resetpassword/:resetToken" />
                            <Route exact path="/setupaccount/:resetToken" />
                            <Route exact path="/selectdealership/" />
                            <Route exact path="/versions/" component={VersionsPage} />
                            <Route exact path="/support/request/" component={RequestSupportPage} />
                            <Route component={Page404} />
                        </Switch>
                    </PageWithNavigation>
                )}
            />
        </section>
    ];
}

const mapStateToProps = state => ({
    user: state.user,
    active: state.active,
    restState: state.restState,
    page: state.page
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(apiAuthUserLogout())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Example);
