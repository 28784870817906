import React from 'react';
import PropTypes from 'prop-types';
import { FormFeedback, InputGroup, Input, InputGroupAddon, InputGroupText } from 'reactstrap';
import FormikFormGroup from './FormikFormGroup';

const FormikText = props => {
    const {
        values,
        formName,
        fieldName,
        handleChange,
        handleBlur,
        type,
        errors,
        touched,
        hideLabel,
        placeholder,
        disabled,
        inputGroupAddons,
        inputClassName,
        children,
        title,
        min,
        max,
        prefix
    } = props;
    const invalid = touched[fieldName] && errors[fieldName];
    return (
        <FormikFormGroup fieldName={fieldName} formName={formName} hideLabel={hideLabel} title={title}>
            <InputGroup>
                {prefix && (
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>{prefix}</InputGroupText>
                    </InputGroupAddon>
                )}
                <Input
                    type={type}
                    invalid={invalid ? true : false}
                    name={fieldName}
                    onChange={handleChange}
                    min={min}
                    max={max}
                    onBlur={handleBlur}
                    value={values[fieldName] ? values[fieldName] : ''} // is this ok to set it to empty stirng //index.js:2177 Warning: `value` prop on `input` should not be null. Consider using an empty string to clear the component or `undefined` for uncontrolled components.
                    placeholder={placeholder}
                    disabled={disabled}
                    className={inputClassName || type !== 'number' ? 'py-4' : 'form-control-lg'}
                />
                {inputGroupAddons}
                {invalid && <FormFeedback tooltip>{errors[fieldName]}</FormFeedback>}
            </InputGroup>
            {children}
        </FormikFormGroup>
    );
};

FormikText.propTypes = {
    values: PropTypes.object.isRequired,
    formName: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    type: PropTypes.string
};

FormikText.defaultProps = {
    type: 'text'
};

export default FormikText;
