import axios from 'axios';
import moment from 'moment';
import { isLoading } from '../Loading/isLoading';
import { isError } from '../Loading/isError';
import { toastr } from 'react-redux-toastr';
import { API_LOGIN_ENDPOINTS } from '../ApiAuth/ApiAuthEndpoints';
import { push } from 'connected-react-router';
import { makeAuthUrl } from './apiAuthHelpers';
import { makeHeaders, makePath } from '../Api/apiHelpers';
import { cookieSetExpires, cookieSetToken, cookieSetUserId } from '../../reducers/cookies';
import { apiUserLoggedIn } from './userLoggedIn';
import { apiDealershipUsers } from '../Api/Dealership/apiDealershipUsers';
import _ from 'lodash';

export const apiUserLogin = (emailAddress, password) => async (dispatch, getState) => {
    const type = API_LOGIN_ENDPOINTS.API.API_AUTH_USER_LOGIN;

    isLoading(dispatch, type, true, {});
    try {
        const data = { emailAddress, password };
        const authResult = await axios({
            method: 'POST',
            url: makeAuthUrl(getState(), makePath(['auth', 'token']), {}),
            headers: { 'Content-Type': 'application/json' },
            data
        });

        const { data: auth } = authResult;
        cookieSetToken(auth.token);
        cookieSetExpires(auth.expires);

        if (auth) {
            // pass the user here in case state has not updated
            //TODO: Test why not the following - Should not have endpoints in more than 1 place.
            //const userData = await dispatch(apiUser({...auth}));
            const userResult = await axios({
                method: 'GET',
                url: makeAuthUrl(getState(), makePath(['user']), {}), //TODO://make the adding path params a function
                headers: makeHeaders()
            });

            const user = { ...userResult.data };

            const { loginAttempts } = user;

            // sort login attempts by most recent
            const sortedAttempts = loginAttempts.sort((a, b) => {
                const aTime = new Date(a.timestamp).getTime();
                const bTime = new Date(b.timestamp).getTime();
                return aTime < bTime ? 1 : -1;
            });

            const [, ...pastAttempts] = sortedAttempts;

            if (pastAttempts.length > 0) {
                var mostRecentSuccess = pastAttempts.find(x => x.successful);
                var oldestLoginAttempt = pastAttempts[pastAttempts.length - 1];

                if (mostRecentSuccess) {
                    toastr.info(`Your last successful login was ${moment(mostRecentSuccess.timestamp).fromNow()}`);
                } else {
                    toastr.info(
                        `Your last successful login was more than ${moment(oldestLoginAttempt.timestamp).fromNow()}`
                    );
                }
            }

            dispatch({ type, data: user });
            cookieSetUserId(user.userId);

            if (user.hasOwnProperty('dealerships') && user.dealerships.length > 1) {
                dispatch(push('/selectdealership'));
            } else if (user.hasOwnProperty('dealerships') && user.dealerships.length === 1) {
                dispatch(apiDealershipUsers(user.dealerships[0].userId, user.dealerships[0].descriptor));
            } else {
                dispatch(apiUserLoggedIn());
            }

            dispatch({ type, data: user });
        }

        isLoading(dispatch, type, false, {});
        return authResult || true;
    } catch (error) {
        const responseStatus = _.get(error, 'response.status', -1);

        if (responseStatus === 403) {
            toastr.error('Your account has been locked out.', 'Please try again in one hour.');
        } else if (responseStatus === 401) {
            toastr.error(
                'Invalid username and/or password.',
                'Your account will be locked after 5 consecutive unsuccessful attempts.'
            );
        } else {
            toastr.error(type, `Response ${error.message}`);
        }

        isError(dispatch, type, true, error.message, {});
        return false;
    }
};
