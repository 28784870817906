import React from 'react';
import ExpiredTokenModal from './ExpiredTokenModal';
import EditVisitActionModal from '../VisitActions/Modals/EditVisitActionModal';
import EditCommunicationModal from '../Communication/Modals/EditCommunicationModal';
import EditMeetingModal from '../Meeting/Modals/EditMeetingModal';
import CreateLeadModal from '../Lead/Modals/CreateLeadModal';
import CancelCommunicationModal from '../Communication/Modals/CancelCommunicationModal';
import CompleteMeetingModal from '../Meeting/Modals/CompleteMeetingModal';
import EditOpportunityModal from '../Opportunity/Modal/EditOpportuntiyModal';

const Modals = () => {
    return (
        <React.Fragment>
            <ExpiredTokenModal />
            <EditVisitActionModal />
            <EditCommunicationModal />
            <EditMeetingModal />
            <CancelCommunicationModal />
            <CompleteMeetingModal />
            <EditOpportunityModal />
            <CreateLeadModal />
        </React.Fragment>
    );
};

export default Modals;
