import React, { useContext } from 'react';
import { connect } from 'react-redux';

import { createCommunication } from '../../../actions/Navigation/Communication/createCommunication';
import { createOpportunity } from '../../../actions/Navigation/Opportunity/createOpportunity';
import { createMeeting } from '../../../actions/Navigation/Communication/createMeeting';
import ActionButtons from '../../common/ActionButtons/ActionButtons';
import ActionButton from '../../common/ActionButtons/ActionButton';
import { CompanyContext } from '../Provider/CompanyProvider';

function CompanyActions({ createCommunication, addOpportunity, createMeeting }) {
    const { companyId } = useContext(CompanyContext);
    return (
        <ActionButtons>
            <ActionButton
                buttonText="Add Meeting"
                fontawesomeIcon="fa-plus"
                bootstrapColor="secondary"
                onClickAction={() => createMeeting(companyId)}
            />
            <ActionButton
                buttonText="Add Communication"
                fontawesomeIcon="fa-plus"
                bootstrapColor="secondary"
                onClickAction={() => createCommunication(companyId, 'communication')}
            />
            <ActionButton
                buttonText="Add Opportunity"
                fontawesomeIcon="fa-plus"
                bootstrapColor="secondary"
                onClickAction={() => addOpportunity(companyId)}
            />
        </ActionButtons>
    );
}

const mapStateToProps = state => ({
    //user: state.user
});

const mapDispatchToProps = dispatch => ({
    createCommunication: companyId => dispatch(createCommunication(companyId)),
    createMeeting: companyId => dispatch(createMeeting(companyId)),
    addOpportunity: companyId => dispatch(createOpportunity(companyId))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyActions);
