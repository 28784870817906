import { DASHBOARD_GET } from '../../actions/dashboard/dashboard';
import { DASHBOARD_GET_PAGE } from '../../actions/dashboard/dashboardGetPage';

export function dashboard(state = {}, action) {
    switch (action.type) {
        case DASHBOARD_GET:
            if (!action.data.forms) {
                action.data.forms = { _: '' };
            }
            return action.data;

        case DASHBOARD_GET_PAGE:
            return {
                ...state,
                ...action.data
            };

        default:
            return state;
    }
}
