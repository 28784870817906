import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import FormikText from '../FormkControls/FormikText';
import EditFieldDisplayValue from './EditFieldDisplayValue';
import SmallSaveCancelButtons from '../InlineEdit/SmallSaveCancelButtons';

class EditTextField extends PureComponent {
    static propTypes = {
        fontawesome: PropTypes.string,
        id: PropTypes.PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        title: PropTypes.string.isRequired,
        value: PropTypes.PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        field: PropTypes.string.isRequired,
        onChanged: PropTypes.func.isRequired,
        isSyncing: PropTypes.bool,
        isError: PropTypes.string
    };

    static defaultProps = {
        fontawesome: 'fa-pencil'
    };

    state = { isEditing: false };

    renderEditContents() {
        const { type, field, value, title } = this.props;
        return (
            <Formik
                initialValues={{ [field]: value }}
                onSubmit={this.props.onChanged}
                render={formikProps => {
                    formikProps.formName = 'fleetProfile';
                    return (
                        <form
                            className="ml-2"
                            onSubmit={e => {
                                this.setState({ isEditing: false });
                                formikProps.handleSubmit(e);
                            }}
                        >
                            <FormikText
                                type={type}
                                fieldName={field}
                                {...formikProps}
                                inputClassName="none"
                                title={title}
                            >
                                <SmallSaveCancelButtons
                                    showSave={formikProps.initialValues.toString() !== formikProps.values.value}
                                    showCancel={true}
                                    onCancel={event => {
                                        this.setState({ isEditing: false });
                                        formikProps.handleReset(event);
                                    }}
                                />
                            </FormikText>
                        </form>
                    );
                }}
            />
        );
    }

    renderNotEditing(formId) {
        const { primary, fontawesome, type, field, value, title, id, isSyncing, isError } = this.props;

        const form = {
            formId,
            id,
            field,
            title,
            value
        };

        form[field] = value;
        form.type = type;
        return (
            <EditFieldDisplayValue
                onClick={() => this.setState({ isEditing: true })}
                isSyncing={isSyncing}
                isError={isError}
                fontawesome={fontawesome}
                value={value}
                title={title}
                primary={primary}
                show={this.state.isEditing}
            />
        );
    }

    render() {
        const { id, field } = this.props;
        return this.state.isEditing ? this.renderEditContents() : this.renderNotEditing(`${id}-${field}`);
    }
}

const mapStateToProps = state => ({
    editFieldForm: state.editFieldForm
});

export default connect(mapStateToProps)(EditTextField);
