// import { ENDPOINTS } from '../constants';
// import { apiCompany } from '../Company/apiCompany';
// import { apiPerson } from '../Person/apiPerson';
// import { apiGet } from '../apiHelpers';

export const apiCommunicationGet = communicationId => async dispatch => {
    // dispatch(apiGet(ENDPOINTS.API.COMMUNICATION_GET, ['communication', communicationId], {}, onResult));
};

// const onResult = result => async dispatch => {
// 	const { companyId, personId } = result.data;
// 	dispatch(apiCompany(companyId));
// 	if (personId) {
// 		dispatch(apiPerson(personId));
// 	}
// };
