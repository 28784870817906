import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router';
import { getLoadingState } from '../../../reducers/Rest/loadingStates';
import ChoiceListFilteredTableNoDashboardData from '../../common/FilteredTable/ChoiceListFilteredTableNoDashboardData';
import { ENDPOINTS } from '../../../actions/Api/constants';
import {
    CREATE_COMMUNICATION_TYPE_COMMUNICATION,
    createCommunication
} from '../../../actions/Navigation/Communication/createCommunication';
import { TableDefinition } from '../../common/FilteredTable/tableAndFilterHelpers';
import communication from '../communication';
import { mapToRows } from '../../common/Table/addColumnsToRow';

class CommunicationsTableCard extends React.Component {
    static defaultProps = {
        breakpoint: 500,
        itemsPerPage: 5,
        noItemsText: 'There are no communications to show',
        columns: [
            communication.startDate,
            communication.activityRegarding,
            communication.assigneeId,
            communication.status,
            communication.body
        ]
    };

    renderButtons = () => {
        const { buttons, cardButtons, createCommunication, companyId } = this.props;
        const items = cardButtons || [];

        if (companyId) {
            if (buttons && buttons.includes('addCommunication')) {
                items.push({
                    text: 'Add Communication',
                    fontAwesomeName: 'fa-plus',
                    onClick: () => createCommunication(companyId)
                });
            }
        }

        return items;
    };

    render() {
        const { loadingStates, rows, resources, openCompany, editRow, title, columns, ...rest } = this.props;
        const isLoading = getLoadingState(loadingStates, ENDPOINTS.API.VISIT_ACTIONS_FILTER_COMMIT).isLoading;

        const tableDefinition = new TableDefinition(columns);

        const tableRows = mapToRows(
            rows,
            communication.id,
            resources,
            tableDefinition.columns,
            values => editRow(values),
            {
                openCompany
            }
        );

        return (
            <ChoiceListFilteredTableNoDashboardData
                id={title}
                tableDefinition={tableDefinition}
                rows={tableRows}
                isLoading={isLoading}
                showInCard={true}
                cardButtons={this.renderButtons()}
                title={title}
                {...rest}
            />
        );
    }
}

const mapStateToProps = state => ({
    loadingStates: state.loadingStates,
    resources: state.resources
});

const mapDispatchToProps = dispatch => ({
    createCommunication: companyId => dispatch(createCommunication(companyId, CREATE_COMMUNICATION_TYPE_COMMUNICATION))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CommunicationsTableCard));
