import {
    basicColumnDef,
    choiceListColumnDef,
    choiceListColumnSettings,
    dateColumnDef,
    idColumnDef,
    timeColumnDef
} from '../common/FilteredTable/tableAndFilterHelpers';
import ColumnTypeFormatCompanyName from '../common/Table/ColumnTypeFormatCompanyName';
import React from 'react';

const meeting = {
    id: idColumnDef('id', 'meetingId'),
    companyName: {
        ...basicColumnDef('Company Name', 'companyName'),
        mapping: (resources, values) => (values && values.company ? values.company.descriptor : ''),
        mapToDisplayedValue: (resources, values, field, actions) => (
            <ColumnTypeFormatCompanyName actions={actions} values={values} />
        )
    },
    status: {
        ...choiceListColumnDef('Status', 'status', 'communicationStatus'),
        emptyOption: 'Select a status...'
    },
    assigneeId: {
        ...choiceListColumnDef('Assignee', 'assigneeId', 'communicationAssignee', { renderAsBasicText: true }),
        emptyOption: 'Select an assignee...',
        searchable: true,
        mapping: (resources, values) => (values && values.assigneeId ? values.assigneeId.toString() : '-')
    },
    startTime: {
        ...timeColumnDef('Meeting Time', 'startDate')
    },
    endDate: {
        ...dateColumnDef('Close Date', 'endDate')
    },
    activityRegarding: {
        ...choiceListColumnDef('Activity Regarding', 'activityRegarding', 'meetingActivityRegarding', {
            renderAsBasicText: true,
            displayEmptyAs: '-'
        }),
        emptyOption: 'Select an activity regarding...',
        searchable: true
    },
    body: {
        ...basicColumnDef('Details', 'body'),
        type: 'textarea',
        mapToDisplayedValue: (resources, values) => (values && values.body ? values.body.substring(0, 100) : '')
    },
    location: {
        ...basicColumnDef('Location', 'location')
    },
    personId: {
        ...choiceListColumnDef('Person', 'personId', 'peopleOptions'),
        emptyOption: 'Select a Person...',
        noOptionsText: 'No people in company'
    },
    eventMethod: {
        ...basicColumnDef('Event Method', 'eventMethod'),
        ...choiceListColumnSettings('communicationMeetingMode'),
        emptyOption: 'Select an Event Method...'
    },
    opportunityId: {
        ...basicColumnDef('Opportunity', 'opportunityId'),
        ...choiceListColumnSettings('opportunityOptions'),
        emptyOption: 'Select an Opportunity...',
        noOptionsText: 'No opportunities in company'
    },
    subject: {
        ...basicColumnDef('Subject', 'subject'),
        searchable: true
    },
    details: {
        ...basicColumnDef('Details', 'details'),
        type: 'textarea'
    },
    startDate: {
        ...dateColumnDef('Meeting Date', 'startDate', true),
        searchable: true
    },
    meetingMode: {
        ...choiceListColumnDef('Meeting Mode', 'meetingMode', 'communicationMeetingMode'),
        emptyOption: 'Select a meeting mode...',
        searchable: true
    },
    feedback: {
        ...basicColumnDef('Feedback', 'feedback')
    },
    rating: {
        ...choiceListColumnDef('Rating', 'rating', 'communicationAppointmentRating'),
        emptyOption: 'Select an rating...',
        searchable: true
    },

    action: {
        ...choiceListColumnDef('Action', 'action', 'meetingAction')
    },
    notes: {
        ...basicColumnDef('Details', 'notes'),
        type: 'textarea'
    },
    roiBooked: {
        ...basicColumnDef('ROI Booked', 'roiBooked')
    }
};

export default meeting;
