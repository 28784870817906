import React from 'react';
import { connect } from 'react-redux';
import { dashboardGet } from '../../../actions/dashboard/dashboard';
import { isAnyLoading } from '../../../reducers/Rest/loadingStates';
import ShowLoadingSpinner from '../../common/ShowLoadingSpinner';
import UpdatePasswordForm from '../../common/CommonForms/UpdatePasswordForm';

class SetupAccountForm extends React.Component {
    componentDidMount() {
        this.props.dashboardGet();
    }

    render() {
        const { token, sendResetPassword, loadingStates, className } = this.props;

        if (isAnyLoading(loadingStates)) {
            return <ShowLoadingSpinner isLoading color="#fff" />;
        }

        return (
            <UpdatePasswordForm
                class={className}
                formText={'Please set a password for your account.'}
                buttonText={'Update Password'}
                token={token}
                sendResetPassword={sendResetPassword}
            />
        );
    }
}

const mapStateToProps = state => ({
    loadingStates: state.loadingStates
});

const mapDispatchToProps = dispatch => ({
    dashboardGet: () => dispatch(dashboardGet())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SetupAccountForm);
