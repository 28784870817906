import { isError } from '../../Loading/isError';
import { toastr } from 'react-redux-toastr';
import { ENDPOINTS } from '../constants';
import { getAxiosPostOfflineRequest } from '../apiHelpers';
import { REST_TIME_FORMAT } from '../../Api/apiHelpers';
import moment from 'moment';
import { createFakeId } from '../../../reducers/util';

export const apiLeadEdit = leadForm => async (dispatch, getState) => {
    const type = ENDPOINTS.API.LEAD_POST;

    const lead = { ...leadForm };

    if (lead.leadId === -1) {
        lead.createdDate = moment().format(REST_TIME_FORMAT);
        lead.updatedDate = moment().format(REST_TIME_FORMAT);
        lead.leadId = createFakeId();
    } else {
        lead.updatedDate = moment().format(REST_TIME_FORMAT);
    }

    try {
        const data = { ...lead };
        if (data.leadId < 0) {
            data.leadId = null;
        }

        data.dealershipId = getState().user.dealershipId;

        const axiosRequest = getAxiosPostOfflineRequest(['lead'], {}, data);

        let unmodifiedLead = getState().leads.find(x => x.leadId === lead.leadId);
        if (!unmodifiedLead) {
            unmodifiedLead = lead;
        }
        unmodifiedLead.error = undefined;

        dispatch({
            type,
            data: { ...lead, syncing: true },
            meta: {
                offline: {
                    effect: axiosRequest,
                    commit: {
                        type: ENDPOINTS.API.LEAD_POST_COMMIT,
                        meta: { lead }
                    },
                    rollback: {
                        type: ENDPOINTS.API.LEAD_POST_ROLLBACK,
                        meta: { unmodifiedLead, lead }
                    }
                }
            }
        });

        //isLoading(dispatch, type, false, {});
        return true;
    } catch (error) {
        isError(dispatch, type, true, error.message, {});
        toastr.error(type, `Response ${error.message}`);
        return false;
    }
};
