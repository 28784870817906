import { ENDPOINTS, IAction } from '../../../actions/Api/constants';
import { RESET_REDUCER } from '../../../actions/resetReducers';
import { setPropertiesSyncing } from "../helper";
import _ from "lodash";
import eventDef from "../../../containers/Events/eventDef";

enum EventAction {
	todo = 'ToDo',
	vacation = 'Vacation',
}

const idField = eventDef.id.field;

export interface IEvent {
	eventId: number;
	createdBy: number;
	createdDate: Date;
	updatedBy: number;
	updatedDate: number;
	eventAction: EventAction;
	activityRegarding: string;
	status: string;
	startDate: Date;
	endDate: string;
	details: string;
	assigneeId: number;
}

export default (state: IEvent[] = [], action: IAction) => {
	switch (action.type) {
		case RESET_REDUCER:
			return [];
		case ENDPOINTS.API.EVENT_POST:
			return  updateStateBeforeSendingToServer(state, action);
		case ENDPOINTS.API.EVENT_POST_COMMIT:
			return postCommit(state, action);
		case ENDPOINTS.API.EVENT_FILTER:
			return update(state, action.data);

		default:
			return state;
	}
};

function updateStateBeforeSendingToServer(state: IEvent[], {data}: IAction){
	const original = state.find((x:any) => data[idField] === x[idField]);
	if(original)
	{
		setPropertiesSyncing(original, data);
	}
	data.syncing = true;
	return [
		data,
		...state.filter((x:any) => x[idField] !== data[idField])
	];
}


function postCommit(state:IEvent[], action:IAction){
	const {
		meta: { modified },
		payload: { data: remote }
	} = action;
	remote.assigneeId = _.get(remote, 'assignee.id');
	return [
		remote,
		...state.filter((x:any) => modified[idField] !== x[idField] && x[idField] !== remote[idField])
	];
}

const update = (state: IEvent[], update: IEvent[]): IEvent[] => [
	...state.filter(stateEvent => !update.some(updateEvent => stateEvent.eventId == updateEvent.eventId)),
	...update,
];
