import React from 'react';
import PropTypes from 'prop-types';
import { FormFeedback, Input, Label } from 'reactstrap';

const FormikCheckbox = props => {
    const { values, fieldName, handleChange, handleBlur, title, errors, touched, hideLabel, disabled, style } = props;
    const invalid = touched[fieldName] && errors[fieldName];
    return (
        <div className="custom-control custom-checkbox">
            <Input
                type="checkbox"
                className="custom-control-input"
                style={style}
                id={fieldName}
                checked={!!values[fieldName]}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled}
            />
            {!hideLabel ? (
                <Label className="custom-control-label" htmlFor={fieldName}>
                    <small className="text-muted text-uppercase">{title || fieldName}</small>
                </Label>
            ) : null}
            {invalid && <FormFeedback tooltip>{errors[fieldName]}</FormFeedback>}
        </div>
    );
};

FormikCheckbox.propTypes = {
    values: PropTypes.object.isRequired,
    formName: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    type: PropTypes.string
};

FormikCheckbox.defaultProps = {
    type: 'text'
};

export default FormikCheckbox;
