import React from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ENDPOINTS } from '../../../actions/Api/constants';

export const CompanyRegistrationsContext = React.createContext({
    companyId: undefined,
    registrations: [],
    isLoading: false
});

const CompanyRegistrationsProvider = ({ companyId, isLoading, registrations, children }) => {
    return (
        <CompanyRegistrationsContext.Provider value={{ companyId, registrations, isLoading }}>
            {children}
        </CompanyRegistrationsContext.Provider>
    );
};

const makeMapStateToProps = () => {
    const getState = createSelector(
        [
            state => _.get(state.loadingStates, `${ENDPOINTS.API.COMPANY}.isLoading`, false),
            (state, { companyId }) =>
                _.orderBy(state.registrations.filter(r => r.company.id === companyId), 'registrationDate', 'desc')
        ],
        (isLoading, registrations) => ({
            isLoading,
            registrations
        })
    );
    return (state, props) => getState(state, props);
};

export default connect(makeMapStateToProps)(CompanyRegistrationsProvider);
