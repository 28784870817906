import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import OpportunityForm from '../Forms/OpportunityForm';
import { AppModalHeader } from '../../common/AppModalHeader';
import { closeModal, FORM_PATHS } from '../../../actions/modals';

class EditOpportunityModal extends React.Component {
    handleSubmit = form => {};

    render() {
        const { modals, setModalClosed } = this.props;

        if (!modals.EditOpportunityModal.isOpen) {
            return '';
        }

        return (
            <Modal isOpen toggle={() => setModalClosed()} className="modal-lg">
                <AppModalHeader toggle={() => setModalClosed()}>
                    {modals.EditOpportunityModal.data.opportunityId < 0 ? 'Create Opportunity' : 'Edit Opportunity'}
                </AppModalHeader>
                <ModalBody>
                    <OpportunityForm
                        onCancel={this.props.setModalClosed}
                        companyId={modals.EditOpportunityModal.data.companyId}
                        opportunityId={modals.EditOpportunityModal.data.opportunityId}
                    />
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    modals: state.modals,
    opportunityForm: state.opportunityForm
});

const mapDispatchToProps = dispatch => ({
    setModalClosed: () => dispatch(closeModal(FORM_PATHS.EditOpportunity.modalName))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditOpportunityModal);
