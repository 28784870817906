import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import FlipMove from 'react-flip-move';
import Th from './Th';
import Tr from './Tr';
import { setTableSortColumn } from '../../../actions/table_sort_order';
import Pagination from './Pagination';
import SortDropdown from './SortDropdown';
import MapArray from '../../common/MapArray';
import { Button } from 'reactstrap';
import ShowLoadingSpinner from '../ShowLoadingSpinner';

class Table extends React.Component {
    constructor() {
        super();
        this.state = {
            collapsed: false,
            page: 0,
            showAll: false
        };
    }

    setPage = page =>
        this.setState({
            page
        });

    toggleShowAll = () => this.setState({ showAll: !this.state.showAll });

    /**
     * Calculate & Update state of new dimensions
     */
    updateDimensions() {
        if (window.innerWidth < this.props.breakpoint && !this.state.collapsed) {
            this.setState({ collapsed: true });
        } else if (window.innerWidth > this.props.breakpoint && this.state.collapsed) {
            this.setState({ collapsed: false });
        }
    }

    /**
     * Add event listener
     */
    componentDidMount() {
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions.bind(this));
    }

    /**
     * Remove event listener
     */
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions.bind(this));
    }

    getSortOrder = () => {
        const { id, tableSortOrders } = this.props;
        return tableSortOrders.find(orders => orders.table === id);
    };

    sortRows = rows => {
        const sortOrder = this.getSortOrder();

        if (!sortOrder) {
            return rows;
        }
        const rawColumnName = `${sortOrder.column}-raw`;
        let sortedRows = _.sortBy(
            rows,
            rows.some(row => row.hasOwnProperty(rawColumnName)) ? rawColumnName : `${sortOrder.column}`
        );

        if (!sortOrder.sort_asc) {
            sortedRows = _.reverse(sortedRows);
        }
        return sortedRows;
    };

    slice = rows => {
        if (this.state.showAll) {
            return rows;
        }
        const { itemsPerPage } = this.props;
        const { page } = this.state;
        if (!itemsPerPage) {
            return rows;
        }
        let max = (page + 1) * itemsPerPage;
        max = max > rows.length ? rows.length : max;
        return rows.slice(page * itemsPerPage, max);
    };

    componentWillReceiveProps(nextProps) {
        const { rows, itemsPerPage } = nextProps;
        const { page } = this.state;
        const maxPage = Math.round(rows.length / itemsPerPage);
        if (page > maxPage) {
            this.setPage(maxPage);
        }
    }

    render() {
        const {
            headings,
            rows,
            id,
            setTableSortColumn,
            itemsPerPage,
            isLoading,
            loadingText,
            onLoadMore,
            showLoadMore
        } = this.props;
        const { collapsed, page, showAll } = this.state;
        const sortOrder = this.getSortOrder();

        return (
            <React.Fragment>
                {collapsed && headings.some(heading => heading.sort) ? (
                    <SortDropdown key="DropdownSort" headings={headings} tableName={id} />
                ) : null}
                <table className="table position-relative" key={id}>
                    <FlipMove typeName="tbody" className="" duration={400} enterAnimation="fade" leaveAnimation="fade">
                        {!collapsed ? (
                            <tr key="headrow">
                                <MapArray
                                    from={headings}
                                    data={{ sortOrder }}
                                    map={(heading, index, { sortOrder }) => ({
                                        ...heading,
                                        sortOrder
                                    })}
                                >
                                    <Th setTableSortColumn={setTableSortColumn} tableId={id} />
                                </MapArray>
                            </tr>
                        ) : null}
                        {isLoading && (
                            <tr key="loading">
                                <td colSpan={headings.length}>
                                    <ShowLoadingSpinner isLoading title={loadingText} />
                                </td>
                            </tr>
                        )}
                        {this.slice(this.sortRows(rows)).map(row => (
                            <tr
                                key={row.id}
                                className={row.onClick ? 'hover-pointer hover-background' : ''}
                                onClick={row.onClick ? () => row.onClick() : null}
                            >
                                <Tr headings={headings} collapsed={collapsed} {...row} />
                            </tr>
                        ))}
                    </FlipMove>
                </table>
                {itemsPerPage && (
                    <Pagination
                        key="pagination"
                        page={page}
                        itemsPerPage={itemsPerPage}
                        rowCount={rows.length}
                        onPageChange={page => this.setPage(page)}
                        toggleShowAll={this.toggleShowAll}
                        showAll={showAll}
                    />
                )}
                {showLoadMore && (
                    <div className="text-center">
                        {' '}
                        <Button outline color="info" onClick={onLoadMore}>
                            Load More
                        </Button>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    tableSortOrders: state.tableSortOrders
});

const mapDispatchToProps = dispatch => ({
    setTableSortColumn: (table, column) => dispatch(setTableSortColumn(table, column))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Table);
