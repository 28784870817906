import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router';
import { createVisitAction } from '../../../actions/Navigation/VisitAction/createVisitAction';
import { getLoadingState } from '../../../reducers/Rest/loadingStates';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { editVisitAction } from '../../../actions/Navigation/VisitAction/editVisitAction';
import { openMeeting } from '../../../actions/Navigation/Communication/communicationSelected';
import { mapToRows } from '../../common/Table/addColumnsToRow';
import visitAction from '../visitAction';
import { TableDefinition } from '../../common/FilteredTable/tableAndFilterHelpers';
import ChoiceListFilteredTableNoDashboardData from '../../common/FilteredTable/ChoiceListFilteredTableNoDashboardData';

class VisitActionsTableCard extends React.Component {
    static defaultProps = {
        breakpoint: 500,
        itemsPerPage: 5,
        noItemsText: 'There are no visit actions to show',
        columns: [visitAction.startDate, visitAction.activityRegarding, visitAction.status]
    };

    renderButtons = () => {
        const {
            buttons,
            cardButtons,
            createVisitAction,
            companyId,
            visitAction,
            match: {
                params: { communicationId }
            }
        } = this.props;
        const items = cardButtons || [];

        if (buttons && buttons.includes('addVisitAction')) {
            items.push({
                text: 'Add VisitAction',
                fontAwesomeName: 'fa-plus',
                onClick: () =>
                    createVisitAction(companyId, (visitAction && visitAction.visitActionId) || communicationId)
            });
        }
        return items;
    };

    render() {
        const {
            loadingStates,
            title,
            rows,
            showFilter,
            companyId,
            visitActions,
            communication,
            editRow,
            resources,
            //openCompany,
            user,
            columns,
            openMeeting,
            ...rest
        } = this.props;

        const isLoading = getLoadingState(loadingStates, ENDPOINTS.API.VISIT_ACTIONS_FILTER_COMMIT).isLoading;

        const tableDefinition = new TableDefinition(columns);

        const tableRows = mapToRows(
            rows,
            visitAction.id,
            resources,
            tableDefinition.columns,
            values => editRow(values),
            {
                //openCompany,
                openMeeting
            }
        );

        return (
            <ChoiceListFilteredTableNoDashboardData
                id={title}
                tableDefinition={tableDefinition}
                rows={tableRows}
                isLoading={isLoading}
                showInCard={true}
                cardButtons={this.renderButtons()}
                title={title}
                {...rest}
            />
        );
    }
}

const mapStateToProps = state => ({
    loadingStates: state.loadingStates,
    resources: state.resources,
    visitActions: state.visitActions,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    editRow: visitAction => dispatch(editVisitAction(visitAction)),
    createVisitAction: (companyId, parentVisitActionId) => dispatch(createVisitAction(companyId, parentVisitActionId)),
    //openCompany: (companyId, descriptor) => dispatch(viewCompany(companyId, descriptor)),
    openMeeting: (communicationId, descriptor) => dispatch(openMeeting(communicationId, descriptor))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(VisitActionsTableCard));
