import React from 'react';
import { connect } from 'react-redux';
import CompanySearchForm from '../../CompanySummary/Forms/CompanySearchForm';
import Layout3Columns, { Column1, Column2 } from '../../Dashboard/Components/Layout3Columns';
import PageSection from '../../common/PageSection';
import SimpleCard from '../../common/Card/SimpleCard';
import BrowserHistory from '../../BrowserHistory/Tables/BrowserHistory';
import { redirectIfNotLoggedIn } from '../../../actions/Navigation/redirectIfNotLoggedIn';

interface IProps {
	redirectIfNotLoggedIn: any;
}

class HomePage extends React.Component<IProps> {
	componentDidMount() {
		this.props.redirectIfNotLoggedIn(() => {});
	}

	render() {
		return (
			<PageSection key={`Companies`} title={`Companies`}>
				<Layout3Columns>
					<Column1>
						<BrowserHistory title="Recent Companies" urlsStartWith="/company/" />
					</Column1>
					<Column2>
						<SimpleCard title="Search Companies">
							<CompanySearchForm />
						</SimpleCard>
					</Column2>
				</Layout3Columns>
			</PageSection>
		);
	}
}

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch: any) => ({
	redirectIfNotLoggedIn: (action: any) => dispatch(redirectIfNotLoggedIn(action))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(HomePage);
