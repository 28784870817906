import React from 'react';
import {
    basicColumnDef,
    choiceListColumnSettings,
    emailColumnDef,
    idColumnDef,
    phoneColumnDef
} from '../common/FilteredTable/tableAndFilterHelpers';
import GdprOverview from '../Gdpr/GdprOverview';
import IsPrimaryPerson from '../Person/Tables/IsPrimaryPerson';

const person = {
    id: idColumnDef('id', 'personId'),
    salutation: {
        ...basicColumnDef('Salutation', 'salutation'),
        ...choiceListColumnSettings('personSalutation'),
        emptyOption: 'Select a Salutation...'
    },
    jobTitle: {
        ...basicColumnDef('Job Title', 'jobTitle'),
        type: 'text'
    },
    descriptor: {
        ...basicColumnDef('Contact Name', 'descriptor'),
        type: 'personName',
        primary: true
    },
    firstName: {
        ...basicColumnDef('First Name', 'firstName')
    },
    lastName: {
        ...basicColumnDef('Last Name', 'lastName')
    },
    // Contact Details
    businessEmail: {
        ...basicColumnDef('Email Address', 'businessEmail'),
        type: 'email'
    },
    businessEmailLink: {
        ...emailColumnDef('Email Address', 'businessEmail'),
        type: 'link'
    },
    businessPhone: {
        ...phoneColumnDef('Business Number', 'businessPhone')
    },
    ddiPhone: {
        ...phoneColumnDef('Direct Dial Number', 'ddiPhone')
    },
    mobilePhone: {
        ...phoneColumnDef('Mobile Number', 'mobilePhone')
    },
    gdprPreferences: {
        ...basicColumnDef('GDPR Preferences', 'gdprPreferences'),
        mapToDisplayedValue: (resources, values) => <GdprOverview person={values} />
    },
    gdprPreferencesInTable: {
        ...basicColumnDef('GDPR Preferences', 'gdprPreferences'),
        right: true,
        sort: false,
        mapToDisplayedValue: (resources, values) => (
            <span className="float-right">
                <GdprOverview person={values} />
            </span>
        )
    },
    gdprEmail: {
        ...basicColumnDef('Email', 'gdprEmail'),
        ...choiceListColumnSettings('personGdprEmail'),
        emptyOption: 'Select a GDPR Preference...'
    },
    gdprDirectMail: {
        ...basicColumnDef('Direct Mail', 'gdprDirectMail'),
        ...choiceListColumnSettings('personGdprEmail'),
        emptyOption: 'Select a GDPR Preference...'
    },
    gdprTelephone: {
        ...basicColumnDef('Telephone', 'gdprTelephone'),
        ...choiceListColumnSettings('personGdprEmail'),
        emptyOption: 'Select a GDPR Preference...'
    },
    gdprSms: {
        ...basicColumnDef('SMS', 'gdprSms'),
        ...choiceListColumnSettings('personGdprEmail'),
        emptyOption: 'Select a GDPR Preference...'
    },
    isPrimaryPerson: {
        ...basicColumnDef('Primary', 'isPrimaryPerson'),
        valueToSortOnMapping: (resources, values, field, actions) => values.isPrimaryPerson,
        mapToDisplayedValue: (resources, values, field, actions) =>
            values.isPrimaryPerson ? <IsPrimaryPerson id={`isPrimary_${values.personId}`} /> : null
    }
};

export default person;
