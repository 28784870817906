import React from 'react';
import { PreventClickThrough } from './PreventClickThrough';
import { Link } from 'react-router-dom';

const ColumnTypeFormatCompanyName = ({ values }) => {
    return (
        values.company &&
        values.company.descriptor && (
            <div onClick={event => PreventClickThrough(event)} className="d-inline-block">
                {values.company.descriptor.split(' - ').map((string, index) => (
                    <div key={index}>
                        <Link to={`/company/${values.company.id}/${values.company.descriptor}`}>{string}</Link>
                    </div>
                ))}
            </div>
        )
    );
};

export default ColumnTypeFormatCompanyName;
