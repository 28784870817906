import React, { useContext } from 'react';
import { CompanyPeopleContext } from '../../Company/Provider/CompanyPeopleProvider';
import PersonTable from './PersonTable';

const CompanyPeopleTable = () => {
    const { people } = useContext(CompanyPeopleContext);
    return <PersonTable breakpoint={700} itemsPerPage={10} rows={people} />;
};

export default CompanyPeopleTable;
