import React, { useContext } from 'react';
import { CommunicationContext } from '../../Communication/Provider/CommunicationProvider';
import { isPropertyHasError, isPropertySyncing } from '../../../reducers/Api/helper';
import SelectInlineChoicelist from '../../common/InlineEdit/SelectInlineChoicelist';
import PersonDetails from '../../Person/Components/PersonDetails';
import { CompanyPeopleContext } from '../../Company/Provider/CompanyPeopleProvider';

const findContact = (people, communication) => people.find(x => x.personId === communication.personId);

const mapPeopleToSelectOptions = people =>
    people.map(x => ({ key: x.personId.toString(), value: `${x.firstName} ${x.lastName}` }));

const MeetingAndForm = () => {
    const { communication, saveCommunication } = useContext(CommunicationContext);
    const { isLoading, companyId, people } = useContext(CompanyPeopleContext);
    const contact = findContact(people, communication);

    return contact ? (
        <PersonDetails
            key={contact.personId}
            title={'Contact'}
            person={contact}
            companyId={companyId}
            isLoading={isLoading}
        >
            <SelectInlineChoicelist
                options={mapPeopleToSelectOptions(people)}
                value={communication.personId ? communication.personId.toString() : ''}
                objectId={communication.communicationId}
                propertyName={'personId'}
                stack
                required
                isSyncing={isPropertySyncing(communication, 'personId')}
                isError={isPropertyHasError(communication, 'personId')}
                save={(a, newPersonId, c) => {
                    const { personId, person, ...rest } = communication;
                    saveCommunication({ personId: parseInt(newPersonId, 10), person: { id: newPersonId }, ...rest });
                }}
            />
        </PersonDetails>
    ) : null;
};

export default MeetingAndForm;
