import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { FORM_NAMES } from '../../../actions/formNames';
import { apiOpportunityEdit } from '../../../actions/Api/Opportunity/apiOpportunityEdit';
import ReactiveForm from '../../common/ReactiveForm';
import opportunity from '../opportunity';
import validation from './OpportunityForm.Validation';
import _ from 'lodash';
import useInitialValues from '../../common/Hooks/useInitialValues';
import moment from 'moment';

const FORM_NAME = FORM_NAMES.OPPORTUNITY;

function handleSubmit(form, save, onCancel) {
    let correctedForm = { ...form };
    try {
        correctedForm.opportunityId = parseInt(correctedForm.opportunityId, 10);
        correctedForm.companyId = parseInt(correctedForm.companyId, 10);
    } catch (err) {
        // Stil works but display bug
    } finally {
        save(correctedForm, onCancel);
    }
}

function useCompanyName(companies, companyId) {
    return useMemo(
        () => {
            const company = companies.find((x) => x.companyId === companyId);
            return company ? company.name : '';
        },
        [companies, companyId]
    );
}

function useLeasingCompanyPeople(resources) {
    return useMemo(() => _.get(resources, `choiceList.${opportunity.leasingCompanyPerson.choiceList}`), [resources]);
}

const OpportunityForm = ({
    save,
    onCancel,
    opportunityId,
    companyId,
    opportunities,
    resources,
    companies,
    user: { userId },
}) => {
    const companyName = useCompanyName(companies, companyId);
    const opportunityLeasingCompanyPeople = useLeasingCompanyPeople(resources);
    const currentOpportunity = useMemo(
        () => opportunities.find((opportunity) => opportunity.opportunityId === opportunityId),
        [opportunities, opportunityId]
    );

    const initialValues = useInitialValues(
        currentOpportunity,
        [
            { field: 'opportunityId' },
            { field: 'companyId', convert: 'toString' },
            { field: 'companyName' },
            { field: 'type' },
            { field: 'description' },
            { field: 'estimatedConversionDate' },
            { field: 'stage' },
            { field: 'wonLostReason' },
            { field: 'lostReasonOther' },
            { field: 'unitPotential' },
            { field: 'note' },
            { field: 'likelihood' },
            { field: 'note' },
            { field: 'assigneeId', convert: 'toString' },
            { field: 'year' },
            { field: 'termsLoaded' },
            { field: 'fuelType' },
            { field: 'model' },
            { field: 'leasingCompany', convert: 'toString' },
            { field: 'leasingCompanyPerson', convert: (val) => ({ ...val, id: val.id ? val.id.toString() : null }) },
        ],
        {
            opportunityId,
            companyId,
            companyName,
            assigneeId: userId,
            unitPotential: 0,
            estimatedConversionDate: moment(),
        }
    );

    return (
        <ReactiveForm
            initialValues={initialValues}
            cancel={onCancel}
            resources={resources}
            formName={FORM_NAME}
            validationSchema={validation}
            handleSubmit={(form) => handleSubmit(form, save, onCancel)}
        >
            <ReactiveForm.Section title=''>
                <ReactiveForm.Text columnDef={opportunity.companyName} disabled />
                <ReactiveForm.Text columnDef={opportunity.description} />
                <ReactiveForm.Choicelist columnDef={opportunity.type} />
                <ReactiveForm.Date columnDef={opportunity.estimatedConversionDate} />
                <ReactiveForm.Choicelist columnDef={opportunity.stage} />
                <ReactiveForm.Choicelist
                    columnDef={opportunity.wonLostReason}
                    hide={({ values }) => _.get(values, `${opportunity.stage.field}`, '') !== 'LostSale'}
                />
                <ReactiveForm.Text
                    columnDef={opportunity.lostReasonOther}
                    hide={({ values }) =>
                        _.get(values, `${opportunity.wonLostReason.field}`, '') !== 'Other' ||
                        _.get(values, `${opportunity.stage.field}`, '') !== 'LostSale'
                    }
                />
                <ReactiveForm.Choicelist columnDef={opportunity.leasingCompany} />
                {/*<ReactiveForm.Choicelist
                    columnDef={opportunity.leasingCompanyPerson}
                    filterOnFormValues={(option, { values }) => {
                        const { leasingCompany } = values;
                        const {
                            metadata: { companyId }
                        } = option;
                        return companyId.toString() === leasingCompany;
                    }}
                />*/}
                <ReactiveForm.Choicelist
                    columnDef={opportunity.leasingCompanyPerson}
                    options={({ values }) => {
                        const ordinal = parseInt(values[opportunity.leasingCompany.field], 10);
                        if (!ordinal) {
                            return [];
                        }
                        return opportunityLeasingCompanyPeople.filter((x) => x.ordinal === ordinal);
                    }}
                />
                {/* <ReactiveForm.Checkbox columnDef={opportunity.termsLoaded} /> */}
                <ReactiveForm.Choicelist
                    columnDef={opportunity.termsLoadedChoiceList}
                    options={[{ key: 'Yes', value: 'Yes', metadata: null }, { key:  'No', value: 'No', metadata: null }]}
                />
                <ReactiveForm.Text columnDef={opportunity.unitPotential} />
                <ReactiveForm.Choicelist columnDef={opportunity.fuelType} />
                <ReactiveForm.Choicelist columnDef={opportunity.model} />
                <ReactiveForm.Text columnDef={opportunity.note} />
                <ReactiveForm.Choicelist columnDef={opportunity.likelihood} />
                <ReactiveForm.Choicelist columnDef={opportunity.year} />
                <ReactiveForm.Choicelist columnDef={opportunity.assignee} />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
};

const mapStateToProps = (state) => ({
    opportunities: state.opportunities,
    companies: state.companies,
    resources: state.resources,
    user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
    save: (opportunity, onCancel) => {
        dispatch(apiOpportunityEdit(opportunity));
        onCancel();
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OpportunityForm);
