import React from 'react';
import { connect } from 'react-redux';
import { apiLeadEdit } from '../../../actions/Api/Lead/apiLeadEdit';
import { FORM_NAMES } from '../../../actions/formNames';
import { createFakeId } from '../../../reducers/util';
import ReactiveForm from '../../common/ReactiveForm';

import lead from '../lead';
import validation from '../lead.validation';

const FORM_NAME = FORM_NAMES.LEAD;

export const LEAD_FORM_NAME = FORM_NAME;

// Might be outdated?
export const EmptyLeadForm = {
    leadId: -1,
    companyName: '',
    brand: 'Corporate',
    website: '',
    phoneNumber: '',
    salutation: '',
    jobTitle: '',
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    address1: '',
    address2: '',
    address3: '',
    address4: '',
    city: '',
    county: '',
    postcode: '',
    fleetSize: 0,
    numberOfCars: 0,
    numberOfVans: 0,
    createdBy: -1,
    createdDate: undefined,
    updatedBy: -1,
    updatedDate: undefined,
    stage: 'UCNInProgress',
    companyStatus: ''
};

class CreateLeadForm extends React.Component {
    handleSubmit = form => this.props.save(form, this.props.cancel);

    render() {
        const { leadId, leads, cancel, resources } = this.props;

        let initialValues = leads.find(lead => lead.leadId === leadId);

        if (!initialValues && leadId < 0) {
            initialValues = EmptyLeadForm;
            initialValues.leadId = createFakeId();
        }

        return (
            <ReactiveForm
                initialValues={initialValues}
                cancel={cancel}
                resources={resources}
                formName={FORM_NAME}
                validationSchema={validation}
                handleSubmit={this.handleSubmit}
            >
                <ReactiveForm.Section title="Company Details">
                    <ReactiveForm.Text columnDef={lead.companyName} />
                    <ReactiveForm.Choicelist columnDef={lead.brand} />
                    <ReactiveForm.Text columnDef={lead.website} />
                    <ReactiveForm.Text columnDef={lead.phoneNumer} />
                </ReactiveForm.Section>
                <ReactiveForm.Section title="Contact Details" size={6} grow={false}>
                    <ReactiveForm.Choicelist columnDef={lead.salutation} />
                    <ReactiveForm.Text columnDef={lead.jobTitle} />
                    <ReactiveForm.Text columnDef={lead.firstName} />
                    <ReactiveForm.Text columnDef={lead.lastName} />
                    <ReactiveForm.Text columnDef={lead.mobile} />
                    <ReactiveForm.Text columnDef={lead.email} />
                </ReactiveForm.Section>
                <ReactiveForm.Section title="Address" size={6} grow={false}>
                    <ReactiveForm.Text columnDef={lead.address1} />
                    <ReactiveForm.Text columnDef={lead.address2} />
                    <ReactiveForm.Text columnDef={lead.address3} />
                    <ReactiveForm.Text columnDef={lead.address4} />
                    <ReactiveForm.Text columnDef={lead.city} />
                    <ReactiveForm.Text columnDef={lead.county} />
                    <ReactiveForm.Text columnDef={lead.postcode} />
                </ReactiveForm.Section>
                <ReactiveForm.Section title="Fleet Profile">
                    <ReactiveForm.Text columnDef={lead.fleetSize} />
                    <ReactiveForm.Text columnDef={lead.numberOfCars} />
                    <ReactiveForm.Text columnDef={lead.numberOfVans} />
                </ReactiveForm.Section>
            </ReactiveForm>
        );
    }
}

const mapStateToProps = state => ({
    leads: state.leads,
    resources: state.resources
});

const mapDispatchToProps = dispatch => ({
    save: (lead, cancel) => {
        dispatch(apiLeadEdit(lead));
        cancel();
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateLeadForm);
