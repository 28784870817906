import React from 'react';
import { Col } from 'reactstrap';
import { connect } from 'react-redux';
import _ from 'lodash';
import FormikText from '../../common/FormkControls/FormikText';
import FormikCheckbox from '../../common/FormkControls/FormikCheckbox';
import FormikSelectChoicelist from '../../common/FormkControls/FormikSelectChoicelist';
import FormSubSection from '../SubSection/FormSubSection';
import MapArray from '../../common/MapArray';
import FormikDate from './FormikDate';
import moment from 'moment';
import { REST_TIME_FORMAT } from '../../../actions/Api/apiHelpers';
import { DisplayField } from '../DisplayField/index';
import { Alert } from 'reactstrap';
import FormikAddressSearch from './FormikAddressSearch';
import { getChoiceListValue } from '../LabelText';

const mapArray = (fields, formikProps, optionsData, resources) => {
    return (
        <MapArray
            from={_.sortBy(fields.filter(field => field.hasOwnProperty('order')), ['order'])}
            map={(field, index, data) => ({ ...field, index, ...data })}
            data={{ formikProps, optionsData, resources }}
        >
            <ColWrappedForm />
        </MapArray>
    );
};

export const FormSection = props => {
    const { title, fields, className, formikProps, optionsData, resources, sectionNumber } = props;
    return (
        <FormSubSection title={title} className={className || 'col-12'} sectionNumber={sectionNumber}>
            {mapArray(fields, formikProps, optionsData, resources)}
        </FormSubSection>
    );
};

const getMapChoiceValues = labelMapping => {
    if (!labelMapping) {
        return undefined;
    }
    const { type, text } = labelMapping;
    switch (type) {
        case 'trim-start':
            return a => (a.indexOf(text) > -1 ? a.substring(text.length) : a);
        default:
            return a => a;
    }
};

const getChoiceListMapping = (optionsFilter, props) => {
    if (!optionsFilter) {
        return undefined;
    }
    const { filter, text, values } = optionsFilter;

    switch (filter) {
        case 'is-in':
            return x => values.indexOf(x.value) > -1;
        case 'is-not-in':
            return x => values.indexOf(x.value) === -1;
        case 'starts-with':
            return x => x.value.indexOf(text) === 0;
        case 'ends-with':
            return x => x.value.indexOf(text) === x.value.length - text.length;
        case 'is-not':
            return x => x.value !== text;
        case 'same-dealership':
            const dealershipUsers = [
                ..._.get(props, 'user.dealershipUsers', []).map(x => x.userId.toString()),
                props.user.dealershipId.toString(),
                props.user.userId.toString()
            ];
            return x => dealershipUsers.includes(x.value.toString());
        default:
            return x => true;
    }
};

const shouldBeHidden = (hideWhen, formikProps) => {
    if (!hideWhen) {
        return false;
    }
    let shouldBeHidden = false;
    hideWhen.forEach(rule => {
        if (typeof rule === 'object') {
            const { match, values, fieldName } = rule;

            switch (match) {
                case 'empty':
                    shouldBeHidden = shouldBeHidden || !formikProps.values[fieldName];
                    break;
                case 'is-in':
                    shouldBeHidden = shouldBeHidden || values.indexOf(formikProps.values[fieldName]) > -1;
                    break;
                default:
                    break;
            }
        } else if (typeof rule === 'function') {
            shouldBeHidden = shouldBeHidden || rule(formikProps);
        }
    });
    return shouldBeHidden;
};

const mapStateToProps = state => ({
    user: state.user
});

const ColWrappedForm = ({ cols, ...rest }) => {
    return (
        <Col md={cols || 12} className="form-col">
            <FormFieldFactory {...rest} />
        </Col>
    );
};

const FormFieldFactory = connect(mapStateToProps)(props => {
    const {
        formikProps,
        min,
        max,
        fieldName,
        title,
        type,
        emptyOption,
        className,
        fields,
        choiceList,
        optionsFilter,
        optionsData,
        noOptionsText,
        labelMapping,
        updatesFields,
        hideWhen,
        readOnlyWhen,
        resources
    } = props;

    if (shouldBeHidden(hideWhen, formikProps)) {
        return null;
    }

    const value = fieldName => {
        return formikProps.values[fieldName];
    };

    const readOnly = readOnlyWhen && readOnlyWhen(formikProps);

    //special named cases
    switch (fieldName) {
        case 'gdprEmail':
        case 'gdprDirectMail':
        case 'gdprTelephone':
        case 'gdprSms':
            return (
                <FormikSelectChoicelist
                    emptyOption="Select a GDPR preference..."
                    choiceList="personGdprEmail"
                    {...formikProps}
                    title={title}
                    fieldName={fieldName}
                    disabled={type === 'cvGdprTrue'}
                />
            );
        case 'endDate':
            return (
                <FormikDate
                    fieldName="endDate"
                    {...formikProps}
                    title={title}
                    onChange={endDate => {
                        const { startDate } = formikProps.values;

                        if (startDate && moment(startDate).isAfter(endDate)) {
                            formikProps.setFieldValue(
                                'startDate',
                                endDate
                                    .hour(9)
                                    .minute(0)
                                    .seconds(0)
                                    .format(REST_TIME_FORMAT)
                            );
                        }
                    }}
                    showTimeSelect
                />
            );
        case 'startDate':
            return (
                <FormikDate
                    fieldName="startDate"
                    {...formikProps}
                    title={title}
                    onChange={startDate => {
                        const { endDate } = formikProps.values;

                        if (endDate && moment(endDate).isBefore(startDate)) {
                            formikProps.setFieldValue(
                                'endDate',
                                startDate
                                    .hour(17)
                                    .minute(30)
                                    .seconds(0)
                                    .format(REST_TIME_FORMAT)
                            );
                        }
                    }}
                    showTimeSelect
                />
            );

        default:
            break;
    }

    //types of field
    switch (type) {
        case 'date':
            return <FormikDate fieldName={fieldName} {...formikProps} title={title} />;
        case 'datetime':
            return <FormikDate fieldName={fieldName} {...formikProps} title={title} showTimeSelect />;
        case 'singleSelectOptionsData':
            const options = (optionsData && optionsData[choiceList]) || [];
            return options.length === 0 ? (
                <DisplayField title={title} value={<Alert color="warning">{noOptionsText}</Alert>} />
            ) : (
                <FormikSelectChoicelist
                    emptyOption={emptyOption}
                    {...formikProps}
                    options={options}
                    title={title}
                    fieldName={fieldName}
                    filter={getChoiceListMapping(optionsFilter)}
                />
            );
        case 'displayDescriptor':
            return value(fieldName) && <DisplayField title={title} value={value(fieldName).descriptor} />;
        case 'choiceList':
            return (
                value(fieldName) && (
                    <DisplayField title={title} value={getChoiceListValue(resources, choiceList, value(fieldName))} />
                )
            );
        case 'multiSelect':
            return (
                <FormikSelectChoicelist
                    emptyOption={emptyOption}
                    choiceList={choiceList}
                    {...formikProps}
                    title={title}
                    fieldName={fieldName}
                    mapChoiceValues={getMapChoiceValues(labelMapping)}
                    filter={getChoiceListMapping(optionsFilter, props)}
                    isMulti={true}
                    disabled={readOnly}
                />
            );
        case 'singleSelect':
            return (
                <FormikSelectChoicelist
                    emptyOption={emptyOption}
                    choiceList={choiceList}
                    {...formikProps}
                    title={title}
                    fieldName={fieldName}
                    mapChoiceValues={getMapChoiceValues(labelMapping)}
                    filter={getChoiceListMapping(optionsFilter, props)}
                    disabled={readOnly}
                />
            );
        case 'checkbox':
            return <FormikCheckbox fieldName={fieldName} title={title} {...formikProps} />;
        case 'addressSearch':
            return (
                <FormikAddressSearch
                    title={title}
                    fieldName={fieldName}
                    formikProps={formikProps}
                    updatesFields={updatesFields}
                />
            );
        case 'div':
            return <div className={className}>{mapArray(fields, formikProps, optionsData, resources)}</div>;

        case 'currency':
            return (
                <div className={className}>
                    <FormikText
                        type={'number'}
                        title={title}
                        fieldName={fieldName}
                        {...formikProps}
                        min={min}
                        max={max}
                        placeholder={emptyOption}
                        prefix="£"
                        disabled={readOnly}
                    />
                </div>
            );

        default:
            if (className) {
                return (
                    <div className={className}>
                        <FormikText
                            type={type}
                            title={title}
                            fieldName={fieldName}
                            {...formikProps}
                            min={min}
                            max={max}
                            disabled={readOnly}
                            placeholder={emptyOption}
                        />
                    </div>
                );
            }
            return (
                <FormikText
                    type={type}
                    title={title}
                    fieldName={fieldName}
                    {...formikProps}
                    min={min}
                    max={max}
                    disabled={readOnly}
                    placeholder={emptyOption}
                />
            );
    }
});
