import { offlinePost } from '../apiHelpers';
import { ENDPOINTS } from '../constants';

export const apiCommunicationPost = communication => async (dispatch, getState) => {
    //console.log(communication);
    dispatch(
        offlinePost(
            ENDPOINTS.API.COMMUNICATION_POST,
            ENDPOINTS.API.COMMUNICATION_POST_COMMIT,
            ENDPOINTS.API.COMMUNICATION_POST_ROLLBACK,
            communication,
            getUnmodified(communication, getState),
            getPostData(communication, getState),
            communication.communicationId,
            ['communication'],
            {}
        )
    );
};

const getUnmodified = (communication, getState) => {
    let unmodified = getState().communications.find(x => communication.communicationId === x.communicationId);
    if (!unmodified) {
        unmodified = { ...communication };
    }
    return unmodified;
};

const getPostData = (communication, getState) => {
    const data = { ...communication };
    if (data.communicationId < 0) {
        data.communicationId = null;
    }
    data.dealershipId = getState().user.dealershipId; //TODO:Should this be in the form

    if (!data.endDate || data.endDate === '0001-01-01T00:00:00') {
        data.endDate = data.startDate;
    }
    return data;
};
