import React from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ENDPOINTS } from '../../../actions/Api/constants';

export const CompanyMeetingsContext = React.createContext({
    companyId: undefined,
    meetings: [],
    isLoading: false
});

export const CompanyMeetingsConsumer = CompanyMeetingsContext.Consumer;

const CompanyMeetingsProvider = ({ companyId, isLoading, meetings, children }) => {
    return (
        <CompanyMeetingsContext.Provider value={{ companyId, meetings, isLoading }}>
            {children}
        </CompanyMeetingsContext.Provider>
    );
};

const makeMapStateToProps = () => {
    const getState = createSelector(
        [
            state => _.get(state.loadingStates, `${ENDPOINTS.API.COMPANY}.isLoading`, false),
            (state, { companyId }) => state.meetings.filter(x => x.company.id === companyId)
        ],
        (isLoading, meetings) => ({
            isLoading,
            meetings
        })
    );
    return (state, props) => getState(state, props);
};

export default connect(makeMapStateToProps)(CompanyMeetingsProvider);
