import React from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ENDPOINTS } from '../../../actions/Api/constants';

export const CompanyOpportunitiesContext = React.createContext({
    companyId: undefined,
    opportunities: [],
    isLoading: false
});

const CompanyOpportunitiesProvider = ({ companyId, isLoading, opportunities, children }) => {
    return (
        <CompanyOpportunitiesContext.Provider value={{ companyId, opportunities, isLoading }}>
            {children}
        </CompanyOpportunitiesContext.Provider>
    );
};

const makeMapStateToProps = () => {
    const getState = createSelector(
        [
            state => _.get(state.loadingStates, `${ENDPOINTS.API.COMPANY}.isLoading`, false),
            (state, { companyId }) => state.opportunities.filter(opportunity => opportunity.companyId === companyId)
        ],
        (isLoading, opportunities) => ({
            isLoading,
            opportunities
        })
    );
    return (state, props) => getState(state, props);
};

export default connect(makeMapStateToProps)(CompanyOpportunitiesProvider);
