import { isLoading } from '../../Loading/isLoading';
import { isError } from '../../Loading/isError';
import { toastr } from 'react-redux-toastr';
import { ENDPOINTS } from '../constants';
import { apiGet } from '../apiHelpers';

export const apiCompanySearch = (keyword, maxResults = 10) => async (dispatch, getState) => {
    const type = ENDPOINTS.API.COMPANY_SEARCH;

    isLoading(dispatch, type, true, {});
    try {
        const dealershipId = getState().user.dealershipId;
        let path;
        if (dealershipId) {
            path = ['company', 'search', dealershipId, keyword];
        } else {
            path = ['company', 'search', keyword];
        }

        dispatch(apiGet(type, path, { maxResults }));
    } catch (error) {
        isError(dispatch, type, true, error.message, {});
        toastr.error(type, `Response ${error.message}`);
        return false;
    }
};
