import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import EditEventForm from '../Forms/EditEventForm';
import { createFakeId } from '../../../reducers/util';
import useModalForm from '../../common/Hooks/useModalForm';

const EditEventModal = ({ children, className }) => {
    const { toggle, isOpen, formValue, openModal } = useModalForm();

    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle} className={className}>
                <AppModalHeader toggle={toggle}>
                    {`${formValue.eventId < 0 ? 'Add' : 'Edit'} ${
                        formValue.eventType === 'CSMAnnualLeave'
                            ? 'Holiday'
                            : formValue.eventType === 'CSMAdminDay'
                            ? 'Admin Day'
                            : 'Event'
                    }`}
                </AppModalHeader>
                <ModalBody>
                    <EditEventForm onCancel={toggle} event={formValue} />
                </ModalBody>
            </Modal>
            {children({
                editEvent: event => openModal(event),
                createEvent: (eventType, defaultValues) =>
                    openModal({ eventId: createFakeId(), eventType, ...defaultValues })
            })}
        </>
    );
};

export default EditEventModal;
