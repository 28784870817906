import React, { useContext } from 'react';
import DemosTableCard from './DemosTableCard';
import { CompanyDemosContext } from '../../Company/Provider/CompanyDemosProvider';

function CompanyDemosTable() {
    const { demos, isLoading } = useContext(CompanyDemosContext);
    return <DemosTableCard rows={demos} isLoading={isLoading} tableIsNotInCard={true} />;
}

export default CompanyDemosTable;
