import { Dispatch } from 'redux';
import { IAction, ENDPOINTS } from '../constants';
import { ICommunication } from '../../../reducers/Api/Communications';
import { isError } from '../../Loading/isError';
import { toastr } from 'react-redux-toastr';
import { makePostRequest } from '../apiHelpers';

export interface ICommunicationFilter {
	status?: string[];
	assigneeId?: number;
	startDate?: Date;
	endDate?: Date;
	companyId?: number;
}

const type = ENDPOINTS.API.COMMUNICATIONS_FILTER;

export const apiCommunicationFilter = (filter: ICommunicationFilter) => async (
	dispatch: Dispatch<IAction>,
	getState: () => any
) => {
	try {
		if (!getState().offline.online) {
			return false;
		}

		const response: ICommunication[] = (await makePostRequest(['communication', 'filter'], {}, filter)).data;

		dispatch({
			type,
			data: response,
			filter,
		});

		return true;
	} catch (err) {
		isError(dispatch, type, true, err.message, {});

		if (getState().user.userId > -1) {
			toastr.error(type, `Response ${err.message}`);
		}

		return false;
	}
};
