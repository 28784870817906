import React, { useContext } from 'react';
import SimpleCard from '../../common/Card/SimpleCard';
import meetingDef from '../meeting';
import { MeetingContext } from '../Provider/MeetingProvider';
import { DashboardFieldColumnDef } from '../../Dashboard/Components/DashboardField';

const MeetingPageOverview = () => {
    const { meeting } = useContext(MeetingContext);
    return (
        <SimpleCard title="Overview">
            <DashboardFieldColumnDef item={meeting} columnDef={meetingDef.details} />
        </SimpleCard>
    );
};

export default MeetingPageOverview;
