import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import CardDropDownButton from '../../common/Buttons/CardDropDownButton';
import SimpleCard from '../../common/Card/SimpleCard';
import { editPerson } from '../../../actions/Navigation/Person/editPerson';
import { safeDispatchPush } from '../../../reducers/util';
import person from '../person';
import { ConnectedFieldDetails } from '../../common/Table/addColumnsToRow';

class PersonDetails extends React.Component {
    renderButtons = () => {
        const { companyId, onViewCompanyPeople } = this.props;
        return (
            <CardDropDownButton
                items={[
                    {
                        text: 'View All',
                        fontAwesomeName: 'fa-th-list',
                        onClick: () => onViewCompanyPeople(companyId)
                    }
                ]}
            />
        );
    };

    render = () => {
        const { person: personObj, title, isLoading, children } = this.props;
        return (
            <SimpleCard
                key={personObj ? personObj.personId : '0'}
                title={title}
                isLoading={isLoading}
                buttons={this.renderButtons()}
            >
                {children ? children : null}
                {personObj ? (
                    <ConnectedFieldDetails
                        values={personObj}
                        idColumnDef={person.id}
                        fields={[
                            person.descriptor,
                            person.jobTitle,
                            person.businessEmailLink,
                            person.businessPhone,
                            person.mobilePhone,
                            person.ddiPhone,
                            person.gdprPreferences
                        ]}
                    />
                ) : null}
            </SimpleCard>
        );
    };
}

const mapStateToProps = state => ({ resources: state.resources });

const mapDispatchToProps = dispatch => ({
    edit: person => dispatch(editPerson(person)),
    onViewCompanyPeople: companyId => safeDispatchPush(dispatch, push, ['company', companyId, 'people'])
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PersonDetails);
