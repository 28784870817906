import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import useModalForm from '../../common/Hooks/useModalForm';
import ViewRegistrationForm from '../Forms/ViewRegistrationForm';

const EditRegistrationModal = ({ children, className }) => {
    const { toggle, isOpen, formValue, openModal } = useModalForm();

    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle} className={className}>
                <AppModalHeader toggle={toggle}>{`Registration`}</AppModalHeader>
                <ModalBody>
                    <ViewRegistrationForm onCancel={toggle} registration={formValue} />
                </ModalBody>
            </Modal>
            {children({
                viewRegistration: registration => openModal(registration)
            })}
        </>
    );
};

export default EditRegistrationModal;
