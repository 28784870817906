import { ENDPOINTS } from '../constants';
import { apiGetPost } from '../apiHelpers';

export const apiCompanies = companyIds => async (dispatch, getState) => {
    if (!companyIds || companyIds.length === 0) {
        return false;
    }

    let path;

    const dealershipId = getState().user.dealershipId;
    if (dealershipId) {
        path = ['company', 'companies', dealershipId];
    } else {
        path = ['company', 'companies'];
    }

    dispatch(apiGetPost(ENDPOINTS.API.COMPANIES, path, companyIds));
};
