import {
    basicColumnDef
    //choiceListColumnSettings
} from '../common/FilteredTable/tableAndFilterHelpers';

import React from 'react';
import Map from './Map';

const addressDef = {
    id: {
        ...basicColumnDef('id', 'id'),
        mapping: values => values.addressId
    },
    map: {
        ...basicColumnDef('Location', 'descriptor'),
        mapping: (resources, values, field, actions) => <Map address={values} />
    },
    streetAddress: {
        ...basicColumnDef('Address', 'address1'),
        mapping: (resources, values, field, actions) =>
            [values.address1, values.address2, values.address3, values.address4]
                .map(x => (x ? x.trim() : ''))
                .filter(x => x)
                .join(', ')
    },
    address1: {
        ...basicColumnDef('Address Line 1', 'address1'),
        primary: true
    },
    address2: {
        ...basicColumnDef('Address Line 2', 'address2')
    },
    address3: {
        ...basicColumnDef('Address Line 3', 'address3')
    },
    address4: {
        ...basicColumnDef('Address Line 4', 'address4')
    },
    city: {
        ...basicColumnDef('City', 'city'),
        type: 'text'
    },
    postCode: {
        ...basicColumnDef('Postcode', 'postCode'),
        type: 'text'
    }
};

export default addressDef;
