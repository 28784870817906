import React from 'react';
import PropTypes from 'prop-types';
import { ListGroupItem } from 'reactstrap';

const ActionButton = props => {
    const { buttonText, fontawesomeIcon, bootstrapColor, onClickAction } = props;
    return (
        <ListGroupItem
            tag="button"
            type="button"
            color={bootstrapColor}
            action
            className="hover-pointer"
            onClick={() => onClickAction()}
        >
            <i className={`fa ${fontawesomeIcon} mr-3`} />
            {buttonText}
        </ListGroupItem>
    );
};

ActionButton.defaultProps = {
    buttonText: PropTypes.string.isRequired,
    fontawesomeIcon: PropTypes.string.isRequired,
    bootstrapColor: PropTypes.string.isRequired,
    onClickAction: PropTypes.func.isRequired
};

export default ActionButton;
