import React from 'react';
import connect from 'react-redux/es/connect/connect';
import Table from '../../common/Table/index';
import { AlertIfNoRows } from '../Table/AlertIfNoRows';
import SimpleCardCollapsable from '../Card/SimpleCardCollapsable';
import CardDropDownButton from '../Buttons/CardDropDownButton';
import ChoiceListAndTextFilteredRows from './ChoiceListAndTextFilteredRows';
import { makeTableColumns, makeTableFilters, makeTableSearchFields } from './tableAndFilterHelpers';
import { FilterTableRowButton } from '../Buttons/TableButtons';
import { FieldsGetHeadings } from '../../Dashboard/Factories/DashboardHelpers';

class ChoiceListFilteredTableNoDashboardData extends React.Component {
    state = { showFilter: this.props.showFilter };

    makeAgreementOpportunitiesTableColumns = () => {
        const { fields, columnDefs, tableDefinition } = this.props;

        if (tableDefinition) {
            return tableDefinition.columns;
        }

        return makeTableColumns(columnDefs, fields);
    };

    makeAgreementOpportunitiesTableFilters = () => {
        const { fields, filterDefs, tableDefinition } = this.props;

        if (tableDefinition) {
            return tableDefinition.filters;
        }

        return makeTableFilters(filterDefs, fields);
    };

    makeSearchFields = () => {
        const { fields, searchFieldDefs, tableDefinition } = this.props;
        if (tableDefinition) {
            return tableDefinition.searchFields;
        }
        return makeTableSearchFields(searchFieldDefs, fields);
    };

    renderButtons = () => {
        const { cardButtons } = this.props;
        const items = [];

        const filters = this.makeAgreementOpportunitiesTableFilters();

        if ((filters && filters.length) || this.makeSearchFields().length) {
            items.push({
                text: 'Filter',
                fontAwesomeName: 'fa-filter',
                onClick: () => this.setState({ showFilter: !this.state.showFilter })
            });
        }

        if (items.length === 1 && (!cardButtons || cardButtons.length === 0)) {
            return (
                <FilterTableRowButton
                    onClick={() =>
                        this.setState({
                            showFilter: !this.state.showFilter
                        })
                    }
                />
            );
        }

        return <CardDropDownButton items={[...cardButtons, ...items]} />;
    };

    renderTable(filteredRows) {
        const { id, noItemsText, breakpoint, itemsPerPage, showCount } = this.props;

        return (
            <AlertIfNoRows message={noItemsText || 'No items to display'} rows={filteredRows}>
                <Table
                    id={id}
                    breakpoint={breakpoint}
                    itemsPerPage={itemsPerPage}
                    headings={FieldsGetHeadings(this.makeAgreementOpportunitiesTableColumns())}
                    rows={filteredRows}
                    showCount={showCount}
                />
            </AlertIfNoRows>
        );
    }

    render() {
        const {
            rows,
            isLoading,
            tableIsNotInCard,
            title,
            tableDefinition,
            showFilter,
            children,
            insertAboveTable
        } = this.props;
        if (!rows) return null;

        if (!tableDefinition) {
            console.warn(`Table ${title} should use tableDefinition not ColumnDefs/FilterDefs etc`);
        }
        return (
            <ChoiceListAndTextFilteredRows
                rows={rows}
                searchFields={this.makeSearchFields()}
                showFilter={this.state.showFilter || showFilter}
                filters={this.makeAgreementOpportunitiesTableFilters()}
            >
                {({ filter, filteredRows }) => {
                    const count = rows.length;
                    return tableIsNotInCard ? (
                        <React.Fragment>
                            {filter}
                            {this.renderTable(filteredRows)}
                        </React.Fragment>
                    ) : (
                        <SimpleCardCollapsable
                            collapseName={title}
                            title={`${title} (${count})`}
                            isLoading={isLoading}
                            buttons={this.renderButtons()}
                        >
                            {insertAboveTable && <div className="position-relative w-100 mb-4">{insertAboveTable}</div>}
                            {filter}
                            <div>{this.renderTable(filteredRows)}</div>
                            {children && <div className="position-relative w-100">{children}</div>}
                        </SimpleCardCollapsable>
                    );
                }}
            </ChoiceListAndTextFilteredRows>
        );
    }
}

const mapStateToProps = state => ({
    resources: state.resources
});

export default connect(mapStateToProps)(ChoiceListFilteredTableNoDashboardData);
