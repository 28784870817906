import React, { Component } from 'react';
import { Collapse, Button, CardBody, Card } from 'reactstrap';

class CollapseChildren extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = { collapse: false };
    }

    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }

    render() {
        const { className, name, hideName, noCard, children } = this.props;
        const { collapse } = this.state;
        return (
            <div>
                <Button color="info" onClick={this.toggle} className={className} style={{ marginBottom: '1rem' }}>
                    {hideName && collapse ? hideName : name}
                </Button>
                <Collapse isOpen={collapse}>
                    {noCard ? (
                        children
                    ) : (
                        <Card>
                            <CardBody>{children}</CardBody>
                        </Card>
                    )}
                </Collapse>
            </div>
        );
    }
}

export default CollapseChildren;
