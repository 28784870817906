import React from 'react';
import SelectDealershipForm from '../Forms/SelectDealershipForm';

export default class SelectDealershipPage extends React.Component {
    render = () => (
        <div className="login-background d-flex no-block justify-content-center align-items-center">
            <SelectDealershipForm className="bg-white p-4" />
        </div>
    );
}
