import { ENDPOINTS } from '../constants';
import { makeGetRequest } from '../apiHelpers';
import { isError } from '../../Loading/isError';
import { toastr } from 'react-redux-toastr';

const type = ENDPOINTS.API.COMPANY_DEMOS_GET;

export const apiCompanyDemos = companyId => async (dispatch, getState) => {
    if (!companyId) return;
    try {
        if (!getState().offline.online) {
            return false;
        }
        const { data } = await makeGetRequest(['company', companyId, 'demos']);
        dispatch({
            type,
            data,
            companyId
        });
    } catch (err) {
        isError(dispatch, type, true, err.message, {});

        if (getState().user.userId > -1) {
            toastr.error(type, `Response ${err.message}`);
        }

        return false;
    }
};
