import * as yup from 'yup';
import opportunity from '../opportunity';
import _ from 'lodash';
import { mandatoryStringFields } from '../../common/CommonForms/yupHelpers';

export default yup
    .object()
    .shape({
        ...mandatoryStringFields([
            opportunity.id,
            opportunity.companyId,
            opportunity.description,
            opportunity.stage,
            opportunity.note,
            opportunity.likelihood,
            opportunity.termsLoaded,
            opportunity.fuelType,
            opportunity.model,
        ]),

        estimatedConversionDate: yup.date('Estimated conversiond date is required'),
        unitPotential: yup
            .number()
            .positive()
            .min(1, 'Opportunites must have the potential to sell at least one vehicle'),
    })
    .test('lost_stage-has_reason', function(value) {
        const stage = _.get(value, opportunity.stage.field, '');
        const lostReason = _.get(value, opportunity.wonLostReason.field, '');
        return (
            stage !== 'LostSale' ||
            (lostReason !== undefined && lostReason !== '') ||
            this.createError({
                // Formik will use the error "path" as the key in the errors; set this to whatever you want
                path: opportunity.wonLostReason.field,
                message: 'Please supply a Lost Reason',
            })
        );
    })
    .test('lost_stage-has_reason_and_other', function(value) {
        const stage = _.get(value, opportunity.stage.field, '');
        const lostReason = _.get(value, opportunity.wonLostReason.field, '');
        const lostReasonOther = _.get(value, opportunity.lostReasonOther.field, '');

        return (
            stage !== 'LostSale' ||
            lostReason !== 'Other' ||
            (lostReasonOther !== undefined && lostReasonOther !== '') ||
            this.createError({
                path: opportunity.lostReasonOther.field,
                message: 'Please provide further details about why the opportunity was lost',
            })
        );
    });
