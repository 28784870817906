import React from 'react';
import { FieldValue } from '../../common/DisplayField/FieldValue';
import moment from 'moment';
import EventWrapper from './EventWrapper';

const AgendaDay = props => {
    const { agenda, day, first, showDayName } = props;

    const daysComms = agenda.filter(comm => moment(comm.start).format(process.env.REACT_APP_DATE_FORMAT) === day);

    return (
        <>
            <FieldValue className={`${first ? '' : 'mt-3'}`} primary>
                {showDayName ? moment(day, process.env.REACT_APP_DATE_FORMAT).format('dddd Do MMMM') : 'day'}
            </FieldValue>
            {daysComms.length === 0 ? <span>No events</span> : null}
            {daysComms.map(({ id, type, start, end, data, customEdit }) => (
                <div className="mt-1" key={id}>
                    <EventWrapper key={id} type={type} data={data} start={start} end={end} customEdit={customEdit} />
                </div>
            ))}
        </>
    );
};

export default AgendaDay;
