import React from 'react';
import { connect } from 'react-redux';
import SimpleCard from '../../common/Card/SimpleCard';
import PageSection from '../../common/PageSection';
/*import { AddTableRowButton } from '../../common/Buttons/TableButtons';*/
/*import { createPerson } from '../../../actions/Navigation/Person/createPerson';*/
import CompanyPeopleTable from '../../Person/Tables/CompanyPeopleTable';
import CompanyTitle from '../../Company/Components/CompanyTitle';
import CompanyProvider from '../../Company/Provider/CompanyProvider';

class PeoplePage extends React.Component {
    render = () => {
        /*const { createPerson } = this.props;*/

        const companyId = parseInt(this.props.match.params.companyId, 10);

        return (
            <CompanyProvider companyId={companyId}>
                <PageSection onBackClicked={this.props.history.goBack} title={<CompanyTitle />}>
                    <SimpleCard
                        title="Contacts"
                        buttons={[]/*
                            <div>
                                <AddTableRowButton onClick={() => createPerson(companyId)} />
                            </div>*/}
                    >
                    <CompanyPeopleTable />
                    </SimpleCard>
                </PageSection>
            </CompanyProvider>
        );
    };
}

const mapStateToProps = state => ({
    //loadingStates: state.loadingStates
});

const mapDispatchToProps = dispatch => ({
    /*createPerson: companyId => dispatch(createPerson(companyId))*/
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PeoplePage);
